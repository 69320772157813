// import { current } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
// import { Children } from 'react';
// import { DropDownButton } from 'devextreme-react/drop-down-button';
import {LoadIndicator} from 'devextreme-react/load-indicator';

// const dropDownOptions = {
//   width: '300px',
// }

const CardSmall = ({ icon, tone, title, value, 
  percentage,  
  children,
  isLoading,
  additionalHeaderContent,
  // menuItems, 
  // menuVisible = false,
  // menuSetOption,
  menu
}
) => {

  const currency = Cookies.get('currency');

  // tood check if need switch or if
  // const formatValue = (value) => {
  //   // console.log(formatting, 'formatting');
  //   // console.log(addPercent, 'addPercent')
  //   switch (addFormat) {
  //     case 'percent':
  //       return `${value}%`;   
  //     case 'currency':
  //       return `${currency} ${Intl.NumberFormat("it-IT").format(value)}` //convert here (in child) bc CardSmall needs to receive a number to se the proper css
  //     default:
  //       return value
  //   }
    
  // };

  const percentageFormat = (percentage) => {
    if (percentage == null){
      return null;
    } else {
      return `${Math.round(percentage)}%`
    }
  }

  return (
    <div className='ticker m-2 text-start'>
      {/* <div className={`icon-wrapper ${tone || (percentage >= 0 ? 'positive' : 'negative')}`}> */}
      {icon &&
      <div className={`icon-wrapper ${value >= 0 ? 'positive' : 'negative'}`}>
        <i className={`dx-icon dx-icon-${icon}`} />
      </div>
      }
      <div className='middle'>
        <div className='title-card
         d-flex justify-content-between align-items-center
         '>
          <p>{title}</p>
         
          {(percentage || percentage == 0) &&
          <div className={`percentage ${percentage >= 0 ? 'positive' : 'negative z-0'}`}>
            <div className={`dx-icon-${percentage >= 0 ? 'spinup' : 'spindown'}`} />
            <div className='value'>{
            isLoading 
            ? <LoadIndicator/> 
            : percentageFormat(percentage)}</div>
            {/* <div className='value'>{`${Math.abs(percentage)}%`}</div> */}
          </div>
          }
          {menu} 
          {/* moved to separate component */}

          {/* {menuVisible && (
            <DropDownButton
            keyExpr="id" // warning: put it in the array of obj that is passed
            displayExpr="text" // warning: put it in the array of obj that is passed                
            className='overflow-menu'
                items={menuItems}
                visible={menuVisible}
                icon='overflow'
                stylingMode='text'
                showArrowIcon={false}
                // dropDownOptions={{ width: 'auto' }}
                dropDownOptions={dropDownOptions}
                hideOnOutsideClick={true}
                onItemClick={onItemClick}
            />
          )} */}

          {additionalHeaderContent}
          
        </div>
        <div className='d-flex justify-content-between flex-wrap'>
          {children}


          {/* moved to separate component */}
          {/* <div className={`total ${fwNormal ? 'fw-normal' : ''}`}  >
            <small>{subtitle}</small>
            <br/>
            {formatValue(value)}


            <div className='d-flex'>
              <div className="subtitle-partition instore">
              <small>instore</small>
              <small>{instore}</small>

              </div>
              <div className="subtitle-partition online">
                <small>online</small>
                <small>{online}</small>

              </div>
            </div>
          </div> */}

          {/* moved to separate component */}
          {/* {(value2 || value2 == 0) && 
          // <div className='total overflow-visible z-1'>
          <div className={`total ${fwNormal ? 'fw-normal' : ''}`}  >
            <small>{subtitle2}</small>
            <br/>
            {formatValue(value2)}
            <div className='d-flex'>
              <div className="subtitle-partition">
              <small>instore</small>
              <small>{instore2}</small>
                
              </div>
              <div className="subtitle-partition">
                <small>online</small>
                <small>{online2}</small>

              </div>
            </div>
          </div>
          } */}


        </div>
      </div>

      {/* {percentage &&
      <div className={`percentage ${percentage >= 0 ? 'positive' : 'negative z-0'}`}>
        <div className={`dx-icon-${percentage >= 0 ? 'spinup' : 'spindown'}`} />
        <div className='value'>{percentageFormat(percentage)}</div>
        {/* <div className='value'>{`${Math.abs(percentage)}%`}</div> 
      </div>
      } */}
    </div>  
    )
}

export default CardSmall

CardSmall.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  value2: PropTypes.number,
  icon: PropTypes.string,
  tone: PropTypes.string,
  percentage: PropTypes.number,
  formatting: PropTypes.string,
};
import { useNavigate } from 'react-router-dom'
import { useEffect, useContext, useRef } from 'react';
import Cookies from 'js-cookie';
import useTranslate from '../utils/useTranslate.jsx';
import { ProductContext } from '../contexts/ProductProvider.jsx';
import demo from '../assets/images/wallpaper_login.jpg';
import { getStatusClass } from '../utils/functions.js';

const OrderListCustomer = ({orders, filter}) => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const currency = Cookies.get('currency')
    const role = Cookies.get('role')

    const { ordersCustScrollPosition, setOrdersCustScrollPosition
        // setStockPagination, stockScrollPosition, setStockScrollPosition, stockSearch, typeSearch, filter,   
    } = useContext(ProductContext)
    const orderScrollRef = useRef(null);


//hanndle scroll
//ATTENZIONE attualemtne fa partire la chiamata del product lookup, capire se serve la pagination , la position si ma la pagination se tiriamo giu direttamente tutti gli ordini no 
    // const updateBottomContent = () => {
    //     console.log('Reached the bottom!');
    //     if(filter.season != undefined && filter.department != undefined && filter.supplier != undefined){
    //         // setStockPagination(prevPag => prevPag + 1)
    //     } 
    // };

    const handleScroll = () => {
        const scrollTop = orderScrollRef?.current.scrollTop; //value of the top from offset
        // const scrollHeight = orderScrollRef?.current.scrollHeight;
        // const clientHeight = orderScrollRef?.current.clientHeight;
        
        // Save scroll position during scroll
        setOrdersCustScrollPosition(scrollTop);

        // // Check if the scroll has reached the bottom
        // if (scrollTop + clientHeight >= scrollHeight) {
        //     updateBottomContent();
        // }
    };

    // Set the div's scroll position to the stored value when the component mounts
    useEffect(() => {
        if (orderScrollRef?.current) {
            orderScrollRef.current.scrollTop = ordersCustScrollPosition;
        }
    }, [ordersCustScrollPosition]);

    // //delays the search, params is the query typed by the user
    // useEffect(() => {    
    //     let delayTimer;
    //     clearTimeout(delayTimer);
    //     delayTimer = setTimeout(() => {
    //         if(orderScrollRef?.current){
    //             orderScrollRef.current.scrollTop = 0;
    //             // setStockPagination(1)
    //             // search() //MOVED TO PROVIDER
    //         }
    //     }, 500);
    // }, [ stockSearch, typeSearch ]);

    //scroll top when filter change
//ATTENZIONE questo impedosce il corretto scroll, per ora commentato, sarà da scommentare o da collegare ad altro es se cambiano gli ordini che cambiano in seguito alla fetch, che parte se i filtri cambiano --> stesso problema anche con orders
                // useEffect(() => {
                //     if (orderScrollRef?.current){
                //         orderScrollRef.current.scrollTop = 0;
                //         // setStockPagination(1)
                //     }
                // }, [filter])

  return (
    <>
    {/* <div id='stock-item-list' className='scroll-view'> */}
        <ul className='stock-item-ul scroll-view-ul list-unstyled ' ref={orderScrollRef} onScroll={handleScroll}>
        {orders.map((order, index) => (
            <li 
                key={index}
                className='stock-item border-top-none'
                onClick={()=> {navigate(`/orders/${order.ID}`)}}>

                <div  className='stock-item-child py-2 d-flex justify-content-between align-items-center'>
                    <div className='stock-item-right d-flex align-items-center flex-shrink-1 flex-grow-1'>
                    {/* image */}
                        {/* <div className='img-container d-flex justify-content-center align-items-center'>
                            {/* <img src={httpToHttps(order.IMAGE_01)} alt="image" /> *

                            <img src={demo} alt="image" />
                        </div> */}

                        {/* data */}
                        <div className='text-start me-2 pe-0 pe-sm-4 flex-grow-1'>
                        
                            {/* <div>  */}
                                <div className='d-flex justify-content-between grey'>
                                    <p>{`#${order.ID}`} </p>
                                    <p>{order.DATE}</p>

                                    {/* <p className='text-secondary'><span className='light-grey'>{`${translate('qty')}: `}</span>{order.QTY}</p> */}
                                    {/* <p className='text-secondary'>{`${translate('qty')}: ${order.QTY}`}</p> */}
                                </div>
                                <div className='d-flex justify-content-between flex-grow-1'>
                                    <p className="title order-customer text-uppercase fw-semibold flex-shrink-1">{order.CUSTOMER.NAME}</p>
                                    <p className='title text-uppercase flex-shrink-0'>{currency} {Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(order.PRICE)}</p>
                                </div>
                                <div className='d-flex justify-content-between py-1 grey'>
                                    <p>{`${order.STORE}`} </p>
                                    <p>{`${order.SALE_CHANNEL}`}</p>
                                </div>
                                    
                                <div className='d-flex justify-content-between'>
                                    <p className={`order-status ${getStatusClass(order.STATUS)}`}>{translate(order.STATUS)}</p>
                                    <p className='grey'>{order.SALE_CHANNEL_ORDER_ID ? `#${order.SALE_CHANNEL_ORDER_ID}` : ''}</p>
                                </div>
                                {/* <p className="supplier-name text-uppercase d-inline text-secondary-emphasis">{order.VALUE_08}
                                <span className='text-uppercase text-black-50 '> {order.SUPPLIER_NAME}</span>
                                </p>     */}

                        </div>
                    </div>

                    {/* arrow */}
                    <div className='arrow pe-0 pe-sm-5 flex-shrink-0'>
                        <i className='fa-solid fa-chevron-right'></i>
                    </div>

                </div>                        

            </li>

            ))}
        </ul>   

    </>
    
  )
}

export default OrderListCustomer


                                
                            {/* </div> */}

                            {/* <div className="container m-0 p-0 mt-1 flex-shrink-1">
                                <div className="row flex-shrink-1">
                                    <div className="col-6 flex-shrink-1">
                                        <div className="cell">
                                            <p className="cell-title fw-bolder">{translate('Available')}</p>
                                            <p className="cell-content fw-bolder">{Intl.NumberFormat('it-IT').format(order.NUM_DATA_05 - order.IMP_QTY)}</p>
                                        </div>
                                        <div className="cell grey">
                                            <p className="cell-title">{translate('Reserved')}</p>
                                            <p className="cell-content">{Intl.NumberFormat('it-IT').format(order.IMP_QTY)}</p>
                                        </div>
                                        <div className="cell grey">
                                            <p className="cell-title">{translate('In Order')}</p>
                                            <p className="cell-content">{Intl.NumberFormat('it-IT').format(order.NUM_DATA_01)}</p>
                                        </div>                                    
                                    </div>
                                    <div className="col-6 flex-shrink-1">
                                        <div className="cell">
                                            <p className="cell-title fw-bolder">{translate('Price')}</p>
                                            <p className="cell-content fw-bolder" >{currency} {Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(order.NUM_DATA_10)}</p>
                                        </div>
                                        {(role==0 || role==1) && (
                                        <div className="cell grey">
                                            <p className="cell-title">ST%</p>
                                            <p className="cell-content">{(order.NUM_DATA_09*100).toFixed(0)} %</p>
                                        </div>
                                        )}                                    

                                        <div className="cell grey">
                                            <p className="cell-title">{translate('Color')}</p>
                                            <p className="cell-content">{order.VARIANT_DESCRIPTION}</p>
                                        </div>
                                </div>
                            </div>
                        </div> */}
import { useNavigate } from 'react-router-dom';

const QRbutton = ({source, textField, height='34px', width='34px', additionalClasses}) => {
  const navigate = useNavigate();

  const handleNavigate = (e) =>{
    // if (e.key && e.key === 'Enter') {
    //   e.preventDefault(); // Prevent navigating with Enter key
    //   return;
    // }
    console.log('onclick', e);
    navigate('/qr-scanner',
      {
        state: {
          source: source,
          textField: textField,
        }
      }
    )
  }
  return (
    <button 
    type='button'
    onClick={handleNavigate}
    className={`qr-camera-btn border-dx ${additionalClasses || ''}`}
    style={{height: height, width: width}}
    >
      <i className='fa-solid fa-camera'></i>
    </button>  
  )
}

export default QRbutton


// onKeyDown={(e) => handleEnterKeyDown(e)}

  // const handleEnterKeyDown = (e) => {
  //   console.log('enter', e);
  //   if (e.key && e.key === 'Enter') {
      
  //   e.preventDefault(); // Prevent navigating with Enter key
  //   return;
  // }
  // }




// icon='fa-solid fa-camera'
// icon='photo'
// onClick={()=> {navigate('/qr-scanner')}}
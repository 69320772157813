import { useContext } from 'react';
import { ProductContext } from '../contexts/ProductProvider';
import SearchTextbox from './SearchTextbox';

const dropdownOptions = {
    inputAttr:{
        autocomplete: 'new-password',
    }
}


const  OrdersTextbox = () => {
    const {ordersSearch, setOrdersSearch} = useContext(ProductContext)    
    const onValueChange = (value) => {
        setOrdersSearch(value);
    }; //problem: store is async updated, so it shows the prevoius value, solution: useEffect for functions that need the updated store value

    return (
        <div className="product-lookup-textbox me-2">
            <SearchTextbox 
                value={ordersSearch} 
                editorOptions={dropdownOptions} 
                onValueChange={onValueChange}
            />
        </div>
    );
}
 
export default OrdersTextbox;

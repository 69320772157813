import Cookies from 'js-cookie';
import useTranslate from '../utils/useTranslate';
import SidebarHeader from '../components/SidebarHeader';
import MainOrders from '../components/MainOrders';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../utils/constants/config';
import Docs from '../api/ApiCallsDocs';
import { ProductContext } from '../contexts/ProductProvider';
import notify from 'devextreme/ui/notify';
// import { expireOptions } from '../utils/constants/expireOptions';
import FooterMenuOrders from '../components/FooterMenuOrders';
// import { DropDownButton } from 'devextreme-react/drop-down-button';
// import { Button } from 'devextreme-react';
import NotificationBell from '../components/NotificationBell';

//mock only first is complete and aggiornato
const orders = [
    { 
        ID: 183734, 
        // DATE: '02 oct 2023 13:12', //capire ocme mi arriva data da BE
        DATE: '2024-10-14T03:00:00Z', //capire ocme mi arriva data da BE
        CUSTOMER:
        //da qui a "a qui", eccetto nome cliente posso fare una seocnda chiamata quando entro nell'ordine detail, se non pesa sulla chiamata generale allora chiamare subito
        {
            ID: '456484', //i clienti hanno un id? mi servirebbe per raggruppare gli ordini dello stesso cliente
            NAME: 'Giovanni Bluissimissimissimo', 
            ORDERS_NUM: 4,
            PHONE: 45836151955,
            MAIL: 'bluuuu@gmail.som'
        }, 
        SHIPPING_ADDRESS: {
            NAME: 'Giovanni Blu',
            ADDRESS: 'via roma 24',
            CAP: '23232',
            CITY: 'Roma',
            PROVINCE: 'RM',
            NATION: 'Italia',
        },
        // "a qui"
        STATUS: 'waybill',  
        PRICE: 1000, //valutare se mettere TOTAL_GROSS  e TOTAL_NET
        STORE: 'Padova', 
        SALE_CHANNEL: 'woocommerce', 
        SALE_CHANNEL_ORDER_ID: '1322523',
        QTY: 6, // questo come doppio controllo in caso, si potrebbe saltare contando gli elementi dell'array arr.lenght --> non se questa deve essere la quantita totale degli articoli, in tal caso è meglio recuperarlo direttamente
        
        ITEMS: [
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'camicia',
                VARIANT_DESCRIPTION: 'rossa',
                BRAND: 'superga',
                // SUPPLIER_NAME: 'supplier01', //da mettere? NO
                SIZE: 'XXL', 
                ITEM_QTY: 3,
                BARCODE: '6886460464864',  //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                // SUPPLIER_NAME: 'supplier01', //da mettere? NO
                SIZE: 'XXL', 
                ITEM_QTY: 3,
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },

        ]
},
    { ID: 247464, DATE: '02 oct 2024 13:12', CUSTOMER: 
        {
            NAME:'Sara Rossi', 
            ORDERS_NUM: 1
        },
        STATUS: 'inserted', QTY: 1, PRICE: 200, STORE: 'Mliano', SALE_CHANNEL: 'woocommerce',         SALE_CHANNEL_ORDER_ID: '1322523',
        ITEMS: [
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'vestito verde smeraldo',
                VARIANT_DESCRIPTION: 'blu petrolio',
                BRAND: 'superga',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },

        ]
    },
    { ID: 375878, DATE: '01 oct 2024 13:12', CUSTOMER: 
        {
            NAME: 'Renzo Verdi',
            ORDERS_NUM: 5
        }, STATUS: 'processing', QTY: 3, PRICE: 300, STORE: 'Napoli', SALE_CHANNEL: 'woocommerce',         SALE_CHANNEL_ORDER_ID: '1322523',
        ITEMS: [
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'vestito verde smeraldo',
                VARIANT_DESCRIPTION: 'blu petrolio',
                BRAND: 'superga',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },

        ]
     },
    { ID: 846468, DATE: '05 oct 2024 13:12', CUSTOMER: 
        {
            NAME: 'Flavio Bianchi',
            ORDERS_NUM: 2
        }, 
        STATUS: 'inserted', QTY: 500, PRICE: 300, STORE: 'Padova', SALE_CHANNEL: 'woocommerce',         SALE_CHANNEL_ORDER_ID: '1322523',
        ITEMS: [
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'vestito verde smeraldo',
                VARIANT_DESCRIPTION: 'blu petrolio',
                BRAND: 'superga',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },

        ]
     },
    { ID: 164455, DATE: '07 oct 2024 13:12', CUSTOMER:
        {
            NAME: 'Roberto Gialli',
            ORDERS_NUM: 3
        }, 
        STATUS: 'waybill', QTY: 3, PRICE: 300, STORE: 'Padova', SALE_CHANNEL: 'woocommerce',         SALE_CHANNEL_ORDER_ID: '1322523',
        ITEMS: [
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'vestito verde smeraldo',
                VARIANT_DESCRIPTION: 'blu petrolio',
                BRAND: 'superga',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },

        ]
     },
    { ID: 421442, DATE: '05 oct 2024 13:12', CUSTOMER: 
        {
            NAME: 'PierGualtiero Verdissimiesieieieieeieieieiei',
            ORDERS_NUM: 1
        }, 
        STATUS: 'processing', QTY: 3, PRICE: 300, STORE: 'Padova', SALE_CHANNEL: 'woocommerce',         SALE_CHANNEL_ORDER_ID: '1322523',
        ITEMS: [
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'vestito verde smeraldo',
                VARIANT_DESCRIPTION: 'blu petrolio',
                BRAND: 'superga',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },

        ]
     },
    { ID: 846668, DATE: '05 oct 2024 13:12', CUSTOMER: 
    // { ID: 846668, DATE: '24/12/2024', CUSTOMER: 
        {
            NAME: 'Flavio Bianchi',
            ORDERS_NUM: 2
        }, 
        STATUS: 'dispatched', QTY: 500, PRICE: 300, STORE: 'Padova', SALE_CHANNEL: 'woocommerce',         SALE_CHANNEL_ORDER_ID: '1322523',
        ITEMS: [
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'vestito verde smeraldo',
                VARIANT_DESCRIPTION: 'blu petrolio',
                BRAND: 'superga',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },

        ]
     },
    { ID: 162455, DATE: '02 oct 2024 13:12', CUSTOMER: 
        {
            NAME: 'Roberto Gialli',
            ORDERS_NUM: 3
        }, STATUS: 'returned', QTY: 3, PRICE: 300, STORE: 'Padova', SALE_CHANNEL: 'woocommerce',         SALE_CHANNEL_ORDER_ID: '1322523',
        ITEMS: [
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'vestito verde smeraldo',
                VARIANT_DESCRIPTION: 'blu petrolio',
                BRAND: 'superga',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },

        ]
     },
    { ID: 426442, DATE: '02 oct 2024 13:12', CUSTOMER: 
        {
            NAME: 'PierGualtiero Verdissimiesieieieieeieieieiei',
            ORDERS_NUM: 1
        }, 
        STATUS: 'part. returned', QTY: 3, PRICE: 300, STORE: 'Padova', SALE_CHANNEL: 'woocommerce',         SALE_CHANNEL_ORDER_ID: '1322523',
        ITEMS: [
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'vestito verde smeraldo',
                VARIANT_DESCRIPTION: 'blu petrolio',
                BRAND: 'superga',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },
            {
                ID: '18a055ef-7e17-4e08-831e-c6fb358c26a8', //serve? si per navigare alla pagina dettaglio prodotto
                IMAGE_01: "https://eversellimages01.blob.core.windows.net/images/demo/63bccf24-8185-403a-9a42-aa9cb9108385.png",
                CODE: '098-09',
                AUX_CODE: '12345',
                DESCRIPTION: 'scarpe ',
                VARIANT_DESCRIPTION: 'rosse',
                BRAND: 'riegel',
                SUPPLIER_NAME: 'supplier01', //da mettere?
                SIZE: 'XXL', 
                BARCODE: '6886460464864' //servira forse in futuro? non credo perche i qr li leggiamo, se sta a schermo devo avere un altro lettore esterno
            },

        ]
     },
  ]

// const dropDownOptions = {
//     width: '120px',
//     height: 'auto',
// };


const openClosedMap = {
    'inserted': 'open',
    'waybill': 'open',
    'processing': 'open',
    'dispatched': 'closed',
    'returned': 'closed',
    'part. returned': 'closed',
  }


  
const Orders = () => {
    const translate = useTranslate();
    const navigate = useNavigate()
    const role = Cookies.get('role')
    const pageTitle = translate('Orders')
    const { filterOrders: filter, setFilterOrders: setFilter, stores, setStores, saleChannels, setSaleChannels, ordersStatus, setOrdersStatus, ordersStore, setOrdersStore, ordersStoreId, setOrdersStoreId,  ordersChannel, setOrdersChannel, ordersChannelId, setOrdersChannelId, openClosedOrders } = useContext(ProductContext)
    const { getStores } = Docs()
    const [ filteredOrders, setFilteredOrders] = useState(orders)
    const [isLoading, setLoading] = useState(false) //todo set isLoading to true when abbiamo la chiamata


    // const [newOrdersNumber, setNewOrdersNumber] = useState(4);
    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // const dropdownRef = useRef(null);

//MOVED TO PRODUCT PROVIDER, kept the passage as prop, eventually can consider to change all the names and import directly where it is needed
    // const [filter, setFilter] = useState({
    //     store: config.all,
    //     channel: config.all,
    //     status: config.all,
    // }) //todo check if empty sting is ok od config.all  

    useEffect(() => {
        if(role == 550){
            navigate("/customer");  //todo: change path? navigate?
        } else {
            if(stores.length == 0) fetchStores();
            if(saleChannels.length == 0) fetchSaleChannels();          
    //TODO upd based on calls 
        //   if(suppliersDash.length == 0) {
        //     fetchSuppliersDash();
        //   }
        //   if(stores.length == 0){
        //     fetchShops();
        //   }


            // Add event listener for clicks outside the dropdown
        // document.addEventListener('mousedown', handleClickOutside);
        // return () => {
        //     document.removeEventListener('mousedown', handleClickOutside);
        // };
    

        }
      }, []);


//shops are the same fetched in dash, hence we'll use the already present useState
    const fetchStores = async () => {
        // setErrors(prevErr => ({...prevErr, shops: null}))

        try {
            // let data = await getShops(`{
            //     "$id": "1",
            //     "FILTER": {
            //         "Sortings": [{"Field": "SEARCH_NAME", "Ascending": true}]
            //     }
            // }`);
            // let data = await getShops(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
            let { success: success, data: data, error: error } = await getStores(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
            if(success){        
            if(!ordersStore) {
                setOrdersStore(data[0].SEARCH_NAME);
            }
            if(!ordersStoreId) {
                setOrdersStore(data[0].ID);
            }

            //problem:
            setFilter(prevFilter => ({ ...prevFilter, store: ordersStore ? ordersStore : data[0].SEARCH_NAME})); 
            setStores(data);
            // } else {
            // setErrors(prevErr => ({...prevErr, stores: error}))
            }
        } catch (error) {
        notify(translate(error.message) || translate('Error'), 'error', 3000);
        }
    }

    const fetchSaleChannels = async () => {
        // setErrors(prevErr => ({...prevErr, stores: null}))

        try {
            
    //todo orders: cambiare chiamata quando la abbiamo            
            let { success: success, data: data, error: error } = await getStores(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
            if(success){        
            if(!ordersChannel) {
                setOrdersChannel(data[0].SEARCH_NAME);
            }
            if(!ordersChannelId) {
                setOrdersChannelId(data[0].ID);
            }

            console.log('salechannel',data);
            setFilter(prevFilter => ({ ...prevFilter, channel: ordersChannel ? ordersChannel : data[0].SEARCH_NAME})); 
            setSaleChannels(data);
            }
        } catch (error) {
        notify(translate(error.message) || translate('Error'), 'error', 3000);
        }
    }


    const selectMultipleFilter = ({ filterType, selectedValue, id }) => {
        setFilter(prevFilter => ({ ...prevFilter, [filterType]: selectedValue}));    
        if(filterType == 'store'){
            setOrdersStore(selectedValue);
            setOrdersStoreId(id);
        } else if(filterType == 'channel'){
            setOrdersChannel(selectedValue);
            setOrdersChannelId(id);
        } else if(filterType == 'status') setOrdersStatus(selectedValue);
        // dashboard(tab);
        };



    //filter the orders array before passing it down so it does it once for both visualization
    
    
    // alternative that does sets the filter regardless of open/closed, it anyways first filters open/cloded/al and then subfilters by status 
    useEffect(() => {
        const filterOrders = () => {
            let newOrders = [];
            if(openClosedOrders == 'all') { //if i select a status from filter, openClosed is set to 'all', here i filter after setting it
                if(filter.status == config.all) {
                    newOrders = orders
                } else {
                    newOrders = orders.filter(order => order.STATUS == filter.status);
                }
            } else {
                const filteredByOpenClosed = orders.filter(order => openClosedMap[order.STATUS] == openClosedOrders);
                if(filter.status == config.all) {
                    newOrders = filteredByOpenClosed;
                } else {
                    newOrders = filteredByOpenClosed.filter(order => order.STATUS == filter.status);
                }
            }
            setFilteredOrders(newOrders);       
        }
        filterOrders()
    }, [openClosedOrders, filter.status, orders])
    
    
        //if filter shows all options of status, when one is selected the user is navigated to "all" of openclosed and there he sees the orders
                // const filterOrders = useCallback(() => {
                //     let newOrders = [];
                //     if(openClosedOrders == 'all') { //if i select a status from filter, openClosed is set to 'all', here i filter after setting it
                //         if(filter.status == config.all) {
                //             newOrders = orders
                //             } else {
                //             newOrders = orders.filter(order => order.STATUS == filter.status);
                //             }
                //     } else {
                //     newOrders = orders.filter(order => openClosedMap[order.STATUS] == openClosedOrders);
                //     }
                //     setFilteredOrders(newOrders);
                // }, [openClosedOrders, filter.status, orders])
                
                // useEffect(() => {
                //     filterOrders()
                // }, [openClosedOrders, filter.status, orders])

    // // va passata al onClick nel filter quindi spostata nel main
    // const filterOrdersByStatus = useCallback(() => {
    //     //ascolta il filtro, quando cambia filtra gli ordini in base al order status
    //     // const newOrders = orders.filter(order => order.STATUS == filter.status)
    //     let newOrders = [];
    //     if(filter.status == config.all) {
    //     newOrders = orders
    //     } else {
    //     newOrders = orders.filter(order => order.STATUS == filter.status);
    //     }
    //     setFilteredOrders(newOrders);
    // },[filter.status, orders])

    // useEffect(() => {
    //     filterOrdersByStatus()
    // }, [filter.status, orders])





//notifications
//       const showNewOrders = () => {
//         console.log('Showing new orders');


//         // setIsDropdownOpen(false);
//         // fetchOdreds
//         // set notiifcations to 0
//         // Add your logic to show new orders
//     };




// //new order notification claude+devextreme, not working
//     // const [newOrdersNumber, setNewOrdersNumber] = useState(4);

//     // ... (keep your existing useEffect, fetchShops, fetchSaleChannels, and selectMultipleFilter functions)

//     // const showNewOrders = () => {
//     //     console.log('Showing new orders');
//     //     // Add your logic to show new orders
//     // };

//     // const dropDownOptions = {
//     //     width: '200px',
//     // };

//     const dropDownContentRender = useCallback(() => {
//         return (
//             // <div className='notification-dropdown text-center'>
//             <div className='text-center py-2'>
//                 <p>There are</p>
//                 <h4>{newOrdersNumber}</h4>
//                 <p>new orders</p>
//                 <Button
//                     className='mt-2'
//                     text='See now' 
//                     onClick={showNewOrders}
//                     stylingMode="contained"
//                     type='default'
//                 />
//                 {/* </Button>     */}
//             </div>
//         );
//     }, [newOrdersNumber]);

//     const dropDownButtonRender = useCallback(() => {
//         return (
//           <div className="notification-bell-wrapper">
//             <i className="fa-solid fa-bell"></i>
//             {newOrdersNumber > 0 && (
//               <span className="notification-badge">{newOrdersNumber}</span>
//             )}
//           </div>
//         );
//     }, [newOrdersNumber]);

//     const NotificationBell = () => (
//         <div className="notification-bell-container">
//             <DropDownButton
//                 className="notification-dropdown-button"
//                 icon='fa-solid fa-bell'
//                 stylingMode="text"
//                 dropDownOptions={dropDownOptions}
//                 dropDownButtonRender={dropDownButtonRender}
//                 dropDownContentRender={dropDownContentRender}
//                 splitButton={false}
//                 showArrowIcon={false}
//                 useItemTextAsTitle={false}
//                 hoverStateEnabled={false}
//             />
//         </div>
//     );


    return (
        <>
        {/* <SidebarHeader title={pageTitle} backArrowVisible={false} bell={NotificationBell} /> */}
        <SidebarHeader title={pageTitle} backArrowVisible={false} bell={<NotificationBell />} />
        <div className={`page-container d-flex flex-column `}>
            {/* add conditional render only if order is populated aka received answer */}
            {filteredOrders && 
            <MainOrders orders={filteredOrders} filter={filter} isLoading={isLoading}/>
            }
            <FooterMenuOrders 
                stores={stores} 
                channels={saleChannels} 
                selectFilter={selectMultipleFilter}
                filter={filter}
            />
        </div>
    </>
    )
}


export default Orders

//notification bell claude + notDevextreme

// const showNewOrders = () => {
//     console.log('Showing new orders');
//     setIsDropdownOpen(false);
    // fetchOdreds
    // set notiifcations to 0
    // Add your logic to show new orders
// };

// const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
// };

// const handleClickOutside = (event) => {
//     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsDropdownOpen(false);
//     }
// };

// const NotificationBell = () => (
//     <div className="notification-bell-container" ref={dropdownRef}>
//         <div className="notification-bell" onClick={toggleDropdown}>
//             <i className="fa-solid fa-bell"></i>
//             {newOrdersNumber > 0 && (
//                 <span className="notification-badge">{newOrdersNumber}</span>
//             )}
//         </div>
//         {isDropdownOpen && (
//             <div className="notification-dropdown">
//                 <p>There are</p>
//                 <h4>{newOrdersNumber}</h4>
//                 <p>new orders</p>
//                 <Button
//                     className='mt-2'
//                     text='See now' 
//                     onClick={showNewOrders}
//                     stylingMode="contained"
//                     type='default'
//                 >See now</Button>
//             </div>
//         )}
//     </div>
// );














//new order notification
    //   const newOrdersNumber = 4

    // //   const notificationText = [
    // //     {
    // //         text: <div className=''>
    // //         <p>There are</p>
    // //         <h4>{newOrdersNumber}</h4>
    // //         <p>new orders</p>
    // //         <button>See now</button>

    // //     </div>
    // //     }
    // //   ]

    // const notificationText = [
    //     { id: 1, text: "New Orders" }  // This array can be more complex if needed, just keeping it simple for now
    // ];

    // const dropDownOptions = {
    // width: '120px',
    // }

    // const showNewOrders = () => {
    //     console.log('henloo');
        
    // }
      
    //   const dropDownContentRender = useCallback(() => {
    //     return (
    //         <div className='notification-dropdown text-center'>
    //             <p>There are</p>
    //             <h4>{newOrdersNumber}</h4>
    //             <p>new orders</p>
    //             <Button
    //                 className='mt-2'
    //                 text='See now' 
    //                 onClick={showNewOrders}
    //                 stylingMode="contained"
    //                 type='default'
    //             />    
    //         </div>
    //     )
    //   }, [])

    //   const dropDownButtonRender = () => {
    //     return (
    //       <div className="position-relative">
    //         <i className="fa-solid fa-bell"></i>
    //         <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
    //           {newOrdersNumber}
    //           <span className="visually-hidden">new orders</span>
    //         </span>
    //       </div>
    //     );
    //   };

    // const bellContent = () => {
    //     return (
    //         <div className='d-flex'>                  
    //             <DropDownButton
    //                 // className='me-1 notification-bell'
    //                 icon='fa-solid fa-bell'  // Icon only on the button
    //                 items={notificationText}  // Items with both icon and text
    //                 stylingMode="text"  // Button styling without text
    //                 itemRender={dropDownContentRender}
    //                 dropDownOptions={dropDownOptions}

    //                 dropDownButtonRender={dropDownButtonRender}
    //                 // dropDownContentRender={dropDownContentRender}
    //                 splitButton={false}
    //                 showArrowIcon={false}
    //                 // buttonTemplate={buttonTemplate}
    //                 useItemTextAsTitle={false}
    //                 hoverStateEnabled={false}    
    //                 // fieldRender={renderField}
    //             />                
    //         </div>


    //         // <div className='dropdown'>
    //         //     {/* <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    //         //     {/* <button type="button" className="btn" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-content="Bottom popover"> 
    //         //         <i className='fa-solid fa-bell fs-18'></i>
    //         //     </button> */}

    //         //     <div className='dropdown-menu'>
    //         //         <p>There are</p>
    //         //         <h4>{newOrdersNumber}</h4>
    //         //         <p>new orders</p>
    //         //         <button>See now</button>

    //         //     </div>

    //         // </div>

    //     )
    // }




//new order notification claude+devextreme, not working
    // const [newOrdersNumber, setNewOrdersNumber] = useState(4);

    // // ... (keep your existing useEffect, fetchShops, fetchSaleChannels, and selectMultipleFilter functions)

    // const showNewOrders = () => {
    //     console.log('Showing new orders');
    //     // Add your logic to show new orders
    // };

    // const dropDownOptions = {
    //     width: '200px',
    // };

    // const dropDownContentRender = useCallback(() => {
    //     return (
    //         <div className='notification-dropdown text-center'>
    //             <p>There are</p>
    //             <h4>{newOrdersNumber}</h4>
    //             <p>new orders</p>
    //             <button
    //                 className='mt-2'
    //                 onClick={showNewOrders}
    //             >
    //                 See now
    //             </button>    
    //         </div>
    //     );
    // }, [newOrdersNumber]);

    // const dropDownButtonRender = useCallback(() => {
    //     return (
    //       <div className="notification-bell-wrapper">
    //         <i className="fa-solid fa-bell"></i>
    //         {newOrdersNumber > 0 && (
    //           <span className="notification-badge">{newOrdersNumber}</span>
    //         )}
    //       </div>
    //     );
    // }, [newOrdersNumber]);

    // const NotificationBell = () => (
    //     <div className="notification-bell-container">
    //         <DropDownButton
    //             className="notification-dropdown-button"
    //             stylingMode="text"
    //             dropDownOptions={dropDownOptions}
    //             dropDownButtonRender={dropDownButtonRender}
    //             dropDownContentRender={dropDownContentRender}
    //             splitButton={false}
    //             showArrowIcon={false}
    //             useItemTextAsTitle={false}
    //             hoverStateEnabled={false}
    //         />
    //     </div>
    // );

import { Html5Qrcode } from 'html5-qrcode';
import { useContext, useEffect, useState } from 'react';
// import { ProductContext } from '../contexts/ProductProvider';
// import useTranslate from '../utils/useTranslate';
import notify from 'devextreme/ui/notify';
// import { useLocation, useNavigate } from 'react-router-dom';
// import SidebarHeader from './SidebarHeader';

const config = { fps: 30, qrbox: { width: 300, height: 300 } } //size of the box where QR code will be scanned



//giapponese 
let html5QrCode

const QRScanner = ({action}) => {
  const [isScanning, setIsScanning] = useState(false)

  useEffect(() => {
      html5QrCode = new Html5Qrcode('qr-reader')
      handleStart();

      return ()=>{
        if(isScanning == true){
          handleStop()
        }
      }
  }, [])

  const handleStart = () => {
      const qrCodeSuccessCallback = (decodedText, decodedResult) => {
          // setQRcode(decodedText) // remember to clean in the child page

          action.onScan(decodedText);
          handleStop()  // must be called after setting the code bc it clearss it , but before navigating otherwise the scanner will not stop UPD on component unmount the scanner will stop anyways
          // navigate(path)
      }
      // html5QrCode.clear()
      html5QrCode.start({ facingMode: 'environment' }, config, qrCodeSuccessCallback).catch((err) => notify(`QR Code scan error: ${err}`, 'error', 2000)
    )
      setIsScanning(true)
  }

  const handleStop = () => {
      // try {
          html5QrCode
              .stop()
              .then(() => {html5QrCode.clear()})
              .catch(err => {console.log(err.message)})
          setIsScanning(false)
      // } catch (err) {
      //     console.log(err)
      // }
  }

  
  return (
    <>
        {/* <SidebarHeader title={translate('Barcode reader')} backArrowVisible={true} /> */}
        <div id="qr-reader"/>
      </>
  )
}
export default QRScanner
//fine giapponese



  // const ACTIONS = {
  //   'product-lookup': {
  //     default: {
  //       onScan: (code) => {
  //         setQRcode(code);
  //         navigate('/product-lookup/:id');
  //       },
  //       // title: 'Barcode reader'
  //     }
  //   },
  //   'inventory': {
  //     default: {
  //       onScan: (code) => {
  //         setInventoryBarcode(code);
  //         navigate('/inventory');
  //       },
  //       // title: 'Barcode reader'
  //     }
  //   },
  //   'barcode-association': {
  //     internal: {
  //       onScan: (code) => {
  //         console.log('scanned', code);
  //         setInternalBarcode(code);
  //         navigate('/barcode-association', { 
  //           state: { 
  //             scannedCode: code,
  //             field: 'internal'
  //           }
  //         });
  //       },
  //       // title: 'Scan Internal Barcode'
  //     },
  //     manufacturer: {
  //       onScan: (code) => {
  //         setManufacturerBarcode(code)
  //         navigate('/barcode-association', { 
  //           state: { 
  //             scannedCode: code,
  //             field: 'manufacturer'
  //           }
  //         });
  //       },
  //       // title: 'Scan Manufacturer Barcode'
  //     }
  //   }
  // };

  // Get the source from the state passed during navigation
  // const { source = 'product-lookup', textField = 'default' } = location.state || {}; //with defaults
  // const action = ACTIONS[source]?.[textField] || ACTIONS['product-lookup'].default;
  // const { source, textField } = location.state || {};
  // const action = ACTIONS[source]?.[textField] || ACTIONS[source]?.default;
  

  // const { setQRcode, setInventoryBarcode, setInternalBarcode, setManufacturerBarcode } = useContext(ProductContext); // removed setTypeSearch, setStockSearch,
  // const translate = useTranslate()
  // const navigate = useNavigate()
  // const [result, setResult] = useState(null);
  // const location = useLocation();



// const handleSearch = () => {
//   // setTypeSearch('Barcode')
//   // setStockSearch(result)
//   setQRcode(result)
//   navigate('/product-lookup/:id')
// }

// const handleRetry = () => {
//   setResult(null)
//   handleStart()
// }


{/* <Button 
  id='qr-back-btn'
  icon='back' 
  text={translate('back')} 
  // className='m-3 bg-secondary-subtle' 
  onClick={()=>navigate('/product-lookup')}
/> */}
{/* {result && (
  <div className='d-flex flex-column align-items-center'>
    <Button 
      onClick={() => handleSearch()}
      className='qr-btn qr-result-btn'
      text={`${translate('Search')}: ${result}`}
    />
    <Button
      onClick={() => handleRetry()}
      className='qr-btn qr-retry-btn'
      text={translate('Retry')}
      />
  </div>
)} */}


// const QRscanner = () => {

//   const { setTypeSearch, stockSearch, setStockSearch } = useContext(ProductContext);
//   const translate = useTranslate()
//   const navigate = useNavigate()
//   const [result, setResult] = useState(null);
     
    
//     //to avoid creating a new scanner upon every state change and component reload, i embedd it in an useEffect 
//   useEffect(() => {
//     const html5QrCode = new Html5Qrcode('qr-reader');
//     html5QrCode.start(
//       { facingMode: "environment" },  // If you want to prefer back camera
//       config, 
//       qrCodeSuccessCallback,
//       errorCallback, 
//       // (html5QrCode, decodedText, decodedResult) => qrCodeSuccessCallback(html5QrCode, decodedText, decodedResult), 
//       )
//     .catch((err) =>{
//       console.log(err);
//       notify(translate('Camera not found'), 'error', 2000)
//     }); 

//     function qrCodeSuccessCallback (decodedText) {
//       setResult(decodedText)    
//       html5QrCode.clear()
//       html5QrCode.stop().catch((err) => console.log("Stop failed:", err));
//       // setIsScannerOpen(false);
//     }

//     // function qrCodeSuccessCallback (decodedText) {
//     //   setResult(decodedText)
//     //   setTypeSearch('Barcode');
//     //   setStockSearch(decodedText); //when barcode changes, aautomatically the search function is triggered by useEffect, so no need to call it 
//     //   html5QrCode.clear()
//     //   html5QrCode.stop().catch((err) => console.log("Stop failed:", err));
//     //   navigate('/product-lookup')
//     //   // setIsScannerOpen(false);
//     // }

//     function errorCallback(err){
//       console.warn(err);
//       notify(`QR Code scan error: ${err}`, 'error', 2000)
//     }
//   }, [])

//   const confirmQr = useCallback(() => {
//     setTypeSearch('Barcode');
//     setStockSearch(result); //when barcode changes, aautomatically the search function is triggered by useEffect, so no need to call it 
//     navigate('/product-lookup')
//   }, [])

//     // useEffect(() => {
//     // const scanner = new Html5QrcodeScanner('qr-reader', config)
//     // scanner.render(success,error)

//     // function success(decodedText) {
//     //   setTypeSearch('Barcode');
//     //   setStockSearch(decodedText); //when barcode changes, aautomatically the search function is triggered by useEffect, so no need to call it     
//     //   scanner.clear();
//     //   navigate(-1)
//     // }
//     // function error(err){
//     //   console.warn(err);
//     //   // notify(translate('Camera not found'), 'error', 2000)
//     //   notify(err, 'error', 2000)
//     // }
//     // },[])


//   return (
//     <>
//     <div id='qr-reader'></div>
//     <Button onClick={confirmQr}>Search: {result}</Button>
//     </>
//   )
// }

// export default QRscanner








  // useEffect(() => {
  //   const scanner = new Html5QrcodeScanner('qr-reader', config)
  //   scanner.render(success,error)

  //   function success(decodedText) {
  //     setTypeSearch('Barcode');
  //     setStockSearch(decodedText); //when barcode changes, aautomatically the search function is triggered by useEffect, so no need to call it     
  //     scanner.clear();
  //     navigate(-1)
  //   }
  //   function error(err){
  //     console.warn(err);
  //     notify(translate('Camera not found'), 'error', 2000)
  //   }
  //   },[])








//giapponese 
// let html5QrCode
// const QRScanner = ({ onResult }) => {
//   const [isScanning, setIsScanning] = useState(false)
//   useEffect(() => {
//       html5QrCode = new Html5Qrcode('reader')
//   }, [])

//   const handleClickAdvanced = () => {
//       const qrCodeSuccessCallback = (decodedText, decodedResult) => {
//           onResult(decodedText)
//           handleStop()
//       }
//       html5QrCode.start({ facingMode: 'environment' }, config, qrCodeSuccessCallback)
//       setIsScanning(true)
//   }

//   const handleStop = () => {
//       try {
//           html5QrCode
//               .stop()
//               .then(() => {
//                   html5QrCode.clear()
//               })
//               .catch(err => {
//                   console.log(err.message)
//               })
//           setIsScanning(false)
//       } catch (err) {
//           console.log(err)
//       }
//   }

//   return (
//       <>
//           <div id="reader" width="100%" />
//           {isScanning ? (
//               <Button variant="contained" onClick={() => handleStop()}>
//                   stop scanning
//               </Button>
//           ) : (
//               <Button variant="contained" onClick={() => handleClickAdvanced()}>
//                   start scanning
//               </Button>
//           )}
//       </>
//   )
// }
// export default QRScanner























  // const qrCodeSuccessCallback = useCallback((html5QrCode, decodedText, decodedResult) => {
  // const qrCodeSuccessCallback = useCallback((decodedText) => {
  //   setTypeSearch('Barcode');
  //   setStockSearch(decodedText); //when barcode changes, aautomatically the search function is triggered by useEffect, so no need to call it 
  //   html5QrCode.clear()
  //   // html5QrCode.stop().catch((err) => console.log("Stop failed:", err));
  //   navigate(-1)
  //   // setIsScannerOpen(false);
  // }, [setStockSearch, setTypeSearch]);
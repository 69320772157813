import config from '../utils/constants/config';
import useApi from './useApi';
import Helper from './Helper';

const Docs = () => {
    const { callApi } = useApi();
    const { removeBadValue, allFirst } = Helper();

    async function getStores (params) {
      // const response = await callApi('POST', `/DOCS/${config.language}/Get_All_qry_GET_SHOP_LISTS`, params); 
      // return allFirst(removeBadValue(response.ELENCO, 'SEARCH_NAME', 'all'))  
      const { success, data, error }  = await callApi('POST', `/DOCS/${config.language}/Get_All_qry_GET_SHOP_LISTS`, params); 
      if(success){
        return{success: true, data: allFirst(removeBadValue(data.ELENCO, 'SEARCH_NAME', 'all')) }
      } else {
        return{success: false, error: error}
      }
    }

  return {getStores}
}

export default Docs
import Bullet, {
  Margin, Size, Tooltip,
} from 'devextreme-react/bullet';
// import Cookies from 'js-cookie';

// const currency = Cookies.get('currency');

// const customizeTooltip = (data) => ({
//     text: `${Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(data.value)}%`,
//   });

  const DashBullet = ({ cellData, maxValue, currencyQty }) => {
    const { value } = cellData;
    const normalizedValue = (value / maxValue) * 100;  

    return(
    <div style={{ 
      position: 'relative', 
      width: '100%', height: '30px' }}
      className='d-flex align-items-center'
      >
        {currencyQty == 'currency' && (
          <Bullet
          id='bullet'
          showTarget={false}
          showZeroLevel={true}
          value={normalizedValue}
          startScaleValue={0}
          endScaleValue={100} // Adjust endScaleValue if values are not in percentage
          color='#8ac2e7'
          >
          <Size
              width={100}
              height={35}
          />
          <Margin
              top={5}
              bottom={0}
              left={5}
              right={5}
          />
          <Tooltip
              enabled={false}
              // paddingTopBottom={2}
              // zIndex={5}
              // customizeTooltip={customizeTooltip}
          >
              {/* <Font size={18} /> */}
          </Tooltip>
          </Bullet>
          )
        }
      <div
        style={{
        position: 'absolute',
        top: '50%',
        right: '0px',
        transform: 'translate(-0%, -50%)',
        //color: '#000', // Adjust text color if needed
      }}
      >
        {/* {`${currency}${value}`} */}
        {Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value)}
        {/* {Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)} */}
      </div>

            {/* <span style={{ marginRight: '10px', zIndex: 3 }}>{`${currency} ${value}`}</span> */}
    </div>
  );}
  export default DashBullet;
// import { useState, useCallback } from "react";
import PropTypes from 'prop-types';
// import 'devextreme/dist/css/dx.material.blue.light.css';

import { Switch } from "devextreme-react/switch";
// import notify from "devextreme/ui/notify";



function DxSwitch({value, onValueChanged}) {
   
    return (
        <Switch 
            value={value}
            width={35}
            rtlEnabled={false}
            // hint={hintMessage}
            onValueChanged={onValueChanged}
        />
    );
}

export default DxSwitch

DxSwitch.propTypes = {
    value: PropTypes.bool,
    onValueChange: PropTypes.func
};
import { DropDownButton } from 'devextreme-react/drop-down-button'
import { useCallback } from 'react'

const dropDownOptions = {
    width: '280px',
  }

const DropDownMenu = ({menuItems, setValue}) => {

    const onItemClick = useCallback((e) => {
        setValue(e.itemData.id)       
    }, [setValue])


  return (
    <DropDownButton
    keyExpr="id" // warning: put it in the array of obj that is passed
    displayExpr="text" // warning: put it in the array of obj that is passed                
    // className='overflow-menu'
        items={menuItems}
        // visible={menuVisible}
        icon='overflow'
        stylingMode='text'
        showArrowIcon={false}
        // dropDownOptions={{ width: 'auto' }}
        dropDownOptions={dropDownOptions}
        hideOnOutsideClick={true}
        onItemClick={onItemClick}
    />  )
}

export default DropDownMenu



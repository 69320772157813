import App from './App'
import { LanguageProvider } from './contexts/LanguageProvider'
import { AuthProvider } from './contexts/LoginAuthProvider'
import { ProductProvider } from './contexts/ProductProvider'

function Providers() {
    return (
      <LanguageProvider>
        <AuthProvider>  {/* this allows te whole app to access the authentication status from useContext */} 
          <ProductProvider>  
            <App /> {/* appLayout has an outlet to insert children in it */}
          </ProductProvider>
        </AuthProvider>
      </LanguageProvider>
    )
  }

  export default Providers
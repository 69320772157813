import { LoadIndicator } from 'devextreme-react/load-indicator'
import { AdaptiveLayout, PieChart } from 'devextreme-react/pie-chart'
import { Connector, Font, Label, Legend, LoadingIndicator, Margin, Series, Size } from 'devextreme-react/pie-chart';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Stock from '../../api/ApiCallsStock';
import notify from 'devextreme/ui/notify';
import { ProductContext } from '../../contexts/ProductProvider';
import useTranslate from '../../utils/useTranslate';
// const mock = [
//     { name: 'Software', value: 500000 },
//     { name: 'Hardware', value: 300000 },
//     { name: 'Services', value: 200000 },
//     { name: 'Support', value: 150000 },
//     { name: 'Training', value: 100000 },
// ];

const SalesDashboardPieChart = ({filter, formatDateToYMD}) => {


  const {calendarDate, dashboardStoreId, dashboardSupplierId, timeFrame, isGross, isCumulative, salesByChannel, setSalesByChannel} = useContext(ProductContext);
  // const {formatDateToYMD} = useDateHandling();
  const { getDashboard4 } = Stock() //removed getSalesByPartition, 
  const abortControllerRefPartChannel = useRef(null);
  const [activeRequestCount, setActiveRequestCount] = useState(0); 
  const [isLoading, setLoading] = useState(false)
  const translate = useTranslate();
  const [errors, setErrors] = useState(false)
  // const [errorMessage, setErrorMessage] = useState(false)

  const partitionNameValueField = useMemo(() => isGross ? 'TOTAL_GROSS' : 'TOTAL_NET' , [isGross])

  const noDataText = translate('No data');



      //PIE CHART
  //Gets sales divided by sale channel to populate the bottom pie chart
  const fetchSalesByChannel = useCallback(async(period) => {
    setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly
    setLoading(true);
    setErrors(false);
    // setErrorMessage(false)


    const optionalDate = calendarDate ? formatDateToYMD(calendarDate) : ''
    try {
      if (abortControllerRefPartChannel.current) {
        abortControllerRefPartChannel.current.abort();
      }
      abortControllerRefPartChannel.current = new AbortController();
      const signal = abortControllerRefPartChannel.current.signal;
      //id store actually not necessary because qhen user selects a shop the table w partition by shop disappears, yet put for constance with other api calls
      //"ID_STORE": "00000000-0000-0000-0000-000000000000",
    //   let data = await getSalesByPartition(`{
    //     "PERIOD": "${period}",
    //     "ID_STORE": "${dashboardShopId}",
    //     "ID_SUPPLIER": "${dashboardSupplierId}",
    //     "ID_SALE_CHANNEL": "00000000-0000-0000-0000-000000000000",
    //     "DOCTYPE": "ALL",
    //     "TO_DATE": "${optionalDate}",
    //     "PARTITION_BY": "SALE_CHANNEL"
    // }`, signal);    

    //OLD API CALL
      // let data = await getSalesByPartition(`{"PERIOD":"${period}","ID_STORE":"${dashboardShopId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"ALL","TO_DATE":"${optionalDate}","PARTITION_BY":"SALE_CHANNEL"}`,signal);  
    //NEW API CALL
      let { success: success, data: data, error: error} = await getDashboard4(`{"PERIOD":"${period}","ID_STORE":"${dashboardStoreId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"ALL","TO_DATE":"${optionalDate}","PARTITION_BY":"SALE_CHANNEL"}`,signal);   
      //DOCTYPE     ("ALL" tutti i dati assieme,"DIVIDED" tutti i dati divisi ,"INSTORE","ONLINE") 
      // TO_DATE
      // PARTITION_BY  ("" tutti i dati assieme,"PERIOD" divisi per frazione del periodo ,"STORE","SALE_CHANNEL")

      // data.map(store => {
      //   //problem ASK: RUNNING non c'è nella risposta di questa call
      //   const running = isGross ? store.TOTAL_GROSS : store.TOTAL_NET
      //   // const lastToDate = isGross? store.LTD_TOTAL_GROSS : store.LTD_TOTAL_NET;
      //   // const target = lastToDate == 0 ? 0 : (running * 100 / lastToDate) - 100
      //   return {
      //     ...store,
      //     TARGET: target
      //   }
      // })



      
      // if(success) {
        setSalesByChannel(data)
        // console.log(data, 'pieChart dataError');
      // } else {
      //   // setErrors(error?.message || 'Error');
      //   setErrors(true);
      // }
    } catch (error) {
      // console.log('Request canceled dash3 PROVIDER ERR', error.name);
      if (!error.name === 'CanceledError') {  //if api call not aborted 
        notify(translate(error.message) || translate('Error'), 'error', 3000);
        // setErrors(true);
      }
    } finally {
      setActiveRequestCount(prevCount => prevCount - 1);
      // setErrors(salesByChannel ? false : true);
    }
  }, [calendarDate, timeFrame, dashboardStoreId, dashboardSupplierId]) //removed: getSalesByPartition, 

  useEffect(() => {
    setLoading(activeRequestCount > 0);
  }, [activeRequestCount])

  // if after loading is set to false, data is not populated, then set the error
  useEffect(() => {
    if(!isLoading) setErrors(salesByChannel ? false : true);
  }, [salesByChannel, isLoading])
  

  useEffect(() => {
    if (dashboardSupplierId && dashboardStoreId ) {
      fetchSalesByChannel(timeFrame);
    }
  }, [dashboardSupplierId, dashboardStoreId, timeFrame, calendarDate ]); //removed docType, isCumulative , isGross, filter ( bc function is already triggered when supplID or storeID change, gross is handled downstream),

  const salesByChannelAbsolute = useMemo(() => {
    return salesByChannel?.map(item => ({
      ...item,
      [partitionNameValueField]: Math.abs(item[partitionNameValueField]),
      originalValue: item[partitionNameValueField] //is ok to have just 1 (gross or net) bc it will be recalculated at every render
    }));
  }, [salesByChannel, partitionNameValueField]);

  const customLabel = (arg) => {
    const originalValue = arg.point.data.originalValue;
    // return `${Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(originalValue)} (${(arg.percent*100).toFixed(1)}%)`;
    const total = salesByChannel?.reduce((acc, item) => acc + item[partitionNameValueField], 0);
    const percentage = ((originalValue / total) * 100).toFixed(1);
    return `${Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(originalValue)} (${percentage}%)`;
  };

  const customLegend = (pointInfo) => {
    const item = salesByChannel[pointInfo.pointIndex];
    // const fieldValue = isGross ? 'TOTAL_GROSS' : 'TOTAL_NET';
    // const value = item[fieldValue];
    const value = item[partitionNameValueField]; // Original value (can be negative)
    // const total = salesByChannel.reduce((acc, item) => acc + Math.abs(item[partitionNameValueField]), 0);
    const total = salesByChannel.reduce((acc, item) => acc + item[partitionNameValueField], 0);
    // const percentage = ((Math.abs(value) / total) * 100).toFixed(1) + "%";
    const percentage = ((value / total) * 100).toFixed(1) + "%";
    return `${pointInfo.pointName}  |  ${Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value)} (${percentage})`;
  };
 
  //onClick on chart, hide or show the selected slice
  const toggleVisibility = 
  // useCallback(
    (item) => {
    item.isVisible() ? item.hide() : item.show();
  }
  // , [])
  
  
  const legendClickHandler = 
  // useCallback(
    (e) => {
    const arg = e.target;
    const item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
    toggleVisibility(item);
  }
  // ,[toggleVisibility]);


  return (

    isLoading ? (
      <LoadIndicator
        visible={isLoading} 
      />
    ) : (
    <div>
    {
    errors ? (
      <p className='pt-2'>
        <i className='dx-icon-pulldown big-number text-end'></i>
      </p>
    ) : (    
    salesByChannel?.length == 0 ? (
      <span className='lighter-grey'>{noDataText}</span>
    ) : (
    <PieChart 
        // dataSource={mock}
        // dataSource={salesByChannel}
        dataSource={salesByChannelAbsolute}
        // dataSource={chartData}
        type='doughnut'
        // diameter={0.8} 
        // innerRadius={0.6}        
        diameter={0.4} 
        // innerRadius={0.3}
        resolveLabelOverlapping='shift'
        width={'100%'}
        height={'auto'} //default is 400
        // onPointClick={pointClickHandler} //to add the show/hide onClick on the graph instead of the legend
        onLegendClick={legendClickHandler}
    >
      {/* <AdaptiveLayout keepLabels={true} /> */}
      {/* <LoadPanel enabled /> */}
      <Legend
        orientation="horizontal"
        itemTextPosition="right"
        horizontalAlignment="center"
        verticalAlignment="bottom"
        // columnCount={1}
        // content={(obj) =>
        //   obj.payload.map((item, index) => (
        //     <div>
        //       {item.value}: {item.payload.value}
        //     </div>
        //   ))}
        // customizeText={(items) => items.map(item => ({ ...item, text: customLegend(item) }))}
        customizeText={customLegend}
      />
        {/* <Margin top={70} /> */}
        
      <Series argumentField='PARTITION_NAME' valueField={partitionNameValueField}>
        <Label //FROM HERE to do upd position
          // backgroundColor='none' radialOffset={-20} visible customizeText={(pointInfo) => pointInfo['percentText']}
          visible={true}
          position="columns"
          customizeText={customLabel}
          // width={50}
          // wordWrap='normal'
          >
          <Font size={10} />
          <Connector visible={true} width={0.5} />
          {/* <Font color='#757575' size={15} /> */}
          
        </Label>
      </Series>
      {/* <Size height={300} /> */}
    </PieChart>
    )
    )}
    </div>
    )
  )
}

export default SalesDashboardPieChart




  // const totalWithSigns = useMemo(() => {
  //   return salesByChannel?.reduce((acc, item) => acc + item[partitionNameValueField], 0);
  // }, [salesByChannel, partitionNameValueField]);


// const salesByChannelAbsolute = useMemo(()=>{
//   console.log('saleschan', salesByChannel);
  
//   salesByChannel?.map(item => ({
//     ...item,
//     [partitionNameValueField]: Math.abs(item[partitionNameValueField]) // Use absolute values for chart
//   }))
// },[salesByChannel, partitionNameValueField])


  // const customLabel = 
  // // useCallback(
  //   (arg) => {       
  //     const value = salesByChannel.find(item => item.PARTITION_NAME === arg.argument)[partitionNameValueField];
  //     return `${Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value)} (${(arg.percent * 100).toFixed(1)}%)`;
  //   // return `${Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(arg.valueText)} (${(arg.percent*100).toFixed(1)}%)`; 
  // }
  // // , [partitionNameValueField, salesByChannel])

  // const customLegend = 
  // // useCallback(
  //   (pointInfo) => {
  //   const item = salesByChannel[pointInfo.pointIndex];
  //   // const fieldValue = isGross ? 'TOTAL_GROSS' : 'TOTAL_NET' 
  //   // const value = item[fieldValue];
  //   const value = item[partitionNameValueField]; // Original value (can be negative)
  //   const absValue = Math.abs(value); // Absolute value for pie chart rendering
  //   // const total = salesByChannel.reduce((acc, item) => acc + item[fieldValue], 0);
  //   const total = salesByChannel.reduce((acc, item) => acc + Math.abs(item[partitionNameValueField]), 0); // Sum of absolute values
  //   // const percentage = ((value / total) * 100).toFixed(1) + "%";
  //   const percentage = ((absValue / total) * 100).toFixed(1) + "%";
  //   return `${pointInfo.pointName}  |  ${Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value)} (${percentage})`;
  // }
  // // ,[isGross, salesByChannel])






    // function pointClickHandler(e) { //to add the show/hide onClick on the graph instead of the legend
  //   toggleVisibility(e.target);
  // }

  // const legendClickHandler = (e) => {
  //   console.log(e, 'legendClickHandler')
  //   const series = e.target;
  //   if (series.isVisible()) {
  //     series.hide();
  //   } else {
  //     series.show();
  //   }
  // } 



  // nome campoo, valore, percentuale
  // const customLegend = (arg) => {
  //   console.log(arg, 'arg pie chart')

  //   // get the data item from the dataSource using the index
  //   const dataItem = salesByChannel[arg.pointIndex]; 
  //   const value = dataItem[isGross ? 'TOTAL_GROSS' : 'TOTAL_NET'];
    
  //   // calculate the total value to get the percentage
  //   const total = salesByChannel.reduce((acc, item) => acc + item[isGross ? 'TOTAL_GROSS' : 'TOTAL_NET'], 0);
  //   const percentage = ((value / total) * 100).toFixed(0) + '%';

  // //   <table> 
  // //   <tr>
  // //     <td><div style="width: 12px; height: 12px; background-color: ${arg.pointColor};"></div></td>
  // //     <td>${arg.pointName}</td>
  // //     <td>${Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)}</td>
  // //     <td>${percentage}</td>
  // //   </tr>
  // // </table>   

  // // Add colored square, field name, value, and percentage
  //   return `${arg.pointColor} ${arg.pointName}: ${Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value)} (${percentage})`;
  //   // (`
  //   //     <div>
  //   //       <div style="width: 12px; height: 12px; background-color: ${arg.pointColor};"></div>
  //   //       <div>${arg.pointName}</div>
  //   //       <div>${Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(value)}</div>
  //   //       <div>${percentage}</div>
  //   //     </div>
  //   //   `);
  // }


  // const customLegend = (arg) => {
  //   console.log('legend', arg);
  //   console.log('data', salesByChannel);
    
  //   //search the value pointName in the data, returns the value that populates the graph
    
  //   return `${arg.pointName}: ${Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(arg.pointValue)} (${arg.percentText})`;  // (`
  // }

  
  // const customLegend = (arg) => {
  //   console.log('legend', arg);
  //   console.log('data', salesByChannel);
    
  //   arg.payload.map((item) => (
  //     <div>{item.value}: {item.payload.value}</div>
  //   ))
  //   return `${arg.pointName}: ${Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(arg.pointValue)} (${arg.percentText})`;  // (`
  // }
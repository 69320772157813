// import { DropDownBox } from 'devextreme-react/drop-down-box';
// import { List } from "devextreme-react/list";
import { useRef, useCallback, useContext } from 'react';
// import { useDispatch, useSelector  } from "react-redux";
// import { setStateTypeSearch } from "../store/stateSlice";
import useTranslate from '../utils/useTranslate';
import { ProductContext } from '../contexts/ProductProvider';
import { SelectBox } from 'devextreme-react/select-box';
 


const dropdownOptions = {
    inputAttr:{
        autocomplete: 'new-password',
    }
}

function DropDownItemBarcode() {

    const {typeSearch, setTypeSearch, setStockPagination} = useContext(ProductContext)
    const translate = useTranslate();

    const options = [
        { text: translate('Item'), value: "Item" },
        { text: translate('Barcode'), value: "Barcode" },
      ];

    // const item = translate("Item")
    // const barcode = translate("Barcode")
    // // const options = [item, barcode];
    const listRef = useRef(null); 
    
    // const dispatch = useDispatch(); //this allows to store new data in the redux store

    //todo: delete after tests
    // const state = useSelector((state)=> state.state) //to summon the state


//to display the correct selection in the dropdown box, DO NOT DELETE
    // const [selectedOption, setSelectedOption] = useState('Item'); //todo: understand if put the default value here or in the "controller" in the productLookup 
    // const dropDownBoxRef = useRef(null);
    // const changeDropDownBoxValue = useCallback((arg) => {
    //     setSelectedOption(arg.addedItems[0]);
    //     // console.log('carloooooooooooooo')
    //     dropDownBoxRef.current.instance().close();
    // }, []);

//to update typesearch that will be used by product lookup "controller"
    const onValueChanged = useCallback((e) => {
        // onSearchChanged(e.value)
        setTypeSearch(e.value)
        setStockPagination(1)
        // console.log('entro qui');
        
        
        // setSelectedOption(e.value); //to display the selected option in the UI
        // dispatch(setStateTypeSearch(e.value))
    }, []);    


    // useEffect(() => {
    //     // console.log(state.typeSearch); //todo: remove
    //     onSearchChanged() //question: what does this function do, hence eventully change order of onSearch and dispatch
    // }, [state.typeSearch]);
    
    return (
        <div className='dropdown-item-barcode'>
            <SelectBox 
                className='typesearch-dropdown'
                dataSource={options}
                displayExpr= 'text'
                valueExpr='value'   
                defaultValue={typeSearch} //todo: change, if user changes in settings, this does not upd
                // searchEnabled={true}
                onValueChanged={onValueChanged}
                // width={100}
                // height={30}
                // defaultValue={languages[0].value} //todo: change, if user changes in settings, this does not upd
                placeholder={false}
                acceptCustomValue={false}
                editorOptions={dropdownOptions}
                // fieldRender={renderField}
              />
        </div>
    );
}

export default DropDownItemBarcode;
{/* <DropDownBox
    dataSource={options}
    // value={selectedOption}
    displayExpr= 'text'
    valueExpr='value'  
    defaultValue={typeSearch}
    ref={dropDownBoxRef}
    onValueChanged={onValueChanged}>
    <List
        ref={listRef}
        dataSource={options}
        allowItemDeleting={false}
        selectionMode='single'
        onSelectionChanged={changeDropDownBoxValue}
    />
</DropDownBox>           */}
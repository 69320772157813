import {LanguageContext} from '../contexts/LanguageProvider';
import itMessages from "devextreme/localization/messages/it.json";
import enMessages from "devextreme/localization/messages/en.json"; // Optional, as en is often default
import { locale, loadMessages } from "devextreme/localization";
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import useTranslate from '../utils/useTranslate';
import { ButtonItem, ColCountByScreen, EmailRule, EmptyItem, Form, GroupItem, Label, PatternRule, RequiredRule, SimpleItem } from 'devextreme-react/form';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Docs from '../api/ApiCallsDocs';
import Subject from '../api/ApiCallsSubject';
import Helper from '../api/Helper';
import { ProductContext } from '../contexts/ProductProvider';
import config from '../utils/constants/config';
import InventoryFooter from './InventoryFooter';

loadMessages(itMessages);
loadMessages(enMessages);

const InventoryNew = () => {
  const translate = useTranslate();
//   const { saveCustomer } = Subject();
  const [isLoading, setLoading] = useState(false);
  const formData = useRef({ description: '', date: '', store: '', type: '', supplier: '', attribute1: '', value1: '', attribute2: '', value2: '', attribute3: '', value3: ''});
  const formRef = useRef(null);
  const [errors, setErrors] = useState(null)
  const { getStores } = Docs();
  const {getSuppliers} = Subject();
  const { allFirst, addAllFirst } = Helper();

  const [ stores, setStores] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  // do not use provider bc currently htese calls also set other parameters, which is useless to call now
  // const { stores, setStores, suppliers, setSuppliers,
  //   dashboardStore, setDashboardStore, dashboardStoreId, setDashboardStoreId } = useContext(ProductContext)


  // const colCount = screen.width < 768? 1 : 2;

  const [type, setType] = useState('')
  const [ selectedAttributes, setSelectedAttributes] = useState({
    attribute1: '',
    attribute2: '',
    attribute3: '',
  })
  const [ valueOptions, setValueOptions ] = useState({
    value1: [],
    value2: [],
    value3: [],
  })


  const handleType = (value) => {
    setType(value)
  }

//TODO api call to populate all the list
  const handleAttributeChange = (attributeNumber, selectedValue) => {
    const newValueOptions = { ...valueOptions };
    
    switch (selectedValue) {
      case 'brand':
        newValueOptions[`value${attributeNumber}`] = brandList;
        break;
      case 'composition':
        newValueOptions[`value${attributeNumber}`] = compositionList;
        break;
      case 'line':
        newValueOptions[`value${attributeNumber}`] = lineList;
        break;
      case 'department':
        newValueOptions[`value${attributeNumber}`] = departmentList;
        break;
      case'subcategory':
        newValueOptions[`value${attributeNumber}`] = subcategoryList;
        break;
      case'season':
        newValueOptions[`value${attributeNumber}`] = seasonList;
        break;
      case 'productType':
        newValueOptions[`value${attributeNumber}`] = productTypeList;
        break;
      default:
        newValueOptions[`value${attributeNumber}`] = [];
        break;
    }
    setValueOptions(newValueOptions);
  };



  useEffect(() => {
    if(suppliers.length == 0) {
      fetchSuppliers();
    }
    if(stores.length == 0){
      fetchStores();
    }
  }, []);

  const textFieldEditorOptions = useMemo(()=>({
    labelMode: 'floating', 
    // stylingMode: 'filled', 
    mode: 'text', 
    value: '' ,
    showClearButton: true
  }), [])
  // const noDigitsPattern = /^[^0-9]+$/;
  // const phonePattern = /^\+(?:\d\s?){6,14}\d$/;

  const dateEditorOptions = useMemo(()=>({
    labelMode: 'floating', 
    // stylingMode: 'filled', 
    // mode: 'text', 
    value: '' ,
    acceptCustomValue: false,
    openOnFieldClick: true,
    type: 'date',
    pickerType: "calendar",
    displayFormat:"dd/MM/yyyy",
    showClearButton: true,
    showDropDownButton: true,
    calendarOptions: {
      max: new Date(),
      firstDayOfWeek: 1
    }
  }), [])


  // //take stores from backend, consider to move the call in the provider sicne many pages use the stores
  // const store = [
  //   { text: translate('Male'), value: "M" },
  //   { text: translate("Female"), value: "F" },
  //   { text: translate("Other"), value: "O" },
  //   { text: translate("Prefer not to tell"), value: "N" }
  // ];
  const storesRemovedAll = stores.filter(store => store.SEARCH_NAME != config.all)
  
  const storeEditorOptions = useMemo(()=>({
    dataSource: storesRemovedAll,
    displayExpr: 'SEARCH_NAME',
    valueExpr: 'ID',
    labelMode: 'floating', 
    // stylingMode: 'filled', 
    mode: 'text', 
    value: '' ,
    showClearButton: true,
    showDropDownButton: true,
    placeholder: translate('Select...'),
    searchEnabled: true
  }), [stores, storesRemovedAll]);

  const types = [
    {
      text: translate('Total'),
      value: 'total'
    },
    {
      text: translate('Partial'),
      value: 'partial'
    },
    {
      text: translate('On detected items only'),
      value: 'detected'
    }
  ]
  const typeEditorOptions = useMemo(()=>({
    dataSource: types,
    displayExpr: 'text',
    valueExpr: 'value',
    labelMode: 'floating', 
    // stylingMode: 'filled', 
    mode: 'text', 
    value: '' ,
    showClearButton: true,
    showDropDownButton: true,
    placeholder: translate('Select...'),
    searchEnabled: true,
    acceptCustomValue: false,
    onValueChanged: (e) => handleType(e.value)
  }), []);


  const supplierEditorOptions = useMemo(()=>({
    dataSource: suppliers, //is already w/o "all"
    displayExpr: 'SEARCH_NAME',
    valueExpr: 'ID',
    labelMode: 'floating', 
    // stylingMode: 'filled', 
    mode: 'text', 
    value: '' ,
    showClearButton: true,
    showDropDownButton: true,
    placeholder: translate('Select...'),
    searchEnabled: true,
    acceptCustomValue: false,
  }), [suppliers]);


  //create an array named attributes, with objexts that have text and 
  const attributes = [
    {
      text: translate('Brand'),
      value: 'brand'
    },
    {
      text: translate('Composition'),
      value: 'composition'
    },
    {
      text: translate('Line'),
      value: 'line'
    },
    {
      text: translate('Department'),
      value: 'department'
    },
    {
      text: translate('Subcategory'),
      value: 'subcategory'
    },
    {
      text: translate('Season'),
      value: 'season'
    },
    {
      text: translate('Product Type'),
      value: 'productType'
    },
  ]


  const attributeEditorOptions = useMemo(()=>({
    dataSource: attributes,
    displayExpr: 'text',
    valueExpr: 'value',
    labelMode: 'floating', 
    // stylingMode: 'filled', 
    mode: 'text', 
    value: '' ,
    showClearButton: true,
    showDropDownButton: true,
    placeholder: translate('Select...'),
    searchEnabled: true,
    acceptCustomValue: false,
  }), []);

  const valueEditorOptions = useMemo(()=>({
    // dataSource: value,
    displayExpr: 'text',
    valueExpr: 'value',
    labelMode: 'floating', 
    // stylingMode: 'filled', 
    mode: 'text', 
    value: '' ,
    showClearButton: true,
    showDropDownButton: true,
    placeholder: translate('Select...'),
    searchEnabled: true,
    acceptCustomValue: false,
  }), []);

  const mandatoryRender = () => <p className='text-start ms-3'>{'* '+translate('Required fields')}</p>;
  
  //moved inside function bc needs to access loading state
  const submitButtonOptions = useMemo(()=>({
    text: isLoading ? <LoadIndicator width='24px' height='24px' visible /> : translate('create'),  
    type: 'default',
    useSubmitBehavior: true,
    width: '50%',  
    // todo: se vogliamo una logica peronalizzata usare funzione di seguito, ma in teoria i conttrolli li fa gia dx, invece no quando clicco submit non mi dice un cavolo
    // onClick: function() {
    //   const validationResult = formInstance.validate(); // get Form instance beforehand
    //   if (validationResult.isValid)
    //       document.getElementById("form-container").submit();
    //   else
    //       alert("Form is invalid");
    
  }), [isLoading]);

  // const cancelButtonOptions = useMemo(() => ({
  //   text: translate("Cancel"),
  //   type: 'normal',
  //   useSubmitBehavior: false,
  //   width: '100%',
  //   // onclick: () => 
  // }), [])



  //Get Shops
  const fetchStores = async () => {
    // setErrors(prevErr => ({...prevErr, stores: null}))

    try {
      let { success: success, data: data, error: error } = await getStores(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
      // if(success){        
        // if(!dashboardStore) {
        //   setDashboardStore(data[0].SEARCH_NAME);
        // }
        // if(!dashboardStoreId) {
        //   setDashboardStoreId(data[0].ID);
        // }
        
        setStores(data);
      // } else {
      //   setErrors(prevErr => ({...prevErr, stores: error?.message}))
      // }
      } catch (error) {
        notify(translate(error.message) || translate('Error'), 'error', 3000);
      }
    }

//from product lookup
    const fetchSuppliers = async () => {
      // setErrors(prevErr => ({...prevErr, suppliers: null}))
      try {
        let { success: success, data: data, error: error } = await getSuppliers('{"$id":"1","FILTER":{"GetAll":false, "Sortings":[{"Field":"SEARCH_NAME","Ascending":true}], "Id":"00000000-0000-0000-0000-000000000000", "Id_2":"00000000-0000-0000-0000-000000000000", "Id_3":"00000000-0000-0000-0000-000000000000", "SimpleFilter":"", "QueryFilter":"ID_SUBJECT_STATUS = \'7bba3c89-dbf8-4d8c-a471-03e205062ba7\'", "Filters":[], "Top":-1, "StartIndex":-1, "PageLength":-1, "Parameter":"", "TopParameter":0}}');
        // if (success){
        // data = addAllFirst(data, 'SEARCH_NAME');
        //   if (!stockSupplier) {
        //     setStockSupplier(data[0]?.SEARCH_NAME);  
        //   }
        // // setFilter((prevFilter) => ({ ...prevFilter, supplier: stockSupplier ? stockSupplier : data[0]?.SEARCH_NAME }));
        setSuppliers(data); 
        // } else {
        //   setErrors(prevErr => ({...prevErr, suppliers: error?.message}));
        // }
      } catch (error) {
        notify(translate(error.message) || translate('Error'), 'error', 3000);
      }
    };


  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrors(null)

    console.log('Submitting form data:', formData.current);
    const { description, date, store, type, supplier, attribute1, value1, attribute2, value2, attribute3, value3   } = formData.current;

    try {
      const { success: success, data: data, error: error} = await saveCustomer ({
        description: description,
        date: date,
        store: store,
        type: type,
        supplier: supplier,
        attribute1: attribute1,
        value1: value1,
        attribute2: attribute2,
        value2: value2,
        attribute3: attribute3,
        value3: value3
      });

      //todo: aggiustare in caso cambino i camppi della risposta
      // if (result.isOk) {
      // if (success) {
        if (!data.SUBJECT.HAS_ERROR && !data.PHYSICAL_SUBJECT.HAS_ERROR && !data.CUSTOMER.HAS_ERROR && !data.PHONES.HAS_ERROR) {
        //todo: empty the fields?
        formRef.current.instance().clear();
        // setLoading(false);
        notify(translate('Inventory created successfully'), 'success', 3000)
        } else {
          //todo: do not empty the fields
        // setLoading(false); 
        notify(translate('Error in creating inventory'), 'error', 2000);
      }
    // } else {
    //  setErrors(error?.message);
    // }
    } catch (error) {
      // throw new Error(error?.message);
    notify(translate('Error in creating inventory'), 'error', 2000);
    } finally {
      setLoading(false); 
    }    
  };
  // setTimeout(() => { 
   //     alert("Submitted");     //todo: fare alert piu carino, con libreria come quella di login o con quella usata per bookstore // no, dx ha alert interni "notification" o simili       
   // }, 1000);                       //todo: show this alert only if callAPI was successful

     //handles calendar translation
  const {language} = useContext(LanguageContext)
  useEffect(() => {
    locale(language)
  }, [language])

  return (
    <>
<form onSubmit={onSubmit} >
      <Form 
          id="inventory-new-form"
          //to do per eventialmente ripopolare i campi se chiamata da errore, senza riscrivere 
          ref={formRef}
          formData={formData.current}
          disabled={isLoading}

          showValidationSummary={true} //todo check if it does something

          className='px-4 px-sm-5 pt-3'
          // colCount={colCount}
          >
          <ColCountByScreen 
            xs={1}  
            sm={type == 'partial' ? 2 : 1} 
            md={type == 'partial' ? 2 : 1} 
            lg={type == 'partial' ? 2 : 1} />

        
        <GroupItem
          caption={translate('New inventory')}
        
        >
          <ColCountByScreen xs={1} sm={1} md={1} lg={1} />

          <SimpleItem 
            dataField='description' 
            editorType="dxTextBox" 
            isRequired={true} 
            editorOptions={textFieldEditorOptions}
          >
            <RequiredRule message={translate('Required field')} />
            {/* <PatternRule message={translate("Do not use digits in this field")}
                pattern={noDigitsPattern} /> */}
            <Label visible={false} text={translate('Description')}/>
          </SimpleItem>

          <SimpleItem 
            dataField='date' 
            editorType="dxDateBox"
            editorOptions={dateEditorOptions}
          > 
            {/* <RangeRule max={maxDate} message="You must be at least 18 years old" />             */}
            <Label visible={false}  text={translate('Date')}/>
          </SimpleItem>
          

{/* todo check if user can type, impair */}
          <SimpleItem 
            dataField='store'
            editorType="dxSelectBox"
            editorOptions={storeEditorOptions}
            >
            <Label visible={false} text={translate('Store')}/>
          </SimpleItem>

          <SimpleItem 
            dataField='type'
            editorType="dxSelectBox"
            editorOptions={typeEditorOptions}
            // onValueChanged={(e) => handleType(e.value)}
            >
            <Label visible={false} text={translate('Type')}/>
          </SimpleItem>
        </GroupItem>



{type == 'partial' &&
        <GroupItem 
          // cssClass='pt-1'
          caption={translate('Partial inventory')}
        >
          <ColCountByScreen xs={2} sm={2} md={2} lg={2} />

          <SimpleItem 
            dataField='supplier'
            editorType="dxSelectBox"
            editorOptions={supplierEditorOptions}
            colSpan={2}
            >
            <Label visible={false} text={translate('Supplier')}/>
          </SimpleItem>

          <SimpleItem 
            dataField='attribute1'
            editorType="dxSelectBox"
            editorOptions={attributeEditorOptions}
            colSpan={1}
            onValueChanged={(e) => handleAttributeChange(1, e.value)}
            >
            <Label visible={false} text={translate('Attribute')}/>
          </SimpleItem>

          <SimpleItem 
            dataField='value1'
            editorType="dxSelectBox"
            editorOptions={valueEditorOptions}
            colSpan={1}
            >
            <Label visible={false} text={translate('Value')}/>
          </SimpleItem>

          <SimpleItem 
            dataField='attribute2'
            editorType="dxSelectBox"
            editorOptions={attributeEditorOptions}
            colSpan={1}
            onValueChanged={(e) => handleAttributeChange(2, e.value)}
            >
            <Label visible={false} text={translate('Attribute')}/>
          </SimpleItem>

          <SimpleItem 
            dataField='value2'
            editorType="dxSelectBox"
            editorOptions={valueEditorOptions}
            colSpan={1}
            >
            <Label visible={false} text={translate('Value')}/>
          </SimpleItem>

          <SimpleItem 
            dataField='attribute3'
            editorType="dxSelectBox"
            editorOptions={attributeEditorOptions}
            colSpan={1}
            onValueChanged={(e) => handleAttributeChange(3, e.value)}
            >
            <Label visible={false} text={translate('Attribute')}/>
          </SimpleItem>

          <SimpleItem 
            dataField='value3'
            editorType="dxSelectBox"
            editorOptions={valueEditorOptions}
            colSpan={1}
            >
            <Label visible={false} text={translate('Value')}/>
          </SimpleItem>
        </GroupItem>
     }
        <SimpleItem render={mandatoryRender} colSpan={2} />
        {/* </GroupItem> */}


      {/* <GroupItem>
        <ColCountByScreen xs={2} sm={2} md={2} lg={2} /> */}

        {/* <ButtonItem 
          horizontalAlignment="center"
          colSpan={1}
          buttonOptions={cancelButtonOptions} 
          className='bg-light-grey'
        /> */}
        <ButtonItem 
          horizontalAlignment="center"
          colSpan={2}
          buttonOptions={submitButtonOptions} 
        />
          {/* <ButtonOptions width='100%' type='default' useSubmitBehavior={true}>
            <span className='dx-button-text'>{loading ? <LoadIndicator width='24px' height='24px' visible /> : 'SUBMIT'}</span>
          </ButtonOptions> */}
        {/* </GroupItem> */}
      </Form>
        {/* <InventoryFooter/> */}
    </form>
    </>
  )
}

export default InventoryNew
import { format } from 'date-fns';


export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const httpToHttps = (url) => {
  return url.replace('http://', 'https://');
}

export const getStatusClass = (status) => {
  switch (status) {
    case 'inserted': //inserito
      return 'status-inserted';
    case 'waybill': //lettera vettura
      return 'status-waybill';
    case 'processing': //lavorazione
      return 'status-processing';
    case 'dispatched': //or processed  //evaso
      return 'status-dispatched';
    case 'returned': //or processed  //evaso
      return 'status-returned';          
    // case 'partially returned': //or processed  //evaso
    case 'part. returned': //or processed  //evaso
      return 'status-part-returned';
    default:
      return '';
  }
};

export const getStatusColor = (status) => {
  const colorMap = {
    'all': 'red',
    'inserted': "rgba(114, 165, 207, 0.5)",
    'processing': "rgba(238, 186, 105, 0.5)",
    'waybill': "rgba(207, 201, 99, 0.5)",
    'dispatched': "rgba(213, 213, 213, 0.5)",
    'returned': "rgba(104, 33, 122, 0.5)",
    'part. returned': "rgba(171, 132, 174, 0.5)"
  };
  return colorMap[status];
};

export const formatDateToYMD = (date) => {
  return format(date, 'yyyy-MM-dd');
};

export const formatDateToYMDHM = (date) => {
  const now = new Date();
  date.setHours(now.getHours(), now.getMinutes());
  return format(date, "yyyy-MM-dd HH:mm");
};

 
// import { TextBox } from 'devextreme-react/text-box';
// import { useCallback, useEffect, useState } from 'react';

import { useContext, useEffect, useState } from 'react';
import FilterPopup from './FilterPopup';
import config from '../utils/constants/config'
// import { useDispatch, useSelector } from 'react-redux';
// import { setFilter } from '../store/filterSlice';
import useTranslate from '../utils/useTranslate';
import ProductLookupOptionsPopup from './ProductLookupOptionsPopup';
import { expireOptions } from '../utils/constants/expireOptions';
import Cookies from 'js-cookie';
import { ProductContext } from '../contexts/ProductProvider';


// const filters = ["Filter 1", "Filter 2", "Filter 3"]; //TODO variates based on the page, passed as prop, then import filters from the righ constant in constant file, then delete this demo 
const filtersProductLookup = [
    {
        label: "season",
        // image: 'fa-regular fa-calendar-check',
        image: 'fa-solid fa-calendar-check',
        // image: 'fa-solid fa-calendar-day',
    },
    {
        label: "department",
        // image: 'fa-regular fa-building',
        image: 'fa-solid fa-building',
    },
    {
        label: "supplier",
        // image: "fa-solid fa-tag",
        image: "fa-solid fa-truck",
    },
    {
        label: "options",
        image: "fa-solid fa-toggle-on",
    }
];


function FooterMenuProductLookup({seasons, departments, suppliers, selectFilter, filter}) {
    const translate = useTranslate();
    const [currentFilterIndex, setCurrentFilterIndex] = useState(null);
    const { availableOnly, setAvailableOnly, setStockPagination } = useContext(ProductContext)


    const handleAvailableOnly = (e) => {
        setAvailableOnly(e.value); //bool
        Cookies.set('lookup-available-only', e.value, { expires: expireOptions });
        // dashboard(tab);
        setStockPagination(1)
    };
    // const [selectedOption, setSelectedOption] = useState(config.all);
    // const [isPopupVisibleSeason, setIsPopupVisibleSeason] = useState(false);
    // const [isPopupVisibleDepartment, setIsPopupVisibleDepartment] = useState(false);
    // const [isPopupVisible, setIsPopupVisible] = useState(false);


    // const dispatch = useDispatch()
    // const filter = useSelector((state)=> state.filter) 
    // const state = useSelector((state)=> state.state) 

    const togglePopup = (index) =>{
        setCurrentFilterIndex(index === currentFilterIndex ? null : index);
        // console.log(filtersProductLookup[index].label, 'label');

        // switch(index){
        //     case 0:
        //         setIsPopupVisibleSeason(!isPopupVisibleSeason);
        //         break;
        //     case 1:
        //         setIsPopupVisibleDepartment(!isPopupVisibleDepartment)
        //         break;
        //     case 2:
        //        //do something for filter 3
        //        break;
        //     default:
        //         break;  // if index is not 0, 1, or 2, do nothing. This is a placeholder. You might want to add a default case or handle this differently.  //todo: gestire l'eventuale mancanza di filtro
        // }
        // setIsPopupVisible(!isPopupVisible)
    }


    //manages the selection of one option, returns the filtered items
    // const handlePopupClick = (option, flag) => {     
    //     dispatch(setFilter({[flag] : option}))
    //     setCurrentFilterIndex(null) // to close the popup
    //     // console.log(option)
    //     // // if () //todo se clicco nel popup 2 deve cambiare il valore dellopzione 2
    //     // setSelectedOption(option)
    //     //todo: save in store e.target.value? isit necessary? do we want the selection to persist?

    //     // togglePopup()
    // }
    // const [value, setValue] = useState("");

    // const onValueChange = useCallback((v) => {
    //     setValue(v)
    //     console.log(value);
    // }, [value]);

    // const selectedOption = 'selected';
    // TODO fetch data based on the selected filter from the backend and update the state with the new data OR filter already existent json and display it?

    //todo capire come targettare i singoli options senza settarli tutti con l'utimo valore selezionato
 
    return (
        <>        
            <div className="footer-menu d-flex">
                {
                    filtersProductLookup.map((staticFilter, index) =>(
                    <div 
                        key={index} 
                        // className='filter-item flex-grow-1' 
                        className={`filter-item flex-grow-1 ${index == 3 ? 'd-flex flex-column justify-content-center' : ''}`} 
                        onClick={() => togglePopup(index)}>

                                {index == 3  ? ( 
                                <>
                                    <div><i className={`${staticFilter.image} fs-18 ${availableOnly ? 'selected' : ''}`}></i></div>
                                    <p className={`text-uppercase m-0 ${availableOnly ? 'selected' : ''}`}>{translate(staticFilter.label)}</p>
                                </>
                                ) : (filter[staticFilter.label] == '<ALL>' ? (
                                    <>
                                    <div><i className={`${staticFilter.image} fs-18`}></i></div>
                                    <p className='text-uppercase m-0'>{translate(staticFilter.label)}</p>
                                    </>
                                ) : (
                                    <>
                                    <div><i className={`${staticFilter.image} fs-18 selected`}></i></div>
                                    <p className='m-0 selected'>{translate(filter[staticFilter.label])}</p>
                                    </>
                                ))}     
                        {/* {filter[staticFilter.label] == '<ALL>' ? (
                            <>
                            <div><i className={`${staticFilter.image} fs-18`}></i></div>
                            <p className='text-uppercase m-0'>{translate(staticFilter.label)}</p>
                            </>
                        ) : (
                            <>
                            <div><i className={`${staticFilter.image} fs-18 selected`}></i></div>
                            <p className='m-0 selected'>{translate(filter[staticFilter.label])}</p>
                            </>
                        )
                        } */}
                            {currentFilterIndex === index &&(
                                (currentFilterIndex == 3) ? (
                                    <ProductLookupOptionsPopup 
                                        isPopupVisible={currentFilterIndex === index} 
                                        togglePopup={()=> togglePopup(index)}
                                        handleAvailableOnly={handleAvailableOnly}
                                        availableOnly={availableOnly}  
                                        // handleCumulativeChange={handleCumulativeChange}
                                        // isCumulative={isCumulative}
                                    />
                                    
                                ) :
                                (
                                <FilterPopup 
                                    isPopupVisible={currentFilterIndex === index} //true if index matches, else false
                                    togglePopup={()=> togglePopup(index)} 
                                    // handlePopupClick={()=>handlePopupClick(index)} 
                                    content={
                                        staticFilter.label === "season"? seasons : 
                                        staticFilter.label === "department"? departments : 
                                        staticFilter.label === "supplier" ? suppliers : 
                                        []
                                    }
                                    filterType={staticFilter.label}
                                    selectFilter={selectFilter}
                                    //differentially pass the content to the global popup based on the label of the filter
                                    />
                                )
                            )}
                    </div>
                    ))
                }
            </div>
            {/* <FilterPopup isPopupVisible={isPopupVisible} togglePopup={togglePopup} /> */}
            {/* must pass a prop to identify which list has to be rendered and to change just the val of that one folter, not all */}

        </>
    );
}
 
export default FooterMenuProductLookup;


{/* <>        
            <div className="footer-menu d-flex">
                {
                    filtersProductLookup.map((staticFilter, index) =>(
                    <div key={index} className='filter-item flex-grow-1' onClick={() => togglePopup(index)}>
                            <p className='m-0'>{translate(filter[staticFilter.label])}</p>
                            <div>
                                <i className={staticFilter.image}></i>
                            </div>
                            <p className='text-uppercase m-0'>{translate(staticFilter.label)}</p>
                            {currentFilterIndex === index &&(
                                <FilterPopup 
                                    isPopupVisible={currentFilterIndex === index} //true if index matches, else false
                                    togglePopup={()=> togglePopup(index)} 
                                    // handlePopupClick={()=>handlePopupClick(index)} 
                                    content={
                                        staticFilter.label === "season"? seasons : 
                                        staticFilter.label === "department"? departments : 
                                        staticFilter.label === "supplier" ? suppliers : 
                                        []
                                    }
                                    filterType={staticFilter.label}
                                    selectFilter={selectFilter}
                                    //differentially pass the content to the global popup based on the label of the filter
                                    />

                            )}
                    </div>
                    ))
                }
            </div>
            {/* <FilterPopup isPopupVisible={isPopupVisible} togglePopup={togglePopup} /> 
            {/* must pass a prop to identify which list has to be rendered and to change just the val of that one folter, not all 

        </> */}
import { DataGrid, Column, ColumnFixing, LoadPanel } from 'devextreme-react/data-grid';
// import PropTypes from 'prop-types';
// import Bullet, { Size, Tooltip } from 'devextreme-react/bullet';
// import DataGrid, { Column } from 'devextreme-react/data-grid';
// import 'devextreme/dist/css/dx.material.blue.light.css';
import Cookies from 'js-cookie';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import config from "devextreme/core/config";
import { ProductContext } from '../../contexts/ProductProvider';
import useTranslate from '../../utils/useTranslate';
import DashBullet from './SalesDashboardTableBullet';
import Stock from '../../api/ApiCallsStock';
import notify from 'devextreme/ui/notify';
import { LoadIndicator } from 'devextreme-react/load-indicator';
     
config({
    defaultCurrency: 'EUR'
});

const SalesDashboardTable2 = ({filter, isCurrentPeriod, calculateDatePast, calculateDatePastLTD, formatDateToYMD, currencyQty}) => {
  const { getDashboard4 } = Stock() //removed getSalesByPartition
  const abortControllerRefPartStore = useRef(null);
  const {calendarDate, dashboardStoreId, dashboardSupplierId, timeFrame, isGross, isCumulative, salesByStore, setSalesByStore} = useContext(ProductContext)
  // const {formatDateToYMD} = useDateHandling()
  const [activeRequestCount, setActiveRequestCount] = useState(0); 
  const [isLoading, setLoading] = useState(false)
  const translate = useTranslate();
  const currency = Cookies.get('currency');
  const dataGrid = useRef(null);
  const total = useMemo(() => currencyQty == 'quantity' ? 'TOTAL_QTY' : (isGross? 'TOTAL_GROSS': 'TOTAL_NET'), [currencyQty, isGross])
  const last_LTD = useMemo(() => currencyQty == 'quantity' ? 'LAST_LTD_TOTAL_QTY' : (isGross? 'LAST_LTD_TOTAL_GROSS' : 'LAST_LTD_TOTAL_NET'), [currencyQty, isGross])
  // const last_LTD = useMemo(() => isGross? 'LTD_TOTAL_GROSS' : 'LTD_TOTAL_NET', [isGross])
  // const last = useMemo(()=> (isGross? 'LAST_TOTAL_GROSS' : 'LAST_TOTAL_NET'), [isGross])

  const noDataText = translate('No data');
  const storeCaption = translate('Store');
  // const totalCaption = `${translate('Current')} (${currency})`;
  // const lastLTDCaption = isCurrentPeriod ? `${translate('LTD')} (${currency})` : `${translate('Last')} (${currency})`
  const totalCaption = `${translate('Current')}`;
  const lastLTDCaption = isCurrentPeriod ? `${translate('LTD')}` : `${translate('Last')}`
  // const LTDCaption = `${translate('LTD')} (${currency})`
  // const lastCaption = `${translate('Last')} (${currency})`
  const [errors, setErrors] = useState(false)
  //   {
  //   present: null,
  //   past: null,
  // })
  // const [errorMessage, setErrorMessage] = useState(false)


  const maxValue = salesByStore ? Math.max(...salesByStore.map(store => isGross ? store.TOTAL_GROSS : store.TOTAL_NET)) : null;

  const renderBulletCell = useCallback((cellData) => <DashBullet cellData={cellData} maxValue={maxValue} currencyQty={currencyQty} />, [maxValue, currencyQty])
  const formatLTDLastValue = {
    formatter: useCallback((value) => {
      return Intl.NumberFormat('it-IT', { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(value);
    }, [])
  }
  
    //SHOPS TABLE
 //Gets sales divided by store to populate the bottom table
  const fetchSalesByStore = useCallback(async(period) => {
    setActiveRequestCount(prevCount => prevCount + 1); //keep trak of aborted calls to set loader properly
    setLoading(true);
    setErrors(false)
    //   {
    //   present: null,
    //   past: null,
    // })
    // setErrorMessage(false)

    // dataGrid?.current?.instance().beginCustomLoading();

    const optionalDate = calendarDate ? formatDateToYMD(calendarDate) : ''
    const optionalDatePast = isCurrentPeriod? calculateDatePastLTD(optionalDate) : calculateDatePast(optionalDate)
    try {
      if (abortControllerRefPartStore.current) {
        abortControllerRefPartStore.current.abort();
      }
      abortControllerRefPartStore.current = new AbortController();
      const signal = abortControllerRefPartStore.current.signal;


    //OLD API CALL
      // let data = await getSalesByPartition(`{"PERIOD":"${period}","ID_STORE":"${dashboardStoreId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"ALL","TO_DATE":"${optionalDate}","PARTITION_BY":"STORE"}`,signal);    

      // console.log('data', optionalDate, optionalDatePast );
      
    //NEW API CALL  doctype: instore only
      let { success: successPresent, data: dataPresent, error: errorPresent } = await getDashboard4(`{"PERIOD":"${period}","ID_STORE":"${dashboardStoreId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"INSTORE","TO_DATE":"${optionalDate}","PARTITION_BY":"STORE"}`,signal);   

      //if isCurrentPeriod is true then cambia data calendarLTD
      let { success: successPast, data: dataPast, error: errorPast } = await getDashboard4(`{"PERIOD":"${period}","ID_STORE":"${dashboardStoreId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"INSTORE","TO_DATE":"${optionalDatePast}","PARTITION_BY":"STORE"}`,signal);  

      // if(successPresent && successPast) {
      const data = mergePresentPastData(dataPresent, dataPast)

      // data.map(store => {
      //   //problem ASK: RUNNING non c'è nella risposta di questa call
      //   const running = isGross ? store.TOTAL_GROSS : store.TOTAL_NET
      //   const lastToDate = isGross? store.LTD_TOTAL_GROSS : store.LTD_TOTAL_NET;
      //   const target = lastToDate == 0 ? 0 : (running * 100 / lastToDate) - 100
      //   return {
      //     ...store,
      //     TARGET: target
      //   }
      // })
      setSalesByStore(data)
      // console.log(data, 'table dataError');

      // if (data.Message) {
      //   setErrorMessage(true)
      //   // console.log(data.Message, 'table error');
      //   } 
      // } else{
      //   setErrors(true) //of this i will only use a true/false, no need for details
      //   // setErrors(errorPresent?.message || errorPast?.message || 'Error') //of this i will only use a true/false, no need for details
      //   //   {
      //   //   present: errorPresent?.message,
      //   //   past: errorPast?.message
      //   // });
      // }
    } catch (error) {
      // console.log('Request canceled dash2 PROVIDER ERR', error.name);
      if (!error.name === 'CanceledError') {  //if api call not aborted 
        notify(translate(error.message) || translate('Error'), 'error', 3000);
      }
    } finally {
      setActiveRequestCount(prevCount => prevCount - 1);
      // setErrors(salesByStore ? false : true);

      // dataGrid?.current?.instance().endCustomLoading();   
    }
  }, [calendarDate, timeFrame, dashboardStoreId, dashboardSupplierId]) // remoed isGross, present in dataField, filter ( bc function is already triggered when supplID or storeID change, gross is handled downstream), 

  useEffect(() => {
    setLoading(activeRequestCount > 0);
  }, [activeRequestCount])

  // if after loading is set to false, data is not populated, then set the error
  useEffect(() => {
    if(!isLoading) setErrors(salesByStore ? false : true);
  }, [salesByStore, isLoading])
  

  useEffect(() => {
    if (dashboardSupplierId && dashboardStoreId ) {      
      fetchSalesByStore(timeFrame);
    }
  }, [dashboardSupplierId, dashboardStoreId, timeFrame, calendarDate ]); //removed docType , isCumulative, isGross (already handled by mergePresentPastData and by constatns), filter ( bc function is already triggered when supplID or storeID change, gross is handled downstream),


  function mergePresentPastData(presentSales, pastSales) {
      //creates an object where the keys are ID_PARTITION values from dataPast to quick lookup when iterating over datapresent  output: {
    //   "28d8a42a-16fc-4608-b041-c1c5bda033f6": { ...past sale object... },
    //   "3fa7d096-b3fb-4fbf-9be5-33c864a4c7df": { ...past sale object... }
    // }

    const mergedMap = new Map();

      // Process presentSales
    presentSales.forEach(sale => {
      mergedMap.set(sale.ID_PARTITION, {
        ...sale,
        LAST_LTD_TOTAL_GROSS: 0,
        LAST_LTD_TOTAL_NET: 0,
        LAST_LTD_TOTAL_QTY: 0,
      });
    });

    // Process pastSales
    pastSales.forEach(sale => {
      if (mergedMap.has(sale.ID_PARTITION)) {
        // Update existing entry
        const currentSale = mergedMap.get(sale.ID_PARTITION);
        currentSale.LAST_LTD_TOTAL_GROSS = sale.TOTAL_GROSS;
        currentSale.LAST_LTD_TOTAL_NET = sale.TOTAL_NET;
        currentSale.LAST_LTD_TOTAL_QTY = sale.TOTAL_QTY;
      } else {
        // Add new entry for past sale not in present
        mergedMap.set(sale.ID_PARTITION, {
          INTERVAL: null,
          ID_PARTITION: sale.ID_PARTITION,
          PARTITION_NAME: sale.PARTITION_NAME,
          DOCTYPE: null,
          TOTAL_GROSS: 0,
          TOTAL_NET: 0,
          TOTAL_QTY: 0,
          LAST_LTD_TOTAL_GROSS: sale.TOTAL_GROSS,
          LAST_LTD_TOTAL_NET: sale.TOTAL_NET,
          LAST_LTD_TOTAL_QTY: sale.TOTAL_QTY,
        });
      }
    });

    // Convert Map back to array
    return Array.from(mergedMap.values());
  }  
  
  return (
    isLoading ? (
        <LoadIndicator
          visible={isLoading} 
        />
    ) : (
      errors ? (
        <p className='pt-2'>
          <i className='dx-icon-pulldown big-number text-end'></i>
        </p>
      ) : (    
    <DataGrid 
      id='dashboard-grid'
      dataSource={salesByStore}
      keyExpr='ID_PARTITION'
      minWidth={500}
      defaultCurrency='EUR'
      noDataText={noDataText}
      ref={dataGrid}
      >
      <Column 
        dataField="PARTITION_NAME" 
        caption={storeCaption}
        sortOrder="asc"
        alignment='left'
        minWidth={80}
        // headerCellRender={renderTitleHeader} todo se voglio dare stile particolare a header della tabella
        >
      </Column>
      <Column 
        dataField={total}
        caption={totalCaption} 
        alignment='right' 
        minWidth={90}
        allowGrouping={false}
        cellRender={renderBulletCell}
        cssClass="bullet"
      ></Column>

  {/* {isCurrentPeriod ? ( */}
      <Column 
        dataField={last_LTD} 
        caption={lastLTDCaption}
        alignment='right' 
        minWidth={90}
        format={formatLTDLastValue}
        ></Column>
  {/* ) : ( */}
      {/* <Column 
        dataField={last} 
        caption={lastCaption} 
        alignment='right' 
        minWidth={100}
        format={formatLTDLastValue}
        ></Column> */}
  {/* )} */}
    </DataGrid>
      )
    )
    
  )
}

export default SalesDashboardTable2







////////////////////////////////
  // const presentSalesMap = dataPresent.reduce((map, presentSale) => {
  //   map[presentSale.ID_PARTITION] = presentSale;
  //   return map;
  // }, {});

  // const pastSalesMap = dataPast.reduce((map, pastSale) => {
  //   map[pastSale.ID_PARTITION] = pastSale;
  //   return map;
  // }, {});

  // // collect all unique ID_PARTITIONs from both data1 and data2
  // const allPartitions = 

  // // Iterate over dataPresent and merge data with the matching ID_PARTITION
  // const mergedData = dataPresent.map(presentSale => {
  //   const pastSale = pastSalesMap[presentSale.ID_PARTITION];

  //   // add LAST_TOTAL_GROSS and LAST_TOTAL_NET from pastSale (dataPast)
  //   if (pastSale) {
  //     if(!isCurrentPeriod){
  //       return {
  //         ...presentSale,
  //         LAST_TOTAL_GROSS: pastSale.TOTAL_GROSS,
  //         LAST_TOTAL_NET: pastSale.TOTAL_NET
  //       };
  //     } else {
  //       return {
  //         ...presentSale,
  //         LAST_TOTAL_GROSS: pastSale.TOTAL_GROSS,
  //         LAST_TOTAL_NET: pastSale.TOTAL_NET,
  //         LTD_TOTAL_GROSS: pastSale.TOTAL_GROSS,
  //         LTD_TOTAL_NET: pastSale.TOTAL_NET
  //       };
  //     }
  //   } else {
  //     // If no matching past sale, just return the present sale data
  //     return presentSale;
  //   }
  // });

  // return mergedData;
  ////////////////////////////////





  //   let data = await getSalesByPartition(`{
  //     "PERIOD": "${period}",
  //     "ID_STORE": "${dashboardShopId}",
  //     "ID_SUPPLIER": "${dashboardSupplierId}",
  //     "ID_SALE_CHANNEL": "00000000-0000-0000-0000-000000000000",
  //     "DOCTYPE": "ALL",
  //     "TO_DATE": "${optionalDate}",
  //     "PARTITION_BY": "STORE"
  // }`, signal); 
  // console.log('fetch salesByStore');
  // setLoading(true);
  // console.log('started loading');




    // let localRunning;
    // let localLastToDate;
    // let localLast;

    // if (isGross) {
    //   localRunning = data[data.length - 1].RUNNING_GROSS;
    //   localLastToDate = data[data.length - 1].LTD_TOTAL_GROSS;
    //   localLast = data[data.length - 1].LAST_TOTAL_GROSS
    // } else {
    //   localRunning = data[data.length - 1].RUNNING_NET;
    //   localLastToDate = data[data.length - 1].LTD_TOTAL_NET;
    //   localLast = data[data.length - 1].LAST_TOTAL_NET;
    // }



{/* rimosso post riunione 2024/08/26*/}
  {/* <Column 
    dataField={isGross? 'LAST_TOTAL_GROSS' : 'LAST_TOTAL_NET'} 
    caption={translate('Last')} 
    alignment='right' 
    minWidth={100}
    format={{
      formatter: (value) => {
          return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);
          }
          }}
          ></Column>


          <Column 
          dataField="TARGET" 
          caption={translate('diff C/Ltd')} 
          alignment='right' 
          format='percent' 
          minWidth={100}></Column> */}

          {/* <Column 
        dataField={isGross? 'TOTAL_GROSS': 'TOTAL_NET'}
        caption={translate('Current')} alignment='right' minWidth={100}
        format={{
          formatter: (value) => {
            return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);
          }
          }}
      ></Column> */}



// SalesDashboardTable2.propTypes = {
  //   datasource: PropTypes.array.isRequired, // todo : set is required whne we have data from BE
  // }
  
  
  // <DataGrid dataSource={datasource} height={290}>
  //   <Column caption='State' dataField='stateName' />
  //   <Column alignment='left' caption='Sales' dataField='total' dataType='number' format='currency' sortOrder='desc' hidingPriority={2} />
  //   <Column alignment='left' caption='% Sold' dataField='percentage' name='percentN' format='percent' hidingPriority={1} />
  //   <Column
  //     alignment='left'
  //     caption='Percentage'
//     dataField='percentage'
//     cssClass='sales-bullet'
//     name='percentB'
//     cellRender={(cellInfo) => (
  //       <Bullet showTarget={false} showZeroLevel={false} value={cellInfo.data.percentage * 100} startScaleValue={0} endScaleValue={100}>
  //         <Tooltip enabled={false} />
//         <Size width={200} height={20} />
//       </Bullet>
//     )}
//     width={200}
//   />
// </DataGrid>

//todo: fetch data directly in the component since data don't ne to be shared
//problem ASK: in dash i have RUNNING or TOTAL that change based on isCumulative, here i only have the TOTAL

// const shops = [
//     {
//         "TOTAL_GROSS": 1031.01,
//         "TOTAL_NET": 845.09,
//         "LAST_TOTAL_GROSS": 451.68,
//         "LAST_TOTAL_NET": 370.22,
//         "LTD_TOTAL_GROSS": 451.68,
//         "LTD_TOTAL_NET": 370.22,
//         "PARTITION_NAME": "Napoli",
//         "ID_PARTITION": "d590f89b-e309-47ed-bf7d-f6dff9e20a03"
//     },
//     {
//         "TOTAL_GROSS": 355.00,
//         "TOTAL_NET": 290.98,
//         "LAST_TOTAL_GROSS": 582.00,
//         "LAST_TOTAL_NET": 477.04,
//         "LTD_TOTAL_GROSS": 582.00,
//         "LTD_TOTAL_NET": 477.04,
//         "PARTITION_NAME": "Padova",
//         "ID_PARTITION": "1a6b9f24-175b-4144-b23b-c55c8272c848"
//     }
//   ]
  
//todo se voglio dare stile particolare a header della tabella
  //   const renderTitleHeader = (data) => {
  //     return <p style={{ font-size: '16px' }}>{data.column.caption}</p>;
  // }
{/* </CardAnalytics> */}
//component to be inserted in card small, showing the partition of all into INSTORE and ONLINE
import Cookies from 'js-cookie';
import useTranslate from '../utils/useTranslate';

const CardSmallPartition = ({instore, instoreQty, online, onlineQty, addFormat, error500}) => {
    const translate = useTranslate()
    const currency = Cookies.get('currency');

    const formatValue = (value) => {
      // if (value) return null
      if (value == null) return null;

        const roundedValue = Math.round(value);

        switch (addFormat) {
            case 'percent':
              return `${value}%`;   
            case 'currency':
              return `${currency} ${Intl.NumberFormat("it-IT").format(value)}` //convert here (in child) bc CardSmall needs to receive a number to se the proper css
            case 'euFormat':
              return `${Intl.NumberFormat("it-IT").format(value)}` //convert here (in child) bc CardSmall needs to receive a number to se the proper css
            case 'euFormatToFixed0':
              return `${Intl.NumberFormat("it-IT").format(roundedValue)}` //convert here (in child) bc CardSmall needs to receive a number to se the proper css
            default:
              return value
          }

        // value = NaN ? '-' : value
        // value = Number(value) ////////DA RIMUOVERE, gestita in parent component placing value or 0


        // return `${Intl.NumberFormat("it-IT").format(value)}` //convert here (in child) bc CardSmall needs to receive a number to se the proper css // removed  ${currency} 
    };

  return (
   
        <div className='d-flex'>
            <div className="subtitle-partition instore">
                <small>instore</small>
                {instore == null || instoreQty == null || error500 ? (
                  // <div></div>
                  <p className='pt-2'>
                    <i className='dx-icon-pulldown big-number text-end'></i>
                  </p>
                ) : (
                  <>
                  <small className='fw-800'>{formatValue(instore)}</small>
                  {/* <br /> */}
                  <span>{instoreQty}</span>
                  </>
                )}
            </div>
            <div className="subtitle-partition online">
                <small>online</small>
                {online == null || onlineQty == null || error500 ? (
                  // <div></div>
                  <p className='pt-2'>
                    <i className='dx-icon-pulldown big-number text-end'></i>
                  </p>
                ) : (
                  <>
                <small className='fw-800'>{formatValue(online)}</small>
                {/* <br /> */}
                <span>{onlineQty}</span>
                </>
                )}
            </div>
            {/* <div className="subtitle-partition">
                <small>{'\u00A0'}</small>
                <small>{currency}</small>
                <br />
                <span>{translate('Qty')}</span>
            </div> */}
        </div>
        )
}

export default CardSmallPartition
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import MainProductLookup from '../components/MainProductLookup'
import SidebarHeader from '../components/SidebarHeader';
import FooterMenuProductLookup from '../components/FooterMenuProductLookup';
import Cookies from 'js-cookie';
import Item from '../api/ApiCallsProductLookup';
import Subject from '../api/ApiCallsSubject';
import Helper from '../api/Helper';

import notify from 'devextreme/ui/notify'; //https://js.devexpress.com/React/Demos/WidgetsGallery/Demo/Common/DialogsAndNotificationsOverview/MaterialBlueLight/
// import { LoadIndicator, LoadPanel } from 'devextreme-react';
import { ProductContext } from '../contexts/ProductProvider';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../utils/useTranslate';

//this component calls getGroup and subject.getSuppliers to populate filters, which are then used to populate view with items-articles
//also calls item.getData
const ProductLookup = () => {
  const translate = useTranslate();
  const { filter, setFilter, seasons, setSeasons, departments, setDepartments, suppliers, setSuppliers, stockSearch, setStockSearch, typeSearch, setTypeSearch, stockArticles, setStockArticles, stockPagination, setStockPagination, stockScrollPosition, setStockScrollPosition, stockSeason, setStockSeason, stockDepartment, setStockDepartment, stockSupplier, setStockSupplier, isLoading, setLoading, search } = useContext(ProductContext);

  const { getSuppliers } = Subject();
  const { getGroup } = Item();
  const { allFirst, addAllFirst } = Helper();

  const [errors, setErrors] = useState({
    seasons: null,
    departments: null,
    suppliers: null,
  })

  
  // const stockScrollRef = useRef(null);

  
  // const [filter, setFilter] = useState(
  //   {
  //     season: config.all,
  //     department: config.all,
  //     supplier: config.all,
  //   }
  // )
  // //used in children to populate
  // const [seasons, setSeasons] = useState([]);
  // const [departments, setDepartments] = useState([]);
  // const [suppliers, setSuppliers] = useState([]);
  
  // //to have updated values from the store
  // const [stockSearch, setStockSearch] = useState(''); //stockSearch is taken from store at component mount
  // const [typeSearch, setTypeSearch] = useState('Item');
  // const [stockArticles, setStockArticles] = useState([]);
  
  // const [stockPagination, setStockPagination] = useState(1);
  // const [stockScrollPosition, setStockScrollPosition] = useState()//question what is this for?
  
  
  // // const [season, setSeason] = useState([]); // this comes from cookes
  // const [stockSeason, setStockSeason] = useState('');
  // const [stockDepartment, setStockDepartment] = useState();
  // const [stockSupplier, setStockSupplier] = useState();
    
  // // const [stockScrollPosition, setStockScrollPosition] = useState(0)
  
  // const [isLoadPanelVisible, setLoadPanelVisible] = useState(false);

  const role = Cookies.get('role')

  const expireOptions = new Date();
  expireOptions.setDate(expireOptions.getDate() + 365 * 150);

  const navigate = useNavigate()
  

// 1) get filters arrays from backend,
// 2) get the already chosen filter value from the cookies or store? 
// 3) with no filters or already chosen filters, make api call to get articles/items 


//all'aggiornamento delle vaiabili nei cookies o nello store mi aggiorna lo useState, ma devo fare tante variabili locali quante sono le cose che devo pescare da store o cookies, cosi dovrebbe partire giusto il searc? no perche parte al caricamento, quindi nello useeffect iniziale ci devono esser ele variabili che s aggionrano quando si aggiornano i valori "remoti" 
 

  //ok
  useEffect(() => {
    console.log(Cookies.get('language'), 'lingua')
    if (role == 550) {
      navigate("/customer");  //todo: change path? navigate
    } else {

      //fetches only the first time the ocmponent is renderes in the provider, then you use the data saved in the provider
      if(seasons.length == 0) {
        fetchSeasons();
      }
      if(departments.length == 0) {
        fetchDepartments();
      }
      if(suppliers.length == 0) {
        fetchSuppliers();
      }
      if(!stockPagination) 
        setStockPagination(1);
      if(!typeSearch) {
        setTypeSearch('Item');
      }
      //if user doesn't search, fetches data with empty string 
      if(stockSearch == false) {
        setStockSearch('');
        // search();
      }
      //if user logs out, stockarticles is deleted and need to be fetched at component load
      if(stockArticles.length == 0) {
        search();
      }  

      // console.log('Cookies from stock', Cookies.get('stock-season'))
      // if (Cookies.get('stock-season') == undefined) {
      //   setSeasonCookies(seasons);
      //   // Cookies.set('stock-season', value);
      //   // setFilter((prevFilter) => ({ ...prevFilter, season: value })); 
      // }
        // search();

    }
  }, []); //tood: understan if i can put js Cookies in the params array of the use Effect, aka can it chek at every load if there are changed cookies

   
  //Get seasons in Eversell 81ECAAD3-FA0E-42CF-89A6-3B2802FA3C4F
  const fetchSeasons = async () => {
    setErrors(prevErr => ({...prevErr, seasons: null}))
    try {
      let { success: success, data: data, error: error } = await getGroup('{"$id":"1","FILTER":{"GetAll":false,"Sortings":[{"Field":"DATE_FROM","Ascending":true}, {"Field":"DESCRIPTION","Ascending":true}],"Id":"00000000-0000-0000-0000-000000000000","Id_2":"00000000-0000-0000-0000-000000000000","Id_3":"00000000-0000-0000-0000-000000000000","SimpleFilter":"","QueryFilter":"ID_ITEM_GROUP = \'81ECAAD3-FA0E-42CF-89A6-3B2802FA3C4F\'","Filters":[],"Top":-1,"StartIndex":-1,"PageLength":-1,"Parameter":"","TopParameter":0}}');

      if (success) {
      // console.log("Seasons data:", data); // per il debug
      //To move all in first
      data = allFirst(data);

      setSeasonCookies(data);
      //MOVED TO ABOVE SEPARATE FUNCTION
      // //if season is not set in cookies, sets the season currepondent to the current time of the year
      //   if (Cookies.get('stock-season') == undefined) {  
      //     console.log('entra nel cookies undef');
      //     const dateCurrent = new Date();
      //     let description = data[0].DESCRIPTION;
          
      //     //question: i dont get which kind of check is this forEach doing (r25 original code, stockController)
      //     //re-sets description every time it finds a season which is "current"
      //     data.forEach(item => {
      //       if (item.DATE_FROM != null && item.DATE_TO != null) {
      //         const dateFrom = new Date(item.DATE_FROM);
      //         const dateTo = new Date(item.DATE_TO);
      //         if (dateFrom <= dateCurrent && dateTo >= dateCurrent) {
      //           description = item.DESCRIPTION;
      //         }
      //         // console.log('descriotion', description) //here it is "all"
      //       }
      //     }); 
      //     setStockSeason(description); 
      //     // console.log('description', description);
      //     Cookies.set('stock-season', description, { expires: expireOptions });
      //     // console.log('Cookies post description', Cookies.get('stock-season'));
      //   }

        // setFilter((prevFilter) => ({ ...prevFilter, season: Cookies.get('stock-season') })); 
        //sets component state to data of season, to be passsed to and consumed by children
        setSeasons(data);
      } else {
        setErrors(prevErr => ({...prevErr, seasons: error.message}))
      }
    } catch (error) {
      notify(translate(error.message) || translate('Error'), 'error', 3000);
    }
  };

  const setSeasonCookies = (data) => {
  //if season is not set in cookies, sets the season currepondent to the current time of the year
  if (Cookies.get('stock-season') == undefined) {  
    const dateCurrent = new Date();
    let description = data[0].DESCRIPTION;
    
    //question: i dont get which kind of check is this forEach doing (r25 original code, stockController)
    //re-sets description every time it finds a season which is "current"
    data.forEach(item => {
      if (item.DATE_FROM != null && item.DATE_TO != null) {
        const dateFrom = new Date(item.DATE_FROM);
        const dateTo = new Date(item.DATE_TO);
        if (dateFrom <= dateCurrent && dateTo >= dateCurrent) {
          description = item.DESCRIPTION;
        } 
        // console.log('descriotion', description) //here it is "all"
      } 
      // else {
      //   console.log('bubsettete');
      // }
    }); 
    // console.log('descriotion fuori if', description) //here it is "all"

    setStockSeason(description); 
    Cookies.set('stock-season', description, { expires: expireOptions });
    //update filter on screen and in provider, hence new search is sent and the previous with emty season filter is aborted
    setFilter((prevFilter) => ({ ...prevFilter, season: Cookies.get('stock-season') })); 
  }
}

  //EX comment: Get departmens in Eversell 3443FDFC-6B0A-403C-87B9-7D166F149878
  const fetchDepartments = async () => {
    setErrors(prevErr => ({...prevErr, departments: null}))
    try {
      let { success: success, data: data, error: error } = await getGroup('{"$id":"1","FILTER":{"GetAll":false,"Sortings":[{"Field":"DESCRIPTION","Ascending":true}],"Id":"00000000-0000-0000-0000-000000000000","Id_2":"00000000-0000-0000-0000-000000000000","Id_3":"00000000-0000-0000-0000-000000000000","SimpleFilter":"","QueryFilter":"ID_ITEM_GROUP = \'3443FDFC-6B0A-403C-87B9-7D166F149878\'","Filters":[],"Top":-1,"StartIndex":-1,"PageLength":-1,"Parameter":"","TopParameter":0}}');
      //To move all in first
      if(success) {
      data = allFirst(data);
      if (!stockDepartment) {
        setStockDepartment(data[0]?.DESCRIPTION); //aka '<ALL>'
      }
      // setFilter((prevFilter) => ({ ...prevFilter, department: stockDepartment ? stockDepartment : data[0]?.DESCRIPTION})); 
      setDepartments(data);
    } else {
      setErrors(prevErr => ({...prevErr, departments: error?.message}));
    }
    } catch (error) {
      notify(translate(error.message) || translate('Error'), 'error', 3000);
    }
  };
  
  const fetchSuppliers = async () => {
    setErrors(prevErr => ({...prevErr, suppliers: null}))
    try {
      let { success: success, data: data, error: error } = await getSuppliers('{"$id":"1","FILTER":{"GetAll":false, "Sortings":[{"Field":"SEARCH_NAME","Ascending":true}], "Id":"00000000-0000-0000-0000-000000000000", "Id_2":"00000000-0000-0000-0000-000000000000", "Id_3":"00000000-0000-0000-0000-000000000000", "SimpleFilter":"", "QueryFilter":"ID_SUBJECT_STATUS = \'7bba3c89-dbf8-4d8c-a471-03e205062ba7\'", "Filters":[], "Top":-1, "StartIndex":-1, "PageLength":-1, "Parameter":"", "TopParameter":0}}');
      if (success){
      data = addAllFirst(data, 'SEARCH_NAME');
        if (!stockSupplier) {
          setStockSupplier(data[0]?.SEARCH_NAME);  
        }
      // setFilter((prevFilter) => ({ ...prevFilter, supplier: stockSupplier ? stockSupplier : data[0]?.SEARCH_NAME }));
      setSuppliers(data); 
      } else {
        setErrors(prevErr => ({...prevErr, suppliers: error?.message}));
      }
    } catch (error) {
      notify(translate(error.message) || translate('Error'), 'error', 3000);
    }
  };

  //EX comment: filter selected

//moved function to popup component, does save the filter in filters, todo understand if it si needed in state too --> no
  const selectFilter = ({ filterType, selectedValue, id }) => {
    setFilter((prevFilter) => ({ ...prevFilter, [filterType]: selectedValue}));
    setStockPagination(1); //necessary to add it here otherwise useEffect alone on filter, search etch does not work
    if(filterType == 'season') setStockSeason(selectedValue);
    if(filterType == 'department') setStockDepartment(selectedValue);
    if(filterType == 'supplier') setStockSupplier(selectedValue);

    if (filterType == 'season') {
      Cookies.set('stock-season', selectedValue, { expires: expireOptions });
    }
    // ///scroll management 
    //               stockScrollRef.current.scrollTop = 0; //brings user to beginning of scroll after setting a new filter
    // setStockPagination(1)
    // // search();
  };


  //MOVED TO PROVIDER TO AVOID CALLING FETCH AT EACH COMPONENT LOAD
  // useEffect(()=>{
  //   if(filter, stockPagination) {
  //     search();
  //   }
  // }, [filter, stockPagination, typeSearch, stockSearch])

 
  // //EX comment: //Search items based on selected filters, function is called after populating all the season, department, supplier lists
  // const search = async () => {
  //   //List articles
  //   setLoadPanelVisible(true);
  //   //In Eversell API if ALL must remove filter
  //   let season = filter.season == config.all ? '' : filter.season;
  //   let department = filter.department == config.all ? '' : filter.department;
  //   let supplier = filter.supplier == config.all ? '' : filter.supplier;

  //   season == undefined ? '' : season;
  //   department == undefined ? '' : department;
  //   supplier == undefined ? '' : supplier;

  //   try {
  //     let data = await getData(`{
  //       "$id": "1",
  //       "TOP": 0,
  //       "ID_STORE": "${Cookies.get('id_store')}",
  //       "ID_PRICING_ITEM": "efc0ce89-f8c4-4ae0-a71d-478854827877",
  //       "LAST_SELL": 0,
  //       "STOCK": 0,
  //       "ORDERED": 0,
  //       "SEASON": "${season}",
  //       "FREE_SEARCH": "${typeSearch === 'Item' ? stockSearch : ''}",
  //       "ID_ITEM": "00000000-0000-0000-0000-000000000000",
  //       "CODE": "",
  //       "SEASON_LIST": "",
  //       "REPARTO": "${department}",
  //       "BRAND": "",
  //       "SUPPLIER": "${supplier}",
  //       "BARCODE": "${typeSearch === 'Barcode' ? stockSearch : ''}",
  //       "P_LENGTH": "10",
  //       "P_NUMBER": "${stockPagination}"
  //     }`)
  //       if(stockPagination != 1){
  //         data = stockArticles.concat(data)
  //       }
  //     setStockArticles(data);    
  //   } catch (error) {
  //     notify(error, 'error', 3000);
  //   } finally {
  //     setLoadPanelVisible(false);
  //   }
  // }


  //MOVED TO LIST COMPONENT
  // //delays the search, params is the query typed by the user
  // const onSearchChanged = (params) => {
  //   let delayTimer;
  //   clearTimeout(delayTimer);
  //   delayTimer = setTimeout(() => {
  //   //To prevent change event when pass from false (initial default value) to content
  //   if(params.prevValue !== false){
  //     stockScrollRef.current.scrollTop = 0; 
  //     setStockPagination(1);
  //     // search()
  //   }
  //   }, 500);
  // };


  // useEffect(()=>{
  //   if(stockScrollRef?.current) stockScrollRef.current.scrollTop = stockScrollPosition
  //   // scroll function??
  // }, [stockScrollPosition])

  // useEffect(()=>{
  //   const handleScroll = () => {
  //     //updates the scroll position value in a var(stockScrollPosition) to fetch it back when needed
  //     if (stockScrollRef?.current) {
  //       // setStockScrollPosition(stockScrollRef?.current.scrollTop)
  //       const position = stockScrollRef.current.scrollTop;
  //       // Throttle the update to avoid too many renders
  //       window.requestAnimationFrame(() => {
  //         setStockScrollPosition(position);
  //       });
  //     }
  //     //To prevent load when change view and is already at bottom
  //     if(filter.season != undefined && filter.department != undefined && filter.supplier != undefined){
  //       if(stockScrollRef?.current.scrollHeight - stockScrollRef.current.scrollTop == stockScrollRef.current.offsetHeight){
  //         setStockPagination(prevPag => prevPag + 1); //when pagination changes, search is set off by useEffect
  //         // search()
  //       }
  //     }
  //     }
  //     const stockScroll = stockScrollRef?.current;
  //     if (stockScroll) {
  //       stockScroll.addEventListener('scroll', handleScroll);
  //       console.log('add scroll');
        
  //     }
  
  //     return () => {
  //       if (stockScroll) {
  //         stockScroll.removeEventListener('scroll', handleScroll);
  //         console.log('remove scroll');
          
  //       }
  //     };
  //   }, [stockScrollRef.current]);

//no more used 
    // const hideOnOutsideClick = () => {
    //   setLoading(false);
    // }
  
  return (
    // {localStockSearch != 'false' && (     question: is it needed? we define it at onLoad
    <>
      <SidebarHeader title={translate("Product Lookup")} backArrowVisible={false}/>
      <div className={`page-container d-flex flex-column`}>

{/* {stockArticles?.length > 0 && */}
        {stockArticles &&
          <MainProductLookup 
            // stockScrollRef={stockScrollRef} 
            // onSearchChanged={onSearchChanged}
            // setTypeSearch={setTypeSearch}
            // setStockSearch={setStockSearch}
            stockArticles={stockArticles}
            />
        }
          <FooterMenuProductLookup
            seasons={seasons}
            departments={departments}
            suppliers={suppliers}
            selectFilter={selectFilter}
            filter={filter}
          />
      </div>
      </>
    // )}
  )
}

export default ProductLookup

import { Accordion } from 'devextreme-react/accordion'
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { ArgumentAxis, Chart, CommonSeriesSettings, Legend, Series, Tooltip} from 'devextreme-react/chart';

import useTranslate from '../utils/useTranslate'
import { useCallback, useMemo, useState } from 'react'
import OrdersChart from './OrdersChart';
import CardBig from './CardBig';
import { AnimatePresence, motion } from 'framer-motion';


const OrdersAccordion = ({orders, isLoading, isAccordionExpanded, setIsAccordionExpanded}) => {
    const translate = useTranslate()
    // const [isLoading, setLoading] = useState(true)
    // const [errors, setErrors] = useState(false)
    const title = useMemo(() => translate('Overview orders'), [])
    // const chevron = useMemo(() => isAccordionExpanded ? 'fa-chevron-up' : 'fa-chevron-down',[isAccordionExpanded])
    const heightCard = useMemo(() => isAccordionExpanded ? 'auto' : 0, [isAccordionExpanded])
    // const accordionHeight = useMemo(() => isAccordionExpanded ? '300px' : '60px', [isAccordionExpanded]);

  const handleAccordionExpansion = useCallback(() => {
    setIsAccordionExpanded(!isAccordionExpanded)
    localStorage.setItem('isOrdersAccordionExpanded', !isAccordionExpanded)
  }, [isAccordionExpanded])
   

  return (
    <CardBig 
      title={title}
      isLoading={isLoading}
      width={'100%'}
      // style={{ height: heightCard }}
      heightCustom={heightCard}
      marginTopContent={0}
      noMarginAround={true}
      className='accordion-wrapper'
      isAnimated={true}
      isAccordionExpanded={isAccordionExpanded}
      onHeaderClick={handleAccordionExpansion}
      additionalHeaderContent={
        <i 
          className={`fa-solid fa-chevron-down accordion-chevron-icon ${isAccordionExpanded ? 'expanded' : ''}`}
          // onClick={handleAccordionExpansion}
          ></i>
        }
    >
        <AnimatePresence>
          {isAccordionExpanded && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <OrdersChart orders={orders} />
            </motion.div>
          )}
        </AnimatePresence>
    </CardBig>
    )
}

export default OrdersAccordion
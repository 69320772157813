//component to provide language across the whole application
import { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { expireOptions } from '../utils/constants/expireOptions';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
    //todo prendere lingua da locale? browser? o facciamo default ita e in caso cambiano al login/poi? intanto 2 
    //question:Cookies.set ma se usiamo provider, ci servono i cookies? per ricordare la preferenza per 150 anni si

    //avoid problmens of cookies when transitioning from old angular app to new react app
    const [language, setLanguage] = useState(() => {
        const cookieLanguage = Cookies.get('language');
        return !cookieLanguage || cookieLanguage.toLowerCase == 'italiano' ? 'it-IT' : cookieLanguage
    })
        // Cookies.get('language') || 
        // 'it-IT');

    //update language when it changes during navigation
    useEffect(() => {
        // console.log('language', language);
        Cookies.set('language', language, { expires: expireOptions }); 
    }, [language]);

    const changeLanguage = (lang) => {
        setLanguage(lang);
    };

    return (
        <LanguageContext.Provider value={{ language, changeLanguage }}>
            {children}
        </LanguageContext.Provider>
    );
};

export { LanguageContext, LanguageProvider };
import {DateRangeBox} from 'devextreme-react/date-range-box';
// import { Popup } from 'devextreme-react/popup';
// import useTranslate from '../utils/useTranslate';
import { useContext, useEffect, useState } from 'react';
import { ProductContext } from '../contexts/ProductProvider.jsx';
import itMessages from "devextreme/localization/messages/it.json";
import enMessages from "devextreme/localization/messages/en.json"; // Optional, as en is often default
import { locale, loadMessages } from "devextreme/localization";
// import { formatDate } from 'devextreme/localization';
import { LanguageContext } from '../contexts/LanguageProvider.jsx';
import { formatDateToYMD } from '../utils/functions.js';
import { LoadingIndicator } from 'devextreme-react/cjs/chart.js';


loadMessages(itMessages);
loadMessages(enMessages);

// const now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
// const now = new Date()
const today = formatDateToYMD(new Date())

const DateRangeBoxDx = ({togglePopup}
    // {isPopupVisible, setIsPopupVisible}
) => {
    const { datesRange, setDatesRange } = useContext(ProductContext)
  
    //handles calendar translation
    const {language} = useContext(LanguageContext)
    const [isLocaleSet, setIsLocaleSet] = useState(false);
    useEffect(() => {
        console.log('language', language);
        locale(language);
        setIsLocaleSet(true); //w/o this the locale won't be seen on first load  (prbly bc it's async)
    }, [language])


    const handleDateSelection = (e, value) => {
        e.stopPropagation();
    }

    const onValueChanged = (e) => {
        const [startDate, endDate] = e.value
        // console.log(e.value); // genera un array
        // if (startDate != null && endDate != null) {
        if (startDate && endDate) {
            // setDatesRange([startDate, endDate])
            // const formattedStart = formatDateToYMD(startDate)
            // const formattedEnd = formatDateToYMD(endDate)
            // setDatesRange({
            //     startDate: formattedStart, 
            //     endDate: formattedEnd,
            // })
            setDatesRange({
                startDate: startDate, 
                endDate: endDate,
            })
            togglePopup()
        }
        // [
        //     "2024-09-30T22:00:00.000Z",
        //     "2024-10-08T22:00:00.000Z"
        //   ]
    }
    if (!isLocaleSet) {
        return null; // or a loading indicator
    }

        return (
            <DateRangeBox 
                multiView={false} 
                max={today}
                onClick={handleDateSelection}
                onValueChanged={onValueChanged}
                // applyValueMode="useButtons"
                showClearButton={true}
                openOnFieldClick={true}
                // applyValueMode="useButtons"

            />
        )
}

export default DateRangeBoxDx
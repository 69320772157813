import config from '../utils/constants/config';
import useApi from './useApi';
import Helper from './Helper';

const Subject = () => {
    const { removeBadValue } = Helper()
    const { callApi } = useApi()

    async function getSuppliers (params) {   
      const { success, data, error } = await callApi('POST', `/SUBJECT/${config.language}/Get_All_qry_GET_SUPPLIERS`, params);   
      if(success) {     
        return { success: true, data: removeBadValue(data.ELENCO, null, 'empty')};
      } else {
        return { success: false, error: error }
      }
    }
    

    //todo understand how save customer is structured, update based on current API 
    async function saveCustomer (p) {
      //EX comment : Wait new API from Stefano
      const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      };
    
      // Info from Stefano - generate guid and put the SAME in all ID and also in ID_SUBJECT, if optional can be blank (e.g., phone, province, ...)
      const guid = uuidv4();
    
      const wantUppercase = true;
    
      if (wantUppercase) {
        for (const key in p) {
            if (Object.prototype.hasOwnProperty.call(p, key) && typeof p[key] === 'string') {            
                p[key] = p[key].toUpperCase();
                if (key === 'mail') {
                p[key] = p[key].toLowerCase();
                }
            }
            }
        }

      const params =  {
        "SUBJECT": {
            "Filter": null,
            "ROW": {
                "ID": guid,
                "SEARCH_NAME": p.firstname+' '+p.lastname,
                "IS_PHYSICAL": true,
                "IS_LEGAL": false,
                "ID_SUBJECT_SATUS": "00000000-0000-0000-0000-000000000000",
                "ANNOTATIONS": "",
                "INS_USR": "",
                "INS_DATA": new Date(),
                  "UPD_USR": "",
                  "UPD_DATA": "",
                  "DEL_USR": "",
                  "DEL_DATA": "",
                  "UPD": false,
                  "DEL": false,
                  "SUBJECT_STATUS_CODE": "N/A",
                  "SUBJECT_STATUS_DESCRIPTION": "N/A"
            },
            "state": 4,
            "AUX_DATA_01": null,
            "TK": "",
            "BU": "",
            "NOCHECK": false
          },
          "PHYSICAL_SUBJECT": {
            "Filter": null,
            "ROW": {
                  "ID": guid,
                  "NAME": p.firstname,
                  "SURNAME": p.lastname,
                  "ID_SEX": (p.gender == 'M' ? "266d6354-08e7-40c0-81c7-7abfc41cfc35" : "413F8344-FC8D-490C-91BA-7D7CD899277E"),
                  "BIRTH_DATE": (p.datebirth == null ? '1900-01-01 00:00:00.0000000' : p.datebirth),
                  "FISCAL_CODE": "",
                  "ID_DOCUMENT_TYPE": "00000000-0000-0000-0000-000000000000",
                  "DOCUMENT_NUMBER": "",
                 "DOCUMENT_EXPIRY": "",
                  "ANNOTATIONS": "",
                  "INS_USR": "",
                  "INS_DATA": new Date(),
                  "UPD_USR": "",
                  "UPD_DATA": "",
                 "DEL_USR": "",
                  "DEL_DATA": "",
                  "UPD": false,
                  "DEL": false,
                  "SEX_DESCRIPTION": (p.gender == 'M' ? "M" : "F"),
                  "DOCUMENT_TYPE_DESCRIPTION": "N/A",
                  "BIRTH_PLACE": ""
                },
           "state": 4,
        "AUX_DATA_01": null,
        "TK": "",
        "BU": "",
        "NOCHECK": false
      },
      "CUSTOMER": {
        "Filter": null,
            "ROW": {
              "ID": guid,
              "CODE": "",
              "BARCODE": "",
              "ID_PAYMENT_TERM": "00000000-0000-0000-0000-000000000000",
              "ID_PAYMENT_METHOD": "00000000-0000-0000-0000-000000000000",
              "ID_CURRENCY": "00000000-0000-0000-0000-000000000000",
              "ID_CUSTOMER_ZONE": "00000000-0000-0000-0000-000000000000",
              "ID_CUSTOMER_GROUP": "00000000-0000-0000-0000-000000000000",
              "ISO_CODE": "",
              "ERP_CODE": "",
              "ERP_EXPORT_DATE": "",
              "ANNOTATIONS": "",
              "INS_USR": "",
              "INS_DATA": new Date(),
              "UPD_USR": "",
              "UPD_DATA": "",
              "DEL_USR": "",
              "DEL_DATA": "",
              "UPD": false,
              "DEL": false,
              "PAYMENT_TERM_CODE": "N/A",
              "PAYMENT_TERM_DESCRIPTION": "Non Assegnato",
              "PAYMENT_METHOD_CODE": "N/A",
             "PAYMENT_METHOD_DESCRIPTION": "Non Assegnato",
              "CURRENCY_CODE": "",
              "CURRENCY_DESCRIPTION": "",
              "CUSTOMER_ZONE_CODE": "N/A",
              "CUSTOMER_ZONE_DESCRIPTION": "Non Assegnato",
              "CUSTOMER_GROUP_CODE": "N/A",
              "CUSTOMER_GROUP_DESCRIPTION": "Non Assegnato",
              "ID_VAT": "00000000-0000-0000-0000-000000000000",
              "VAT_CODE": "N/A",
              "VAT_DESCRIPTION": "N/A",
              "VAT_VALUE": 0.00,
              "ID_DOC_SHIPMENT_TYPE": "00000000-0000-0000-0000-000000000000",
              "SHIPMENT_TYPE_CODE": "N/A",
              "SHIPMENT_TYPE_DESCRIPTION": "N/A",
              "ID_CARRIER": "00000000-0000-0000-0000-000000000000",
              "CARRIER_DESCRIPTION": "",
              "BANK": "",
              "IBAN": "",
              "SWIFT": "",
              "DISCOUNT": 0.0
        },
        "state": 4,
        "AUX_DATA_01": null,
        "TK": "",
        "BU": "",
        "NOCHECK": false
      },
      "PHONES": [
           /*{
              "Filter": null,
              "ROW": {
                "ID": uuidv4(),
                "ID_SUBJECT": guid,
                "ID_PHONE_TYPE": "C4B437BD-3900-47FD-9F93-8AC010DC89A3", 
                    "IS_MAIN": true,
                "DESCRIPTION": "",
                "NUMBER": p.phone,
                "SHOW_INDEX": 1,
                "ANNOTATIONS": "",
                "INS_USR": "",
                "INS_DATA": new Date(),
                "UPD_USR": "",
                "UPD_DATA": "",
                "DEL_USR": "",
                "DEL_DATA": "",
                "UPD": false,
                "DEL": false,
                "PHONE_TYPE_DESCRIPTION": "N/A"
              },
              "state": 4,
             "AUX_DATA_01": null,
              "TK": "",
              "BU": "",
              "NOCHECK": false
           },*/
           {
              "Filter": null,
              "ROW": {
                "ID": uuidv4(),
                "ID_SUBJECT": guid,
                "ID_PHONE_TYPE": "9889E2A2-18A5-42C3-A9CC-01AC76A81F65",
                    "IS_MAIN": false,
                "DESCRIPTION": "",
                "NUMBER": p.mobile,
                "SHOW_INDEX": 1,
                "ANNOTATIONS": "",
                "INS_USR": "",
                "INS_DATA": new Date(),
                "UPD_USR": "",
                "UPD_DATA": "",
                "DEL_USR": "",
                "DEL_DATA": "",
                "UPD": false,
                "DEL": false,
                "PHONE_TYPE_DESCRIPTION": "N/A"
              },
              "state": 4,
             "AUX_DATA_01": null,
              "TK": "",
              "BU": "",
              "NOCHECK": false
           }
      ],
      "LINKS": [
        {
              "Filter": null,
              "ROW": {
                "ID": uuidv4(),
                "ID_SUBJECT": guid,
                "ID_LINK_TYPE": "dd158814-98f7-4904-af19-62766f8ef34f",
                "IS_MAIN": true,
                "DESCRIPTION": "",
                "LINK": p.mail,
                "SHOW_INDEX": 1,
                "ANNOTATIONS": "",
                "INS_USR": "",
                "INS_DATA": new Date(),
                "UPD_USR": "",
                   "UPD_DATA": "",
                "DEL_USR": "",
                "DEL_DATA": "",
                "UPD": false,
                "DEL": false,
                "LINK_TYPE_DESCRIPTION": "N/A"
              },
              "state": 4,
              "AUX_DATA_01": null,
             "TK": "",
              "BU": "",
              "NOCHECK": false
        }
      ],
      "ADDRESSS": [
        {
              "Filter": null,
              "ROW": {
                "ID": uuidv4(),
                "ID_SUBJECT": guid,
                "ID_ADDRESS_TYPE": "5E29C548-BA57-4DB3-8C36-39866C23A50A",
                "IS_MAIN": true,
                "LEGAL": true,
                "HOME": false,
                "SHIPMENT_TO": false,
                "SHIPMENT_FROM": false,
                "CONTACT": false,
                "DESCRIPTION": "",
                "DESTINATION": "",
                "ADDRESS": p.address,
                "CITY": p.city,
                "PROVINCE": p.province,
                "NATION": "",
                "ZIP_CODE": "",
                "VAT_NUMBER": "",
                "SHOW_INDEX": 1,
                "ANNOTATIONS": "",
                "INS_USR": "",
                "INS_DATA": new Date(),
                "UPD_USR": "",
                "UPD_DATA": "",
                "DEL_USR": "",
                "DEL_DATA": "",
                   "UPD": false,
                "DEL": false,
                "ADDRESS_TYPE_DESCRIPTION": "N/A"
              },
              "state": 4,
              "AUX_DATA_01": null,
              "TK": "",
              "BU": "",
              "NOCHECK": false
        }
      ]
    };

      const { success, data, error } = await callApi('POST', `/SUBJECT/${config.language}/Save_COMPLETE_CUSTOMER`, params);
      if ( success ){
        // console.log(data);  
        return {success: true, data: data}
      } else {
        return { success: false, error: error }
      }
    }

    return { getSuppliers, saveCustomer}
}

export default Subject



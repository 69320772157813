import { useCallback, useContext, useEffect, useRef, useState } from 'react';
// import MainSalesDashboard from '../components/MainSalesDashboard'
import PageHeader from '../components/PageHeader';
import Sidebar from '../components/Sidebar';
// import MainSalesDashboard from '../components/MainSalesDashboard';
import MainSalesDashboard from '../components/SalesDashboard/MainSalesDashboardDateHandling';
// import MainSalesDashboard from '../utils/trash';
// import MainSalesDashboard from '../components/SalesDashboard/OldMainSalesDashboard';
// import FooterMenu from '../components/FooterMenu';
// import filters from "../utils/constants/filters";
import FooterMenuDashboard from '../components/FooterMenuDashboard';
import config from '../utils/constants/config'
import Subject from '../api/ApiCallsSubject';
import Helper from '../api/Helper';
import notify from 'devextreme/ui/notify';
import Docs from '../api/ApiCallsDocs';
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import { ProductContext } from '../contexts/ProductProvider';
import useTranslate from '../utils/useTranslate';
import SidebarHeader from '../components/SidebarHeader';
import { expireOptions } from '../utils/constants/expireOptions';


const SalesDashboard = () => {
  const translate = useTranslate();
  const { filterDash: filter, setFilterDash: setFilter, suppliersDash, setSuppliersDash, stores, setStores, dashboardSupplier, setDashboardSupplier, dashboardSupplierId, setDashboardSupplierId, dashboardStore, setDashboardStore, dashboardStoreId, setDashboardStoreId, calendarDate, 
    // tab, setTab, 
    timeFrame, setTimeFrame, isGross, setIsGross, isCumulative, setIsCumulative } = useContext(ProductContext)
  const navigate = useNavigate()
  const {getSuppliers} = Subject()
  const {addAllFirst} = Helper()
  const { getStores } = Docs()
  const [errors, setErrors] = useState({
    suppliers: null,
    stores: null,
  })

  // const { getDashboard1, getDashboard2, getSalesByPartition } = Stock()
  // const [isLoadPanelVisible, setLoadPanelVisible] = useState(false);
  // const [target, setTarget] = useState('-'); 
  // const [target, setTarget] = useState(null); //MOVED TO CHILD COMPOENNT


  const role = Cookies.get('role');

 
  // const [dashboardSupplier, setDashboardSupplier] = useState();
  // const [dashboardSupplierId, setDashboardSupplierId] = useState();
  // const [dashboardShop, setDashboardShop] = useState();
  // const [dashboardShopId, setDashboardShopId] = useState();


//MOVED TO PRODUCT PROVIDER, kept the passage as prop, eventually can consider to change all the names and import directly where it is needed
  // const [filter, setFilter] = useState({
  //   supplier: config.all,
  //   store: config.all,
  // }) //todo check if empty sting is ok od config.all  


  // const filterRef = useRef(filter);
  // const dashboardSupplierIdRef = useRef(dashboardSupplierId);
  // const dashboardShopIdRef = useRef(dashboardShopId);

  // const [supplier, setSupplier] = useState(config.id_all);
  // const [shop, setShop] = useState(config.id_all);

  //moved to provider
  // const [suppliersDash, setSuppliersDash] = useState([]);
  // const [shops, setShops] = useState([]);

  //MOVED TO CHILD COMPONENT
  // const [chartData, setChartData] = useState()  //datasource for chart
  // const [chartCommonSeriesSettings, setChartCommonSeriesSettings] = useState();
  

  //MOVED TO PROVIDER
  // const [salesByStore, setSalesByStore] = useState();
  // const [salesByChannel, setSalesByChannel] = useState();
  // const [isGross, setIsGross] = useState();
  // const [isCumulative, setIsCumulative] = useState();
  

  //MOVED TO CHHILD COMPONENT
  // const [running, setRunning] = useState({});
  // const [lastToDate, setLastToDate] = useState({});
  // const [last, setLast] = useState({});


  // const [part1, setPart1] = useState();
  // const [part2, setPart2] = useState();
  // const [part3, setPart3] = useState();

 
  // const [tab, setTab] = useState('day');  //MOVED TO PROVIDER

  // const [docType, setDocType] = useState('ALL');

  //MOVED TO PROVIDER
  // const now = new Date();
  // const [calendarDate, setCalendarDate] = useState(now);  //todo: set by the click on calendar

  
  // const abortControllerRefDash = useRef(null); //MOVED TO CHILD COMPONENT
  // const abortControllerRefPartStore = useRef(null);  //MOVED TO CHILD COMPONENT
  // const abortControllerRefPartChannel = useRef(null); //MOVED TO CHILD COMPONENT




  
  // const expireOptions = new Date();
  // expireOptions.setDate(expireOptions.getDate() + 365 * 150);

	//Show specific tab aka period of time day/month/year
  // const handleTabChange = value => {
  //   setTab(value);
  //   // dashboard(value);
  // };

	//Set specific doctype aka instore or online
  // const handleDocTypeChange = value => {
  //   setDocType(value);
  //   // dashboard(tab);
  // };

  
 //MOVED TO FOOTER MENU 
  // //ex grossChange 
  // const handleGrossChange = (e) => {
  //   setIsGross(e.value);
  //   Cookies.set('dashboard-is_gross', e.value, { expires: expireOptions });
  //   // dashboard(tab);
  // };

//MOVED TO CHILD (CARDBIG)
  // //ex cumulativeChange
  // const handleCumulativeChange = (e) => {
  //   setIsCumulative(e.value);
  //   Cookies.set('dashboard-is_cumulative', e.value, { expires: expireOptions });
  //   // dashboard(tab);
  // };

  useEffect(() => {
    if(!(role == 0 || role == 1)){
        navigate("/");  //todo: change path? navigate?
    } else {
      // console.log('suppleDash' , suppliersDash);
      
      if(suppliersDash.length == 0) {
        fetchSuppliersDash();
      }
      if(stores.length == 0){
        fetchStores();
      }
      //EX comment //Options other Devexpress bug if you set model not change value, also if not remember or logout remember option
      if (Cookies.get('dashboard-is_gross') == undefined) {
          Cookies.set('dashboard-is_gross', true, { expires: expireOptions });
      }
      if (Cookies.get('dashboard-is_cumulative') === undefined) {
          Cookies.set('dashboard-is_cumulative', false, { expires: expireOptions });
      }
          //convert string value from cookies in boolean
      setIsGross(Cookies.get('dashboard-is_gross') == 'true' ? true : false);
      setIsCumulative(Cookies.get('dashboard-is_cumulative') == 'true' ? true : false);

      // dashboard(tab);
    }
  }, []);

  //on component load and on var in array modification, function is triggered
  //MOVED TO CHILD COMPONENT
  // useEffect(() => {
  //   // console.log('logs', dashboardSupplierId, dashboardShopId, docType);
    
  //   if (dashboardSupplierId && dashboardShopId 
  //     // && docType
  //   ) {
  //     // console.log('fetch')
  //     dashboard(tab); // executes funct with updated value
  //     // fetchSalesByStore(tab);
  //     // fetchSalesByChannel(tab);
  //   }
  // }, [dashboardSupplierId, dashboardShopId, tab, filter, calendarDate, isGross, isCumulative]); //removed docType
  //re-put isGross, isCumulative because dash must recalculate running ltd etc values (before:removed isGross, isCumulative, bc they are not params of the call, they are handled downstream data reception)

  // useEffect(()=>{
  //   fetchSalesByStore(tab)
  // },[tab])

  //EX comment: Filter selected (multiple so can pass id)
  const selectMultipleFilter = ({ filterType, selectedValue, id }) => {
    setFilter(prevFilter => ({ ...prevFilter, [filterType]: selectedValue}));    
    if(filterType == 'store'){
      setDashboardStore(selectedValue);
      setDashboardStoreId(id);
    } else if(filterType == 'supplier'){
      setDashboardSupplier(selectedValue);
      setDashboardSupplierId(id);
    }
    // dashboard(tab);
  };

  //Get Supplier
  const fetchSuppliersDash = async () => {
    setErrors(prevErr => ({...prevErr, suppliers: null}))
    try {
      // let data = await getSuppliers(`{
      //   "$id": "1",
      //   "FILTER": {
      //       "GetAll": false,
      //       "Sortings": [{"Field": "SEARCH_NAME", "Ascending": true}],
      //       "Id": "00000000-0000-0000-0000-000000000000",
      //       "Id_2": "00000000-0000-0000-0000-000000000000",
      //       "Id_3": "00000000-0000-0000-0000-000000000000",
      //       "SimpleFilter": "",
      //       "QueryFilter": "ID_SUBJECT_STATUS = '7bba3c89-dbf8-4d8c-a471-03e205062ba7'",
      //       "Filters": [],
      //       "Top": 1000,
      //       "StartIndex": -1,
      //       "PageLength": -1,
      //       "Parameter": "",
      //       "TopParameter": 0
      //     }
      //   }`);
        let { success: success, data: data, error: error} = await getSuppliers(`{"$id":"1","FILTER":{"GetAll":false,"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}],"Id":"00000000-0000-0000-0000-000000000000","Id_2":"00000000-0000-0000-0000-000000000000","Id_3":"00000000-0000-0000-0000-000000000000","SimpleFilter":"","QueryFilter":"ID_SUBJECT_STATUS='7bba3c89-dbf8-4d8c-a471-03e205062ba7'","Filters":[],"Top":1000,"StartIndex":-1,"PageLength":-1,"Parameter":"","TopParameter":0}}`);
          // console.log('fetchSupplDash', data);
        if(success) {  
        data = addAllFirst(data, 'SEARCH_NAME');
        data[0].ID = config.id_all;
        if(!dashboardSupplier){          
          setDashboardSupplier(data[0].SEARCH_NAME)
          setDashboardSupplierId(data[0].ID)
        }
        //problem:
        setFilter(prevFilter => ({ ...prevFilter, supplier: dashboardSupplier ? dashboardSupplier : data[0].SEARCH_NAME })); 
        setSuppliersDash(data);
      } else {
        setErrors(prevErr => ({...prevErr, suppliers: error?.message}))
      }
    } catch (error) {
      notify(translate(error.message) || translate('Error'), 'error', 3000);
    }
  }

  //Get Shops
  const fetchStores = async () => {
    setErrors(prevErr => ({...prevErr, stores: null}))

    try {
      // let data = await getShops(`{
      //     "$id": "1",
      //     "FILTER": {
      //         "Sortings": [{"Field": "SEARCH_NAME", "Ascending": true}]
      //     }
      // }`);
      // let data = await getShops(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
      let { success: success, data: data, error: error } = await getStores(`{"$id":"1","FILTER":{"Sortings":[{"Field":"SEARCH_NAME","Ascending":true}]}}`);
      if(success){        
        if(!dashboardStore) {
          setDashboardStore(data[0].SEARCH_NAME);
        }
        if(!dashboardStoreId) {
          setDashboardStoreId(data[0].ID);
        }

        //problem:
        setFilter(prevFilter => ({ ...prevFilter, store: dashboardStore ? dashboardStore : data[0].SEARCH_NAME})); 
        setStores(data);
      } else {
        setErrors(prevErr => ({...prevErr, stores: error?.message}))
      }
      } catch (error) {
        notify(translate(error.message) || translate('Error'), 'error', 3000);
      }
    }

 
  //Populates page with dash data
  // mi servono i dati divisi per instore e online e il totale. nel grafico mi serve ...

  //for graph: need data divided by instore and online, by intervals --> WEB02
  //for paragraph: need data divided by ALL, INSTORE, ONLINE, [total not intervals --> it actually takes the cumulative of the last interval)] to populate RUNNING LAST LTD and TARGET --> i need em separated --> WEB01 

  // NB WEB01 non ha possibilità di selezionare la data --> fare chiamata per totale che la abbia o sommare a manina cio che esce da instore e online, sommattoria poi di tutti i periodi dell'intervallo

  //nb parte una chiamata ogni bvolta che clicco all/instore/online

  //MOVED TO CHILD COMPONENT
  // const dashboard = async (period) => {
  //   const optionalDate = calendarDate ? calendarDate : ''
  //   try {
  //     if (abortControllerRefDash.current) {
  //       abortControllerRefDash.current.abort();
  //     }
  //     abortControllerRefDash.current = new AbortController();
  //     const signal = abortControllerRefDash.current.signal;

  //     // console.log('period:', period, 'doctype:', docType, dashboardShopId, dashboardSupplierId, optionalDate)
  //     console.log('period:', period, optionalDate)
  //     const docType = 'ALL'
  //   //   let data = await getDashboard2(`{
  //   //     "$id": "1",
  //   //     "PERIOD": "${period}",
  //   //     "DOCTYPE": "${docType}",
  //   //     "ID_STORE": "${dashboardShopId}",
  //   //     "ID_SUPPLIER": "${dashboardSupplierId}",
  //   //     "ID_SALE_CHANNEL": "00000000-0000-0000-0000-000000000000",
  //   //     "TO_DATE": "${optionalDate}"
  //   // }`, signal)
  //     let data = await getDashboard2(`{"$id":"1","PERIOD":"${period}","DOCTYPE":"${docType}","ID_STORE":"${dashboardShopId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","TO_DATE":"${optionalDate}"}`,signal)
  //     // LUCA: nuovo parametro opzionale TO_DATE che permette di personalizzare la data di riferimento.
  //     // Per visualizzare correttamente i dati di un mese/anno passato andrebbe settato sull'ultimo giorno di quel periodo (es. 31/12 o 31/07, 30/05, ...)

  // //PARAGHRAPS
  //       // let localRunning;
  //       // let localLastToDate;
  //       // let localLast;

  //       let localRunning = {};
  //       localRunning.ALL = isGross
  //         ? (data[data.length - 2].RUNNING_GROSS) + (data[data.length - 1].RUNNING_GROSS)
  //         : (data[data.length - 2].RUNNING_NET) + (data[data.length - 1].RUNNING_NET)
  //       localRunning.INSTORE = isGross
  //         ? data[data.length - 2].RUNNING_GROSS
  //         : data[data.length - 2].RUNNING_NET
  //       localRunning.ONLINE = isGross
  //         ? data[data.length - 1].RUNNING_GROSS
  //         : data[data.length - 1].RUNNING_NET

  //       let localLastToDate = {};
  //       localLastToDate.ALL = isGross 
  //         ? (data[data.length - 2].LTD_TOTAL_GROSS) + (data[data.length - 1].LTD_TOTAL_GROSS)
  //         : (data[data.length - 2].LTD_TOTAL_NET) + (data[data.length - 1].LTD_TOTAL_NET)
  //       localLastToDate.INSTORE = isGross 
  //         ? data[data.length - 2].LTD_TOTAL_GROSS
  //         : data[data.length - 2].LTD_TOTAL_NET
  //       localLastToDate.ONLINE = isGross 
  //         ? data[data.length - 1].LTD_TOTAL_GROSS
  //         : data[data.length - 1].LTD_TOTAL_NET
          
  //       let localLast = {};
  //       localLast.ALL = isGross 
  //         ? (data[data.length - 2].LAST_TOTAL_GROSS) + (data[data.length - 1].LAST_TOTAL_GROSS)
  //         : (data[data.length - 2].LAST_TOTAL_NET) + (data[data.length - 1].LAST_TOTAL_NET)
  //       localLast.INSTORE = isGross
  //         ? data[data.length - 2].LAST_TOTAL_GROSS
  //         : data[data.length - 2].LAST_TOTAL_NET
  //       localLast.ONLINE = isGross
  //         ? data[data.length - 1].LAST_TOTAL_GROSS
  //         : data[data.length - 1].LAST_TOTAL_NET


  //       //es year has now 24 obj in data instead of 12 --> take the last 2 (INSTORE, ONLINE)and sum them
  //       // if INSTORE or ONLINE, no need to sum, but need to select the right obj

  //       // let index; //to semplify the following if
  //       // if (docType == 'INSTORE') {
  //       //   index = data.length - 2
  //       // } else if (docType == 'ONLINE') {
  //       //   index = data.length - 1
  //       // }

  //       // if (docType == 'ALL') {
  //       //   localRunning = isGross
  //       //   ? (data[data.length - 2].RUNNING_GROSS) + (data[data.length - 1].RUNNING_GROSS)
  //       //   : (data[data.length - 2].RUNNING_NET) + (data[data.length - 1].RUNNING_NET)

  //       //   localLastToDate = isGross 
  //       //   ? (data[data.length - 2].LTD_TOTAL_GROSS) + (data[data.length - 1].LTD_TOTAL_GROSS)
  //       //   : (data[data.length - 2].LTD_TOTAL_NET) + (data[data.length - 1].LTD_TOTAL_NET)

  //       //   localLast = isGross 
  //       //   ? (data[data.length - 2].LAST_TOTAL_GROSS) + (data[data.length - 1].LAST_TOTAL_GROSS)
  //       //   : (data[data.length - 2].LAST_TOTAL_NET) + (data[data.length - 1].LAST_TOTAL_NET)

  //       // } else if (docType == 'INSTORE' || docType == 'ONLINE') {
  //       //   localRunning = isGross 
  //       //   ? data[index].RUNNING_GROSS
  //       //   : data[index].RUNNING_NET

  //       //   localLastToDate = isGross
  //       //   ? data[index].LTD_TOTAL_GROSS
  //       //   : data[index].LTD_TOTAL_NET

  //       //   localLast = isGross
  //       //   ? data[index].LAST_TOTAL_GROSS
  //       //   : data[index].LAST_TOTAL_NET
  //       // }

  //       setRunning(localRunning);
  //       setLastToDate(localLastToDate);
  //       setLast(localLast);

  //       setTarget(localLastToDate.ALL == 0 ? 0 : (localRunning.ALL * 100 / localLastToDate.ALL) - 100);  


  // //CHART
  //     let part1 = isCumulative ? 'RUNNING' : 'TOTAL';
  //     let part2 = isGross ? 'GROSS' : 'NET';
  //     // let part3 = isCumulative ? 'area' : 'stackedbar' //moved to the child component, not needed here
  //     const valueField = `${part1}_${part2}`

  //     //process data so that each obj has the fields interval, instore, online and is correctly populated
  //     const transformed = [];
  //     data.forEach(item => {
  //       const existing = transformed.find(d => d.INTERVAL === item.INTERVAL);
        
  //       if (existing) {
  //         // existing[item.DOCTYPE] = item.RUNNING_NET; //
  //         existing[item.DOCTYPE] = item[valueField];
  //       } else {
  //         transformed.push({
  //           INTERVAL: item.INTERVAL,
  //           INSTORE: item.DOCTYPE === 'INSTORE' ? item[valueField] : 0,
  //           ONLINE: item.DOCTYPE === 'ONLINE' ? item[valueField] : 0
  //         });
  //       }
  //     });
  //     setChartData(transformed);
  //     // setChartCommonSeriesSettings(part3)
  //   } catch (error) {
  //     console.log('Request canceled dash1 PROVIDER ERR', error.name);
  //     if (!error.name === 'CanceledError') {  //if api call not aborted 
  //       notify(error, 'error', 3000);
  //     }
  //   } finally {
  //     // setLoadPanelVisible(false);
  //   }
  // }

  // //SHOPS TABLE
  //  //Gets sales divided by store to populate the bottom table
  //  const fetchSalesByStore = async(period) => {
  //   const optionalDate = calendarDate ? calendarDate : ''
  //   try {
  //     if (abortControllerRefPartStore.current) {
  //       abortControllerRefPartStore.current.abort();
  //     }
  //     abortControllerRefPartStore.current = new AbortController();
  //     const signal = abortControllerRefPartStore.current.signal;

  //   //   let data = await getSalesByPartition(`{
  //   //     "PERIOD": "${period}",
  //   //     "ID_STORE": "${dashboardShopId}",
  //   //     "ID_SUPPLIER": "${dashboardSupplierId}",
  //   //     "ID_SALE_CHANNEL": "00000000-0000-0000-0000-000000000000",
  //   //     "DOCTYPE": "ALL",
  //   //     "TO_DATE": "${optionalDate}",
  //   //     "PARTITION_BY": "STORE"
  //   // }`, signal); 

  //     let data = await getSalesByPartition(`{"PERIOD":"${period}","ID_STORE":"${dashboardShopId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"ALL","TO_DATE":"${optionalDate}","PARTITION_BY":"STORE"}`,signal);    

  //     // let localRunning;
  //     // let localLastToDate;
  //     // let localLast;

  //     // if (isGross) {
  //     //   localRunning = data[data.length - 1].RUNNING_GROSS;
  //     //   localLastToDate = data[data.length - 1].LTD_TOTAL_GROSS;
  //     //   localLast = data[data.length - 1].LAST_TOTAL_GROSS
  //     // } else {
  //     //   localRunning = data[data.length - 1].RUNNING_NET;
  //     //   localLastToDate = data[data.length - 1].LTD_TOTAL_NET;
  //     //   localLast = data[data.length - 1].LAST_TOTAL_NET;
  //     // }
  //     data.map(store => {
  //       //problem ASK: RUNNING non c'è nella risposta di questa call
  //       const running = isGross ? store.TOTAL_GROSS : store.TOTAL_NET
  //       const lastToDate = isGross? store.LTD_TOTAL_GROSS : store.LTD_TOTAL_NET;
  //       const target = lastToDate == 0 ? 0 : (running * 100 / lastToDate) - 100
  //       return {
  //         ...store,
  //         TARGET: target
  //       }
  //     })
  //     setSalesByStore(data)
      
  //   } catch (error) {
  //     console.log('Request canceled dash2 PROVIDER ERR', error.name);
  //     if (!error.name === 'CanceledError') {  //if api call not aborted 
  //       notify(error, 'error', 3000);
  //     }
  //   }
  // }

  // //PIE CHART
  // //Gets sales divided by sale channel to populate the bottom pie chart
  // const fetchSalesByChannel = async(period) => {
  //   const optionalDate = calendarDate ? calendarDate : ''
  //   try {
  //     if (abortControllerRefPartChannel.current) {
  //       abortControllerRefPartChannel.current.abort();
  //     }
  //     abortControllerRefPartChannel.current = new AbortController();
  //     const signal = abortControllerRefPartChannel.current.signal;
  //     //id store actually not necessary because qhen user selects a shop the table w partition by shop disappears, yet put for constance with other api calls
  //     //"ID_STORE": "00000000-0000-0000-0000-000000000000",
  //   //   let data = await getSalesByPartition(`{
  //   //     "PERIOD": "${period}",
  //   //     "ID_STORE": "${dashboardShopId}",
  //   //     "ID_SUPPLIER": "${dashboardSupplierId}",
  //   //     "ID_SALE_CHANNEL": "00000000-0000-0000-0000-000000000000",
  //   //     "DOCTYPE": "ALL",
  //   //     "TO_DATE": "${optionalDate}",
  //   //     "PARTITION_BY": "SALE_CHANNEL"
  //   // }`, signal);    

  //     let data = await getSalesByPartition(`{"PERIOD":"${period}","ID_STORE":"${dashboardShopId}","ID_SUPPLIER":"${dashboardSupplierId}","ID_SALE_CHANNEL":"00000000-0000-0000-0000-000000000000","DOCTYPE":"ALL","TO_DATE":"${optionalDate}","PARTITION_BY":"SALE_CHANNEL"}`,signal);    

  //     // data.map(store => {
  //     //   //problem ASK: RUNNING non c'è nella risposta di questa call
  //     //   const running = isGross ? store.TOTAL_GROSS : store.TOTAL_NET
  //     //   // const lastToDate = isGross? store.LTD_TOTAL_GROSS : store.LTD_TOTAL_NET;
  //     //   // const target = lastToDate == 0 ? 0 : (running * 100 / lastToDate) - 100
  //     //   return {
  //     //     ...store,
  //     //     TARGET: target
  //     //   }
  //     // })
  //     setSalesByChannel(data)
      
  //   } catch (error) {
  //     console.log('Request canceled dash3 PROVIDER ERR', error.name);
  //     if (!error.name === 'CanceledError') {  //if api call not aborted 
  //       notify(error, 'error', 3000);
  //     }
  //   }
  // }


  return (
    <>
      <SidebarHeader title={translate("Sales Dashboard")} backArrowVisible={false}/>
      <div className={`page-container d-flex flex-column `}>
        {/* <div className='pt-5'>
            <LoadIndicator
            visible={isLoadPanelVisible}
            width={100}
            height={100}
            />
        </div> */}
        {/* {(running !== undefined && last !== undefined && lastToDate !== undefined && target !== null) && ( */}
        <MainSalesDashboard 
          // chartData={chartData} 
          // chartCommonSeriesSettings={chartCommonSeriesSettings}

          // handleTabChange={handleTabChange}
          // running={running}
          // lastToDate={lastToDate}
          // last={last}
          // target={target}

          // handleDocTypeChange={handleDocTypeChange}
          // docType={docType}
          // salesByStore={salesByStore}
          // salesByChannel={salesByChannel}
          // isGross={isGross}  
          // isCumulative={isCumulative}
          // handleCumulativeChange={handleCumulativeChange}

          // calendarDate={calendarDate}
          // setCalendarDate={setCalendarDate}

          filter={filter}

          />
        {/* )} */}
        {(stores && suppliersDash) && (
          <FooterMenuDashboard 
            filter={filter}
            stores={stores}
            suppliers={suppliersDash}
            selectMultipleFilter={selectMultipleFilter}
            // handleGrossChange={handleGrossChange}
            // isGross={isGross}  
            // handleCumulativeChange={handleCumulativeChange}
            // isCumulative={isCumulative}
            />       
        )
        }
      </div>
    </>
  )};


export default SalesDashboard;



//backup dashboard call at 2024/08/20, before transforming it in 2 separate calls, one for the graph and one for the paragraphs

// const dashboard = async(period) => {
//   // setLoadPanelVisible(true);
//   const optionalDate = calendarDate ? calendarDate : ''
//   try {
//     console.log('period:', period, 'doctype:', docType, dashboardShopId, dashboardSupplierId, optionalDate)

//     // let data = await getDashboard1(`{
//     //   "$id":"1", 
//     //   "PERIOD": "${period}", 
//     //   "DOCTYPE": "${docType}", 
//     //   "ID_STORE": "${dashboardShopId}", 
//     //   "ID_SUPPLIER": "${dashboardSupplierId}"}`
//     // )
    
//     // to populate the graph 
//     let data = await getDashboard2(`{
//         "$id": "1",
//         "PERIOD": "${period}",
//         "DOCTYPE": "${docType}",
//         "ID_STORE": "${dashboardShopId}",
//         "ID_SUPPLIER": "${dashboardSupplierId}",
//         "ID_SALE_CHANNEL": "00000000-0000-0000-0000-000000000000",
//         "TO_DATE": "${optionalDate}"
//     }`)
// // LUCA: nuovo parametro opzionale TO_DATE che permette di personalizzare la data di riferimento.
// // Per visualizzare correttamente i dati di un mese/anno passato andrebbe settato sull'ultimo giorno di quel periodo (es. 31/12 o 31/07, 30/05, ...)

//     // setChartData(data);      

//     //const chartInstance = document.getElementById('dashboardChart').dxChart('instance')
//     // chartInstance.option('dataSource', data);  //modify the way it i passed down to the chart
//     let localRunning;
//     let localLastToDate;
//     let localLast;

//     let part1;
//     let part2;
//     let part3;

//     console.log('getdashboard2:', data)
//     if (isGross) {
//       part2 = 'GROSS';

//       localRunning = data[data.length - 1].RUNNING_GROSS;
//       setRunning(localRunning);
//       localLastToDate = data[data.length - 1].LTD_TOTAL_GROSS;
//       setLastToDate(localLastToDate);
//       localLast = data[data.length - 1].LAST_TOTAL_GROSS
//       setLast(localLast);

//     } else {
//       part2 = 'NET';

//       localRunning = data[data.length - 1].RUNNING_NET;
//       setRunning(localRunning);
//       localLastToDate = data[data.length - 1].LTD_TOTAL_NET;
//       setLastToDate(localLastToDate);
//       localLast = data[data.length - 1].LAST_TOTAL_NET;
//       setLast(localLast);
//     }
//     if(isCumulative) {
//       part1 = 'RUNNING';
//       part3 = 'area';
//     } else {
//       part1 = 'TOTAL';
//       part3 = 'stackedbar';
//     }
    
//     //process data so that each obj has the fields interval, instore, online and is correctly populated
//     const valueField = `${part1}_${part2}`
//     const transformed = [];
//     data.forEach(item => {
//       const existing = transformed.find(d => d.INTERVAL === item.INTERVAL);
      
//       if (existing) {
//         existing[item.DOCTYPE] = item.RUNNING_NET;
//       } else {
//         transformed.push({
//           INTERVAL: item.INTERVAL,
//           INSTORE: item.DOCTYPE === 'INSTORE' ? item[valueField] : 0,
//           ONLINE: item.DOCTYPE === 'ONLINE' ? item[valueField] : 0
//         });
//       }
//     });
//     setChartData(transformed);

//     // //old code: setChartSeries({argumentField: 'INTERVAL', valueField: `${part1}_${part2}`, type: part3, color: '#5793bb'})
//     // setChartCommonSeriesSettings(part3)
//     // // chartInstance.option('series', {argumentField: 'INTERVAL', valueField: part1+"_"+part2, type: part3, color: '#5793bb'}); //modify the way it i passed down to the chart



//     //define target
//     setTarget(localLastToDate == 0 ? 0 : (localRunning * 100 / localLastToDate) - 100);  
//     console.log('remo' , running, last, lastToDate);
      
//   } catch (error) {
//     notify(error, 'error', 3000);
//   } finally {
//     // setLoadPanelVisible(false);
//   }
// }








/////////////////backup dashbordGraph e dashbord prima di ri unirle
// const dashboardGraph = async (period) => {
//   const optionalDate = calendarDate ? calendarDate : ''
//   try {
//     console.log('period:', period, 'doctype:', docType, dashboardShopId, dashboardSupplierId, optionalDate)
    
//     //to populate the graph 
//     let data = await getDashboard2(`{
//         "$id": "1",
//         "PERIOD": "${period}",
//         "DOCTYPE": "${docType}",
//         "ID_STORE": "${dashboardShopId}",
//         "ID_SUPPLIER": "${dashboardSupplierId}",
//         "ID_SALE_CHANNEL": "00000000-0000-0000-0000-000000000000",
//         "TO_DATE": "${optionalDate}"
//     }`)
// // LUCA: nuovo parametro opzionale TO_DATE che permette di personalizzare la data di riferimento.
// // Per visualizzare correttamente i dati di un mese/anno passato andrebbe settato sull'ultimo giorno di quel periodo (es. 31/12 o 31/07, 30/05, ...)


// //todo NO: volnendo si puo spostare direttamente nel componente figlio passandogli isGross e isCumulative  --> no perche serve anche nella trasformazione dei dati
//     let part1 = isCumulative ? 'RUNNING' : 'TOTAL';
//     let part2 = isGross ? 'GROSS' : 'NET';
//     // let part3 = isCumulative ? 'area' : 'stackedbar' //moved to the child component, not needed here

//     const valueField = `${part1}_${part2}`

//     //process data so that each obj has the fields interval, instore, online and is correctly populated
//     const transformed = [];
//     data.forEach(item => {
//       const existing = transformed.find(d => d.INTERVAL === item.INTERVAL);
      
//       if (existing) {
//         existing[item.DOCTYPE] = item.RUNNING_NET;
//       } else {
//         transformed.push({
//           INTERVAL: item.INTERVAL,
//           INSTORE: item.DOCTYPE === 'INSTORE' ? item[valueField] : 0,
//           ONLINE: item.DOCTYPE === 'ONLINE' ? item[valueField] : 0
//         });
//       }
//     });
//     setChartData(transformed);
//     // setChartCommonSeriesSettings(part3)
//   } catch (error) {
//     notify(error, 'error', 3000);
//   } finally {
//     // setLoadPanelVisible(false);
//   }
// }

// const dashboard = async(period) => {
//   // setLoadPanelVisible(true);
//   const optionalDate = calendarDate ? calendarDate : ''
//   try {
//     // console.log('period:', period, 'doctype:', docType, dashboardShopId, dashboardSupplierId, optionalDate)

//     // let data = await getDashboard1(`{
//     //   "$id":"1", 
//     //   "PERIOD": "${period}", 
//     //   "DOCTYPE": "${docType}", 
//     //   "ID_STORE": "${dashboardShopId}", 
//     //   "ID_SUPPLIER": "${dashboardSupplierId}"}`
//     // )

//     let data = await getDashboard2(`{
//       "$id": "1",
//       "PERIOD": "${period}",
//       "DOCTYPE": "${docType}",
//       "ID_STORE": "${dashboardShopId}",
//       "ID_SUPPLIER": "${dashboardSupplierId}",
//       "ID_SALE_CHANNEL": "00000000-0000-0000-0000-000000000000",
//       "TO_DATE": "${optionalDate}"
//   }`)

    
//     let localRunning;
//     let localLastToDate;
//     let localLast;

//     //es year has now 24 obj in data instead of 12 --> take the last 2 (INSTORE, ONLINE )and sum them
//     // if INSTORE or ONLINE, no need to sum, but need to select the right obj

//     //to semplify the followinf if
//     let index;
//     if (docType == 'INSTORE') {
//       index = data.length - 2
//     } else if (docType == 'ONLINE') {
//       index = data.length - 1
//     }
    
//     localLastToDate = data[data.length - 1].LTD_TOTAL_GROSS;

//     if (docType == 'ALL') {
//       localRunning = isGross
//       ? (data[data.length - 2].RUNNING_GROSS) + (data[data.length - 1].RUNNING_GROSS)
//       : (data[data.length - 2].RUNNING_NET) + (data[data.length - 1].RUNNING_NET)

//       localLastToDate = isGross 
//       ? (data[data.length - 2].LTD_TOTAL_GROSS) + (data[data.length - 1].LTD_TOTAL_GROSS)
//       : (data[data.length - 2].LTD_TOTAL_NET) + (data[data.length - 1].LTD_TOTAL_NET)

//       localLast = isGross 
//       ? (data[data.length - 2].LAST_TOTAL_GROSS) + (data[data.length - 1].LAST_TOTAL_GROSS)
//       : (data[data.length - 2].LAST_TOTAL_NET) + (data[data.length - 1].LAST_TOTAL_NET)

//     } else if (docType == 'INSTORE' || docType == 'ONLINE') {
//       localRunning = isGross 
//       ? data[index].RUNNING_GROSS //aggiustare numero del obj
//       : data[index].RUNNING_NET

//       localLastToDate = isGross
//       ? data[index].LTD_TOTAL_GROSS
//       : data[index].LTD_TOTAL_NET

//       localLast = isGross
//       ? data[index].LAST_TOTAL_GROSS
//       : data[index].LAST_TOTAL_NET
//     }

//     // if (docType == 'ALL') {
//     //   localRunning = isGross
//     //   ? (data[data.length - 2].RUNNING_GROSS) + (data[data.length - 1].RUNNING_GROSS)
//     //   : (data[data.length - 2].RUNNING_NET) + (data[data.length - 1].RUNNING_NET)
//     // } else if (docType == 'INSTORE') {
//     //   localRunning = isGross 
//     //   ? (data[data.length - 2].RUNNING_GROSS) //aggiustare numero del obj
//     //   : data[data.length - 2].RUNNING_NET
//     // } else if (docType == 'ONLINE') {
//     //   localRunning = isGross 
//     //   ? (data[data.length - 1].RUNNING_GROSS) //aggiustare numero del obj
//     //   : data[data.length - 1].RUNNING_NET
//     // }

//     setRunning(localRunning);
//     setLastToDate(localLastToDate);
//     setLast(localLast);

//     setTarget(localLastToDate == 0 ? 0 : (localRunning * 100 / localLastToDate) - 100);  

//     // if (isGross) {
//     //   localRunning = data[data.length - 1].RUNNING_GROSS;
//     //   setRunning(localRunning);
//     //   localLastToDate = data[data.length - 1].LTD_TOTAL_GROSS;
//     //   setLastToDate(localLastToDate);
//     //   localLast = data[data.length - 1].LAST_TOTAL_GROSS
//     //   setLast(localLast);
//     // } else {
//     //   localRunning = data[data.length - 1].RUNNING_NET;
//     //   setRunning(localRunning);
//     //   localLastToDate = data[data.length - 1].LTD_TOTAL_NET;
//     //   setLastToDate(localLastToDate);
//     //   localLast = data[data.length - 1].LAST_TOTAL_NET;
//     //   setLast(localLast);
//     // }
    
//     console.log('running, last, lastToDate' , running, last, lastToDate);        
//   } catch (error) {
//     notify(error, 'error', 3000);
//   } finally {
//     // setLoadPanelVisible(false);
//   }
// }
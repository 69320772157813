import { SelectBox } from 'devextreme-react/select-box'

const LanguageDropdown = ({language, languages, onValueChanged}) => {
  return (
        <SelectBox
          className='language-dropdown'
          dataSource={languages}
          displayExpr= 'text'
          valueExpr='value'   
          // searchEnabled={true}
          onValueChanged={onValueChanged}
          width={100}
          height={30}
          // defaultValue={languages[0].value} //todo: change, if user changes in settings, this does not upd
          defaultValue={language} //todo: change, if user changes in settings, this does not upd
          placeholder={false}
          acceptCustomValue={false}
          aria-label="language"
          // fieldRender={renderField}
        />
  )
}

export default LanguageDropdown
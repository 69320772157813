import { motion } from 'framer-motion';
import useTranslate from '../utils/useTranslate.jsx';
import { useNavigate } from 'react-router-dom'
import { LoadPanel } from 'devextreme-react/load-panel';
// import OrderList from './OrderListCustomer.jsx';
import { useCallback, useContext, useMemo, useState } from 'react';
// import { ProductContext } from '../contexts/ProductProvider.jsx';
import OrdersTextbox from './OrdersTextbox.jsx';
import OrdersDropdown from './OrdersDropdown.jsx';
// import OrdersDropdown from './OrdersDropdown copy.jsx';
// import OrdersDropdown from './OrdersDropdown copy 2.jsx';
// import DxButtonGroup from './DxButtonGroup.jsx';
import OrderListCustomer from './OrderListCustomer.jsx';
import OrderListItem from './OrderListItem.jsx';
import TabNavigation from './TabNavigation.jsx';
import TabSwitch from './TabSwitch.jsx';
import { ProductContext } from '../contexts/ProductProvider.jsx';
import OrdersAccordion from './OrdersAccordion.jsx';
import Cookies from 'js-cookie';

const animationProps = {
    initial: { x: '-100%', opacity: 0 },
    animate: { x: 0, opacity: 1 },
    exit: { x: '100%', opacity: 0 },
    transition: { duration: 0.3 }
  }

  const openClosedOptions = [
    { 
      // id: 0, 
      // text: translate('open'),
      icon: 'fa-solid fa-box-open', value: 'open' },
    { 
      // id: 1, 
      // text: translate('closed'), 
      icon: 'fa-solid fa-box', value: 'closed'},
    { 
      // id: 2, 
      // text: translate('all'), 
      icon: 'fa-solid fa-cubes-stacked', value: 'all'}        
  ];
  
//   num ordine
//   data
//   stato
//   nome cliente
//   (negozio) si
// (canale vendita) si
//   (quanitta) si
//   (totale) si
//   articoli codice/nome


const MainOrders = ({orders, filter, isLoading}) => {

  const translate = useTranslate();
  const { noResultsMessageOrders, isOrdersLoading, visualization, setVisualization, openClosedOrders, setOpenClosedOrders } = useContext(ProductContext);
  const navigate = useNavigate();
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(localStorage.getItem('isOrdersAccordionExpanded') === 'true' || false)
  const role = Cookies.get('role');


  const customerItemOptions = useMemo(()=> [{
      id: 0,
      text: translate('customer'),
      hint: "customer",
      // icon: 'user',
      icon: 'fa-solid fa-user',
    }, {
      id: 1,
      text: translate('item'),
      hint: 'item',
      icon: 'fa-solid fa-tag',
    }], [translate])

  // const [selectedVisItem, setSelectedVisItem ] = useState(customerItemOptions[0])
  // const [visualization, setVisualization] = useState(customerItemOptions[0].hint)

  // const [visualization, setVisualization] = useState('customer')

  // const changeVisualization = useCallback((item) => {
  //   console.log('tab', item.hint)
  //   setSelectedVisItem(item)
  //   setVisualization(item.hint)
  // }, [])



  // const [selectedOpenClosed, setSelectedOpenClosed ] = useState(openClosedOptions[0])
  // const [openClosedOrders, setOpenClosedOrders] = useState(openClosedOptions[0].value)

  //customer or item visulization
  const changeOpenClosedOrders = useCallback((item) => {
    console.log('tab', item.value)
    // setSelectedOpenClosed(item)
    setOpenClosedOrders(item.value)
  }, [])

  const extraMarginTop = useMemo(() => {
    if (role == 0 || role == 1) {
      return isAccordionExpanded 
        ? 'extra-margin-top-accordion-open'
        : 'extra-margin-top-accordion-closed';
    } else {
      return 'extra-margin-top';
    }
  }, [isAccordionExpanded, role]);



  return (
    <>
      <div id='lookup-form' className='d-flex align-items-center flex-wrap extra-height-lookup'>
        <OrdersTextbox/>
        {/* <DxButtonGroup
          options={customerItemOptions} 
          setValue={setVisualization} 
          defaultValue={'customer'}
        /> */}
        {/* <OrdersDropdown/> */}
        <TabSwitch
          options={customerItemOptions}
          setValue={setVisualization}
          selectedItem={visualization}
          width='40px'
          display='icon'
        />
        {/* <br /> */}
        <div className='tab-nav-container  px-2'>
        <TabNavigation dataSource={openClosedOptions} onItemClick={changeOpenClosedOrders} selectedValue={openClosedOrders}
        // selectedItem={selectedOpenClosed} onSelectionChanged={changeVisualization} 
        />
        </div>
        {(role == 0 || role == 1) &&
          <OrdersAccordion 
            orders={orders} 
            isLoading={isLoading} 
            isAccordionExpanded={isAccordionExpanded}
            setIsAccordionExpanded={setIsAccordionExpanded}
          /> 
        }
      </div>

        {/* <div id='lookup-form' className='d-flex align-items-center'>
          <ProductLookupTextbox 
            // onSearchChanged={onSearchChanged} 
            // setStockSearch={setStockSearch}
            />
          <Button 
            icon='photo'
            onClick={()=> {navigate('/product-lookup/qr-scanner')}}
            className='ms-2 me-1 qr-camera-btn'
            />
        </div> */}
        <motion.div 
          className={`scroll-view ${extraMarginTop}`}
          id='order-loadpanel-container'
          {...animationProps}
          // initial={{ x: '-100%', opacity: 0 }}   
          // animate={{ x: 0, opacity: 1 }}      
          // exit={{ x: '100%', opacity: 0 }}   
          // transition={{ duration: 0.3 }}     
        >
        {/* <div id='stock-loadpanel-container'> */}
        <LoadPanel 
          visible={isOrdersLoading}
          hideOnOutsideClick={false}
          container='#order-loadpanel-container'
          shading={true}
          shadingColor="rgba(0, 0, 0, 0.2)"
          />
          {/* </div> */}
          {(noResultsMessageOrders || orders.length == 0) ? (
          <p className='mt-4 fs-5 grey'>{translate('Orders not found.')}</p>
          ) : (
            visualization == 'customer' ? (
            <OrderListCustomer orders={orders} filter={filter} />
            ) : (
              <OrderListItem orders={orders} filter={filter}/>
            )
          )}
      </motion.div>
    </>
  )
}

export default MainOrders






  // const changeVisualization = useCallback((args) => {
  //   console.log('visItem', args.addedItems[0]);

  //   setSelectedVisItem(args?.addedItems[0] ? args.addedItems[0] : customerItemOptions[0])
  //   setVisualization(args?.addedItems[0]?.hint)
  // }, [])

  // useEffect(() => {
  //   console.log('visualization', visualization);
  // },[visualization])


//correct
  // const changeVisualization = useCallback((e) => {
  //   console.log('tab', e.itemData.hint)
  //   setSelectedVisItem(e.itemData)
  //   setVisualization(e.itemData.hint)
  // }, [])


  


  // const tabOptions = [
  //   {
  //     id: 0,
  //     text: translate('Customer'),
  //     icon: 'user',
  //   },
  //   {
  //     id: 1,
  //     text: translate('Items'),
  //     icon: 'fa-solid fa-tag',
  //   },
  //   // {
  //   //   id: 2,
  //   //   text: 'Clients',
  //   //   icon: 'accountbox',
  //   // },
  //   // {
  //   //   id: 3,
  //   //   text: 'Orders',
  //   //   icon: 'ordersbox',
  //   // },
  //   // {
  //   //   id: 4,
  //   //   text: 'Favorites',
  //   //   icon: 'bookmark',
  //   // },
  //   // {
  //   //   id: 5,
  //   //   text: 'Search',
  //   //   icon: 'search',
  //   // },
  // ];
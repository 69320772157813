
import config from '../utils/constants/config';
import useApi from './useApi';

const Stock = () => {
  const { callApi } = useApi()
  
//EX comment: Stock to get data to dashboard

//dismissed, substituted with getDashboard2
// async function getDashboard1 (params) {  
//   try {
//     const response = await callApi('POST', `/STOCK/${config.language}/GET_DASHBOARD_WEB_01`, params);
//     return response.ELENCO;  
//   } catch (error) {
//     throw new Error(error);
//   }
// }

//dismissed
// async function getDashboard2 (params, signal) { 
//     try {
//       const response = await callApi('POST', `/STOCK/${config.language}/GET_DASHBOARD_WEB_02`, params, signal);
//       return response.ELENCO;  
//     } catch (error) {
//       throw new Error(error);
//     }
//   }

//not handling errors
// async function getDashboard4 (params, signal) { 
//   try {
//     const response = await callApi('POST', `/STOCK/${config.language}/GET_DASHBOARD_WEB_04`, params, signal);
//     return response.ELENCO;  
//   } catch (error) {
//     throw new Error(error);
//   }
// }

async function getDashboard4 (params, signal) { 
    const { success, data, error } = await callApi('POST', `/STOCK/${config.language}/GET_DASHBOARD_WEB_04`, params, signal);
    if(success) {
      // if (data.Message) {       
      // return { success: true, data: data }
      // } else {
        return { success: true, data: data.ELENCO };
      // }
    } else {
      console.log('getDash4 error');
      return { success: false, error: error };
    }
}

//dismissed
// async function getSalesByPartition (params, signal) {
//   try {
//     const response = await callApi('POST', `/STOCK/${config.language}/GET_DASHBOARD_WEB_02_PART`, params, signal);
//     return response.ELENCO;  
//   } catch (error) {
//     throw new Error(error);
//   }
// }

  return {
    // getDashboard1, getDashboard2, 
    getDashboard4 
    // getSalesByPartition
    }
}

export default Stock
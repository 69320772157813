//this component renders a poup, possible actions:
//- search of a term in the List (while user types, the search gets updated)
// - selection of a term in the List --> saved in variable, variable filters products(to be seen api)
//


import { TextBox } from 'devextreme-react/text-box'
// import { ScrollView } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { setFilter } from '../store/filterSlice';
import Cookies from 'js-cookie'
import useTranslate from '../utils/useTranslate';
import { expireOptions } from '../utils/constants/expireOptions';
import { capitalizeFirstLetter } from '../utils/functions';
import DateRangeBoxDx from './DateRangeBoxDx';
import { ProductContext } from '../contexts/ProductProvider';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';


//todo: prendere il dato dalle props e a seconda della flag(aka ref) fare il render di un array diverso
const now = new Date()

function FilterPopup({isPopupVisible, togglePopup, 
    content, filterType, selectFilter}) {
    
    const translate = useTranslate();
    const filterTitle = useMemo(() => capitalizeFirstLetter(translate(filterType)), [filterType, translate])
    const {datesRange, setDatesRange } = useContext(ProductContext)
    const [filteredPopupOptions, setFilteredPopupOptions] = useState(content)
    const optionRefs = useRef([]); // array di ref

    useEffect(()=>{
        // console.log(content , 'CONTENT')
        setFilteredPopupOptions(content) //reset the filtered options on component mount or content update
    }, [content])
    
    //filters option that contain the typed characters, even if they are not subsequent in the "option"
    // const search = (value) => {
    //     const newOptions = content.filter(option => value.toLowerCase().split('').every(char => (filterType == 'supplier' || filterType === 'shop' ? option.SEARCH_NAME : option.DESCRIPTION).toLowerCase().includes(char))); 
    //     setFilteredPopupOptions(newOptions)
    // }


    //todo ORDERS upd with channel and status from ORDERS d amettere co con  SEARCH_NAME o con DESCRIPTION
    const search = (value) => {
        const searchTerm = value.toLowerCase();
        const newOptions = content.filter(option => {
            const fieldToSearch = filterType === 'supplier' || filterType === 'store' ? option.SEARCH_NAME : option.DESCRIPTION; 
            return fieldToSearch.toLowerCase().includes(searchTerm);
        });
        setFilteredPopupOptions(newOptions);
    }
    
    //todo ORDERS upd with channel and status from ORDERS
    const handleOptionClick = (e, option) => {
        if(option.DESCRIPTION == 'select dates'){
            selectFilter(
                {
                    filterType: filterType,
                    selectedValue: option.DESCRIPTION,
                    id: option.ID // Passa ID solo se disponibile
                }
            ); 
        } else {
        togglePopup();        
        if (filterType == 'season') {
            Cookies.set('stock-season', option.DESCRIPTION, { expires: expireOptions });
        }
        if (filterType == 'archive') { //3 defaults options, the custom dates are handled in the child component 
            let startDate;
            let endDate;
            switch (option.DESCRIPTION) {
                case 'this month':
                    startDate = startOfMonth(now)
                    endDate = endOfMonth(now)
                    break;
                case 'last month':
                    startDate = startOfMonth(subMonths(now, 1))
                    endDate = endOfMonth(subMonths(now, 1))
                    break;
                case 'last 3 months':
                    startDate = startOfMonth(subMonths(now, 2))
                    endDate = endOfMonth(now)
                    break;
                default:
            }
            setDatesRange({startDate: startDate, endDate: endDate})
        }
        const selectedValue = (filterType == 'supplier' || filterType === 'store') ? option.SEARCH_NAME : option.DESCRIPTION;
        selectFilter(
            {
                filterType: filterType,
                selectedValue: selectedValue,
                id: option.ID // Passa ID solo se disponibile
            }
        ); //in productLookup
        // console.log('e.target', e.value);

    }        
    };

    const handleInsideClick = (e) => {
        e.stopPropagation();
    };

    
    const renderContent = () => {
        return (
            // <ScrollView height="100%" width="100%">
                <div className='popup-container' onClick={handleInsideClick}>
                    <div className='popup-search-box'>
                        <TextBox 
                            mode= "search"
                            placeholder={translate('Search')}
                            showClearButton={true}
                            valueChangeEvent="input"
                            onValueChange={search}
                        />
                    </div>
                    {filteredPopupOptions.map((option, index) =>{
                        const displayText = (filterType === 'supplier' || filterType === 'store')
                        ? (option.SEARCH_NAME === '<ALL>' ? translate('<ALL>') : option.SEARCH_NAME)
                        : (option.DESCRIPTION === '<ALL>' ? translate('<ALL>') : 
                            (filterType == 'status' || filterType == 'archive' ? translate(option.DESCRIPTION) : option.DESCRIPTION)
                            );

                        

                    return (
                        <div 
                            key={index} 
                            onClick={(e)=>handleOptionClick(e, option)} 
                            className='popup-option'
                            value={{filterType, option}} 
                            ref={el => optionRefs.current[index] = {div: el, option: option}}
                        >
                            {/* {(filterType == 'supplier' || filterType === 'shop') ? option.SEARCH_NAME : option.DESCRIPTION} */}
                            {displayText}
                            {option.DESCRIPTION == 'select dates' && <DateRangeBoxDx togglePopup={togglePopup}/>}
                            {/* {option.DESCRIPTION == 'select dates'  
                                ? <DateRangePopup isPopupVisible={isPopupDatesVisible} setIsPopupVisible={setIsPopupDatesVisible}/> 
                                : displayText} */}
                        </div>  // todo: onClick should be replaced with an action that sends the filter option to the backend.
                    )})}            
                </div>
            // </ScrollView>
        )
    }
    


  return (
    <Popup 
        id="popup"
        contentRender={renderContent} 
        visible={isPopupVisible}
        hideOnOutsideClick={true}
        onHiding={togglePopup}
        // showTitle={false}
        showCloseButton={true}
        // style={{height: '250px', minHeight: 'auto', width: '300px'}}
        width={300}
        height={'auto'}
        // minHeight={'auto'}
        maxHeight={350}
        title={filterTitle}
        >
    </Popup>
  )
}

export default FilterPopup

FilterPopup.propTypes = {
    isPopupVisible: PropTypes.bool.isRequired,
    togglePopup: PropTypes.func.isRequired,
    // handlePopupClick: PropTypes.func.isRequired,
    content: PropTypes.array.isRequired,
    filterType: PropTypes.string.isRequired,
    selectFilter: PropTypes.func.isRequired
}
import { Popup } from 'devextreme-react/popup';
import PropTypes from 'prop-types';
// import { Switch } from "devextreme-react/switch";
// import { useCallback, useState } from 'react';
import DxSwitch from './DxSwitch';
import useTranslate from '../utils/useTranslate';


const SalesDashboardOptionsPopup = ({
    isPopupVisible, togglePopup,
    handleGrossChange, isGross, 
    handleCumulativeChange, 
    // isCumulative
}) => {
    
    const translate = useTranslate();
   

    // const [switchValue, setSwitchValue] = useState(true)
    // const [hintMessage, setHintMessage] = useState("Click to switch on");
 
    // const onValueChanged = useCallback((e) => {
    //     const messageText = e.value ? "Click to switch off" : "Click to switch on";
    //     const stateLabel = e.value ? "ON" : "OFF";
    //     setHintMessage(messageText);
    //     setSwitchValue(e.value);
        
    //     }, []);

    const handleInsideClick = (e) => {
        e.stopPropagation();
    };

const renderContent = () => {
    return (
            // <div className='compact text-center fs-6 lh-lg overflow-hidden my-2 container justify-content-around align-items-center' onClick={handleInsideClick}>
            <div className='text-center fs-6 lh-lg mt-3 container' onClick={handleInsideClick}>
                <div className="row pb-3">
                    <div className="col"><p className='m-0'>{translate('Gross')}</p></div>
                    <div className="col d-flex justify-content-center align-items-center">
                    <DxSwitch 
                        value={isGross}
                        // onValueChanged={(e) => handleGrossChange(e.value)}
                        onValueChanged={handleGrossChange}
                    />
                        {/* <Switch 
                            value={switchValue}
                            width={40}     
                            // rtlEnabled={true}
                            hint={hintMessage}
                            onValueChanged={onValueChanged}
                        >
                        </Switch> */}
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col"><p className='m-0'>{translate('Cumulative')}</p></div>
                    <div className="col d-flex justify-content-center align-items-center">
                    <DxSwitch 
                        value={isCumulative}
                        onValueChanged={handleCumulativeChange}
                    />
                    </div>
                </div> */}
            </div>
    )
}

  return (
      <Popup
        contentRender={renderContent} 
        visible={isPopupVisible}
        hideOnOutsideClick={true}
        onHiding={togglePopup}
        showTitle={true}
        title={translate('Options')} 
        showCloseButton={true}
        // style={{height: '250px', minHeight: 'auto', width: '300px'}}
        width={300}
        height={'auto'}
        minHeight={'auto'}
      >
      </Popup>

  );
}


export default SalesDashboardOptionsPopup

SalesDashboardOptionsPopup.propTypes = {
    isPopupVisible: PropTypes.bool.isRequired,
    togglePopup: PropTypes.func.isRequired,
    handleGrossChange: PropTypes.func.isRequired,
}
//converted from tsx to jsx

//todo: evitare che form si azzeri quando faxcio submit
import { useState, useRef, useCallback, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Form, { Item, Label, ButtonItem, ButtonOptions, RequiredRule } from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

// import LoginOauth from './LoginOauth'; todo check if needed/wanted, i don't think so
// import { useAuth } from '../../contexts/LoginAuthProvider';
import Cookies from 'js-cookie'
import { AuthContext } from '../contexts/LoginAuthProvider.jsx';
import useTranslate from '../utils/useTranslate.jsx';
import { expireOptions } from '../utils/constants/expireOptions';



// import { ThemeContext } from '../../../theme/theme';
// import Button from 'devextreme-react/button';


// function getButtonStylingMode(theme) {
//   return theme === 'dark' ? 'outlined' : 'contained';
// }

// todo:delete this mock reset link
const resetLink = '/reset-password';




const LoginForm = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const {login } = useContext(AuthContext)
  const [isLoading, setLoading] = useState(false);
  const formData = useRef({ Customer: '', UserName:'', Password: '', isPersistent: false });
  const formRef = useRef(null);
  const role = Cookies.get('role');

  // let isEyeOpen = true;

  // const [passwordMode, setPasswordMode] = useState('password');
  // const [isEyeOpen, setPasswordIcon] = useState(true);

  // useEffect(()=>{
  //   console.log(
  //     'cookies from login',
  //       Cookies.get('token'),
  //       Cookies.get('url'),
  //       Cookies.get('currency'),
  //       Cookies.get('language'),
  //       Cookies.get('role'),
  //       Cookies.get('id_store'),
  //       Cookies.get('company_name'),
  Cookies.get('customer')
  //   );
  // },[])

  

  // const companyEditorOptions = { 
  //   labelMode: 'floating', 
  //   stylingMode: 'filled',   
  //   mode: 'text', 
  //   value: '',
  //   // value: Cookies.get('customer')? Cookies.get('customer') : '',
  //   showClearButton: true
  // };
  // const usernameEditorOptions = { 
  //   labelMode: 'floating', 
  //   stylingMode: 'filled', 
  //   mode: 'text', 
  //   value: '',
  //   // value: Cookies.get('username') ? Cookies.get('username') : '',
  //   showClearButton: true
  // };
  // const passwordEditorOptions = { 
  //   labelMode: 'floating', 
  //   stylingMode: 'filled', 
  //   // mode: passwordMode, 
  //   mode: 'password',    
  //   value: '',
  //   // value: Cookies.get('password') ? Cookies.get('password') : '',
  //   // showClearButton: true
  //   buttons: [
  //     {
  //       name: 'password',
  //       location: 'after',
  //       options: {
  //         stylingMode: 'text',
  //         icon: 'eyeopen',
  //         // icon: isEyeOpen ? 'eyeopen' : 'eyeclose',
  //         // icon: passwordIcon, 
  //         onClick: (e) => changePasswordMode('Password', e),
  //       },
  //     },
  //   ],
  // };
  // const rememberMeEditorOptions = { 
  //   text: translate('Remember me'), 
  //   elementAttr: { className: 'form-text' } 
  // };

  const companyEditorOptions = useMemo(() =>({ 
    labelMode: 'floating', 
    // stylingMode: 'filled',   
    // stylingMode: 'outlined',    
    mode: 'text', 
    value: '',
    // value: Cookies.get('customer')? Cookies.get('customer') : '',
    showClearButton: true
  }), []);
  const usernameEditorOptions = useMemo(()=>({ 
    labelMode: 'floating', 
    // stylingMode: 'filled', 
    // stylingMode: 'outlined', 
    mode: 'text', 
    value: '',
    // value: Cookies.get('username') ? Cookies.get('username') : '',
    showClearButton: true
  }),[]);
  const passwordEditorOptions = useMemo(()=>({ 
    labelMode: 'floating', 
    // stylingMode: 'filled', 
    // stylingMode: 'outlined', 
    // mode: passwordMode, 
    mode: 'password',    
    value: '',
    // value: Cookies.get('password') ? Cookies.get('password') : '',
    // showClearButton: true
    buttons: [
      {
        name: 'password',
        location: 'after',
        options: {
          stylingMode: 'text',
          icon: 'eyeopen',
          // icon: isEyeOpen ? 'eyeopen' : 'eyeclose',
          // icon: passwordIcon, 
          onClick: (e) => changePasswordMode('Password', e),
        },
      },
    ],
  }), []);
  const rememberMeEditorOptions = useMemo(()=>({ 
    text: translate('Remember me'), 
    elementAttr: { className: 'form-text' } 
  }), []);

  // const changePasswordMode = useCallback(() => {
  //   setPasswordMode(prevMode => (prevMode === 'text' ? 'password' : 'text'));
  //   setPasswordIcon(prevIcon => (prevIcon === 'eyeopen' ? 'eyeclose' : 'eyeopen'));
  // }, []);

  
  const changePasswordMode = useCallback((fieldName, e) => {
    const editor = formRef.current.instance().getEditor(fieldName);
    editor.option('mode', editor.option('mode') === 'text' ? 'password' : 'text');
    const buttonInstance = e.component;
    buttonInstance.option('icon', buttonInstance.option('icon') === 'eyeclose' ? 'eyeopen' : 'eyeclose')
    // editor.option('mode', editor.option('mode') === 'text' 
    // ? editor.option('buttons[0].options.icon', 'eyeopen')
    // : editor.option('buttons[0].options.icon', 'eyeclose'));
  
  }, []);

  //on component load, change fields value if Cookies were previously set with rememberMe check
  useEffect(() => {
    onLoadRememberMe()
  },[])

  const onLoadRememberMe = () => {
    const dataField = ['Customer', 'UserName', 'Password', 'isPersistent' ]
    dataField.forEach((fieldName) => {
      const editor = formRef.current.instance().getEditor(fieldName);
      editor.option('value', Cookies.get(fieldName) ? Cookies.get(fieldName) : '');
    })

  };

  const onSubmit = useCallback(
    async(e) => {
      e.preventDefault();
      const { Customer, UserName, Password, isPersistent } = formData.current;
      // console.log('onSubmit', formData.current);
      setLoading(true);

      try {
        const { success: success, data: result, error: error} = await login ({
          Customer: Customer, 
          UserName: UserName, 
          Password: Password, 
          isPersistent: isPersistent});
        if (success) {
          //navigate is differential based on the role
          if (role == 550){
            navigate('/customer')
          } else { 
            navigate('/product-lookup');
          }
          setLoading(false);
          if (isPersistent){
            Cookies.set('Customer', Customer, { expires: expireOptions });
            Cookies.set('UserName', UserName, { expires: expireOptions });
            Cookies.set('Password', Password, { expires: expireOptions });
            Cookies.set('isPersistent', isPersistent, { expires: expireOptions });
          }
        } else {
          setLoading(false); 
          notify(translate(error), 'error', 2000);
          // console.error('Login failed:', result.message);
        }
        
      } catch (error) {
        setLoading(false); 
        throw new Error(error);
      }
    },
    [login, 
      navigate //maybe remove
    ] 
  );


  return (
    <form className='login-form' onSubmit={onSubmit}>
      <Form
        ref={formRef}
        formData={formData.current}
        disabled={isLoading}
        showRequiredMark={false}
        // labelMode='floating'
      >
        <Item dataField='Customer' editorType='dxTextBox' editorOptions={companyEditorOptions} >
          {/* <RequiredRule message={translate('Company is required')} /> */}
          <RequiredRule message={translate('Required field')} />
          {/* <EmailRule message='Email is invalid' /> */}
          <Label visible={false} text={translate('Company')} />
        </Item>

        <Item dataField='UserName' editorType='dxTextBox' editorOptions={usernameEditorOptions}>
          {/* <RequiredRule message='Username is required' /> */}
          <RequiredRule message={translate('Required field')} />
          {/* <EmailRule message='Email is invalid' /> */}
          <Label visible={false} text={translate('Username')} />
        </Item>

        <Item dataField='Password' editorType='dxTextBox' 
        editorOptions={passwordEditorOptions}
        >
          {/* <RequiredRule message='Password is required' /> */}
          <RequiredRule message={translate('Required field')} />
          <Label visible={false} text={translate('Password')}/>
        </Item>

        <Item dataField='isPersistent' editorType='dxCheckBox' editorOptions={rememberMeEditorOptions}>
          <Label visible={false} />
        </Item>

        <ButtonItem>
          <ButtonOptions width='100%' type='default' useSubmitBehavior>
            <span className='dx-button-text'>{isLoading ? <LoadIndicator width='24px' height='24px' visible /> : translate('Sign In')}</span>
          </ButtonOptions>
        </ButtonItem>

      </Form> 
      {/* future developments
      <div className='reset-link'>
        <Link to={resetLink}>{translate('Forgot password?')}</Link>
      </div> */}
    </form>
  );
}

export default LoginForm;

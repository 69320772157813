 
// import { TextBox } from 'devextreme-react/text-box';
// import { useCallback, useEffect, useState } from 'react';

import { useCallback, useContext, useEffect, useState } from 'react';
import FilterPopup from './FilterPopup';
// import config from '../utils/constants/config'
// import { useDispatch, useSelector } from 'react-redux';
// import { setFilter } from '../store/filterSlice';
import useTranslate from '../utils/useTranslate';
import Cookies from 'js-cookie';
import { ProductContext } from '../contexts/ProductProvider';
import { formatDate } from 'devextreme/localization';
import { status } from '../utils/constants/filters';
import { archive } from '../utils/constants/filters';



// const filters = ["Filter 1", "Filter 2", "Filter 3"]; //TODO variates based on the page, passed as prop, then import filters from the righ constant in constant file, then delete this demo 
const filtersOrders = [
    {
        label: "store",
        image: 'fa-solid fa-store',
        visibleFor: ['admin'] 
    },
    {
        label: "channel",
        // image: "fa-solid fa-tag",
        image: "fa-solid fa-globe",
        visibleFor: ['admin', 'store'] 
    },
    {
        label: "status",
        // image: 'fa-regular fa-calendar-check',
        image: 'fa-solid fa-tasks',
        // image: 'fa-solid fa-bars-progress',
        visibleFor: ['admin', 'store'] 
    },
    {
        label: "archive",
        image: 'fa-solid fa-folder-open',
        visibleFor: ['admin', 'store'] 
    },
];



// 'inserted' //inserito
// 'waybill' //lettera vettura
// 'processing' //lavorazione
// 'dispatched' //or processed  //evaso
// 'returned' //or processed  //evaso
// 'partially returned' //or processed  //evaso


//todo sostituire con funzione vera che viene passsata da padre? arriva da provider? vedi footer dash e footer lookup


function FooterMenuOrders({stores, channels, selectFilter, filter
    // setOrdersFilter, filter
}) {
    const translate = useTranslate();
    const { openClosedOrders, setOpenClosedOrders, datesRange} = useContext(ProductContext);
    const [filteredStatus, setFilteredStatus] = useState(status)
    const [currentFilterIndex, setCurrentFilterIndex] = useState(null);
    const togglePopup = (index) =>{
        setCurrentFilterIndex(index === currentFilterIndex ? null : index);
    }

    const role = parseInt(Cookies.get('role'), 10);
    const roleMap = {
        0: 'admin',
        1: 'admin',
        300: 'store',
        550: 'customer'
    };

    const roleString = roleMap[role] || '';


    //if role = store --> does not show the filter shop, and sets the value of that filter to the id of the store
    //set filter of shop to cookies Cookies.get('id_store');
    useEffect(() => {
        if (roleString != 'admin'){
            selectFilter(
                {
                    filterType: 'store',
                    selectedValue: '',  //tood: eventually ask to give the store name in the Get_BASE_VALUES call, now the STORE_NAME is given but populated with "SHOP" instead of the store name
                    id: Cookies.get('id_store') // Passa ID solo se disponibile
                }
            )
        }
      
    }, [roleString])
    
//description will be translated in the map in filterPopup
    



    const selectFilterSetOpenClosed = useCallback(({ filterType, selectedValue, id })=>{
        // console.log('set openclosed', openClosedOrders);
        // setOpenClosedOrders('all')
        selectFilter({ filterType, selectedValue, id });
    },[selectFilter, setOpenClosedOrders])

    //filters status list based on open/closed tab 
    useEffect(()=>{
        const filterStatus = () => {
            const newStatus = openClosedOrders == 'all' ? status : status.filter(status => status.OPEN_CLOSED.includes(openClosedOrders));
            setFilteredStatus(newStatus);
            }
        filterStatus()
    },[openClosedOrders])
 
    return (
        <>        
            <div className="footer-menu d-flex">
                {
                    filtersOrders.map((staticFilter, index) =>(
                        staticFilter.visibleFor.includes(roleString) && (

                    <div 
                        key={index} 
                        className='filter-item flex-grow-1' 
                        onClick={() => togglePopup(index)}
                    >
                        {/* <div>
                            <i className={`${staticFilter.image} fs-18 ${}`}></i>
                        </div> */}
                        {/* {!filter[staticFilter.label] || filter[staticFilter.label] == '<ALL>' || filter[staticFilter.label] == `${translate('this month')} (default)` ? ( */}
                        {!filter[staticFilter.label] || filter[staticFilter.label] == '<ALL>' || filter[staticFilter.label] == 'this month' ? (
                            <>
                            <div><i className={`${staticFilter.image} fs-18`}></i></div>
                            <p className='text-uppercase m-0'>{translate(staticFilter.label)}</p>
                            </>
                        ) : (
                            <>
                            <div><i className={`${staticFilter.image} fs-18 selected`}></i></div>
                            {filter[staticFilter.label] == 'select dates' ? (
                                <p className='m-0 selected'>{`${formatDate(datesRange.startDate, 'ddMMMyy')} - ${formatDate(datesRange.endDate, 'ddMMMyy')}`}</p>
                            ) : (
                                <p className='m-0 selected'>{translate(filter[staticFilter.label])}</p>
                            )
                            }
                            </>
                        )
                        }
                        {currentFilterIndex === index &&(
                            <FilterPopup 
                                isPopupVisible={currentFilterIndex === index} //true if index matches, else false
                                togglePopup={()=> togglePopup(index)} 
                                // handlePopupClick={()=>handlePopupClick(index)} 
                                content={
                                    staticFilter.label === "store"? stores : 
                                    staticFilter.label === "channel" ? channels : 
                                    staticFilter.label === "status"? filteredStatus : 
                                    staticFilter.label === "archive"? archive : 
                                    []
                                }
                                filterType={staticFilter.label}
                                selectFilter={staticFilter.label =='status'? selectFilterSetOpenClosed : selectFilter}
                                //differentially pass the content to the global popup based on the label of the filter
                                />

                        )}
                    </div>
                        )
                    ))
                }
            </div>
        </>
    );
}
 
export default FooterMenuOrders;




//moved to filters
// const status = [
//     {
//         ID: 0,
//         DESCRIPTION: config.all,
//         OPEN_CLOSED: ['open', 'closed']
//     },
//     {
//         ID: 1,
//         DESCRIPTION: 'inserted',
//         OPEN_CLOSED: ['open']
//     },
//     {
//         ID: 2,
//         DESCRIPTION: 'waybill',
//         OPEN_CLOSED: ['open']
//     },
//     {
//         ID: 3,
//         DESCRIPTION: 'processing',
//         OPEN_CLOSED: ['open']
//     },
//     {
//         ID: 4,
//         DESCRIPTION: 'dispatched',
//         OPEN_CLOSED: ['closed']
//     },
//     {
//         ID: 5,
//         DESCRIPTION: 'returned',
//         OPEN_CLOSED: ['closed']
//     },
//     {
//         ID: 6,
//         DESCRIPTION: 'part. returned',
//         OPEN_CLOSED: ['closed']
//     }
// ]    


// const archive = [
//     {
//         ID: 0,
//         // DESCRIPTION: `${translate('this month')} (default)`  //if modify here, have to modify the default val in the product provider too
//         DESCRIPTION: 'this month'  //if modify here, have to modify the default val in the product provider too
//     },
//     {
//         ID: 1,
//         DESCRIPTION: 'last month'
//     },
//     {
//         ID: 2,
//         DESCRIPTION: 'last 3 months'
//     },
//     {
//         ID: 3,
//         DESCRIPTION: 'select dates'
//     },
//     // {
//     //     ID: 3,
//     //     DESCRIPTION: translate('this year')
//     // },
//     // {
//     //     ID: 4,
//     //     DESCRIPTION: translate('last year')
//     // },
//     // {
//     //     ID: 5,
//     //     DESCRIPTION: translate('last 3 years')
//     // },
// ]    









// <div className="footer-menu d-flex">
//                 {
//                     filtersOrders.map((staticFilter, index) =>(
//                         staticFilter.visibleFor.includes(roleString) && (

//                     <div 
//                         key={index} 
//                         className='filter-item flex-grow-1' 
//                         onClick={() => togglePopup(index)}
//                     >
//                         <p className='m-0'>{translate(filter[staticFilter.label])}</p>
//                         <div>
//                             <i className={staticFilter.image}></i>
//                         </div>
//                         <p className='text-uppercase m-0'>{translate(staticFilter.label)}</p>
//                         {currentFilterIndex === index &&(
//                             <FilterPopup 
//                                 isPopupVisible={currentFilterIndex === index} //true if index matches, else false
//                                 togglePopup={()=> togglePopup(index)} 
//                                 // handlePopupClick={()=>handlePopupClick(index)} 
//                                 content={
//                                     staticFilter.label === "store"? stores : 
//                                     staticFilter.label === "channel" ? channels : 
//                                     staticFilter.label === "status"? status : 
//                                     []
//                                 }
//                                 filterType={staticFilter.label}
//                                 selectFilter={selectFilter}
//                                 //differentially pass the content to the global popup based on the label of the filter
//                                 />

//                         )}
//                     </div>
//                         )
//                     ))
//                 }
//             </div>
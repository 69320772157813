import { Popup } from 'devextreme-react/popup';
import PropTypes from 'prop-types';
// import { Switch } from "devextreme-react/switch";
// import { useCallback, useState } from 'react';
import DxSwitch from './DxSwitch';
import useTranslate from '../utils/useTranslate';


const ProductLookupOptionsPopup = ({
    isPopupVisible, togglePopup,
    handleAvailableOnly, availableOnly, 
}) => {
    
    const translate = useTranslate();

    const handleInsideClick = (e) => {
        e.stopPropagation();
    };

const renderContent = () => {
    return (
            <div className='text-center fs-6 lh-lg mt-3 container' onClick={handleInsideClick}>
                <div className="row pb-3">
                    <div className="col"><p className='m-0'>{translate('Available only')}</p></div>
                    <div className="col d-flex justify-content-center align-items-center">
                    <DxSwitch 
                        value={availableOnly}
                        // onValueChanged={(e) => handleGrossChange(e.value)}
                        onValueChanged={handleAvailableOnly}
                    />
                    </div>
                </div>
            </div>
    )
}

  return (
      <Popup
        contentRender={renderContent} 
        visible={isPopupVisible}
        hideOnOutsideClick={true}
        onHiding={togglePopup}
        showTitle={true}
        title={translate('Options')} 
        showCloseButton={true}
        // style={{height: '250px', minHeight: 'auto', width: '300px'}}
        width={300}
        height={'auto'}
        minHeight={'auto'}
      >
      </Popup>

  );
}


export default ProductLookupOptionsPopup

// import { useState } from 'react'
// import { Form } from 'react-bootstrap'
import { Button } from 'devextreme-react/button';
import { LoadPanel } from 'devextreme-react/load-panel';
import DropDownItemBarcode from "./DropDownItemBarcode.jsx";
// import LoadingPanel from './trash/LoadingPanel.jsx';
import ProductLookupList from './ProductLookupList.jsx';
// import ProductLookupQR from './ProductLookupQR.jsx';
import ProductLookupTextbox from './ProductLookupTextbox.jsx';
// import FooterMenu from './FooterMenu.jsx';
import PropTypes from 'prop-types';
import { ProductContext } from '../contexts/ProductProvider';
import { useContext } from 'react';
import { motion } from 'framer-motion';
// import usePreviousRoute from '../router/PreviousRoute.jsx';  
// import { useLocation, useParams } from 'react-router-dom';
import useTranslate from '../utils/useTranslate.jsx';
import { useNavigate } from 'react-router-dom'
import QRbutton from './QRbutton.jsx';


const animationProps = {
  initial: { x: '-100%', opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: '100%', opacity: 0 },
  transition: { duration: 0.3 }
}

function MainProductLookup({
  // stockScrollRef, onSearchChanged, 
  // setTypeSearch, setStockSearch, 
  stockArticles,
}) {

  const translate = useTranslate();
  const { noResultsMessage, isLoading } = useContext(ProductContext);
  const navigate = useNavigate();



  return (
    <>
      {/* <LoadingPanel/> */}
        
        <div id='lookup-form' className='d-flex align-items-center'>
          <ProductLookupTextbox 
            // onSearchChanged={onSearchChanged} 
            // setStockSearch={setStockSearch}
            />
          <DropDownItemBarcode 
            // onSearchChanged={onSearchChanged} 
            // setTypeSearch={setTypeSearch}
            /> {/* todo: in future change api calls so it does not require this filter, if i serch numbers it searches in itme and in barcode simoultaneously*/}
          <QRbutton
            // icon='photo'
            // path='/qr-scanner'          
            // onClick={()=> {navigate('/product-lookup/qr-scanner')}}
            // className='ms-2 me-1 qr-camera-btn border-dx'
            additionalClasses='ms-2 me-1 fs-18'
            source="product-lookup" 
            // fieldType="search" //name not needed bc there is only 1 field to fill whi the read barcode in the whole page
            />
        </div>

        {/* initial={{ opacity: 0 }}   
        animate={{ opacity: 1 }}   
        exit={{ opacity: 0 }}     
        transition={{ duration: 0.2 }}  */}

      <motion.div 
        className="scroll-view"
        id='stock-loadpanel-container'
        {...animationProps}
        // initial={{ x: '-100%', opacity: 0 }}   
        // animate={{ x: 0, opacity: 1 }}      
        // exit={{ x: '100%', opacity: 0 }}   
        // transition={{ duration: 0.3 }}     
      >
        {/* <div id='stock-loadpanel-container'> */}
        <LoadPanel 
          visible={isLoading}
          hideOnOutsideClick={false}
          container='#stock-loadpanel-container'
          shading={true}
          shadingColor="rgba(0, 0, 0, 0.2)"
          />
          {/* </div> */}
          {noResultsMessage ? (
          <p className='mt-4 fs-5 grey'>{translate('Products not found.')}</p>
          ) : (
            <>
            <ProductLookupList stockArticles={stockArticles} />
            </>
          )}
      </motion.div>
      {/* <FooterMenu filters={filters[0]}/> */}
    </>
  )
}

export default MainProductLookup

MainProductLookup.propTypes = {
  resultsNotFound: PropTypes.bool,
}



 //NOT WORKING , LAST ATTEMPT
  // const location = useLocation(); //to access navigation state from header if prev page is product detail
  // const isFromDetails = location.state?.isFromDetails;

  // const animationProps = isFromDetails
  // ? {
  //       initial: { x: '-100%', opacity: 0 },   
  //       animate: { x: 0, opacity: 1 },      
  //       exit: { x: '100%', opacity: 0 },   
  //       transition: { duration: 0.3 }
  //   }
  // : {
  //     initial: { opacity: 0 },
  //     animate: { opacity: 1 },
  //     exit: { opacity: 0 },
  //     transition: { duration: 0.3 }
  //   };


  ///////
  // const previousLocationRef = useRef(null);
  // useEffect(() => {
  //   previousLocationRef.current = location;
  // }, [location]);

    // const isComingFromProductPage = previousLocationRef.current?.pathname.includes('/product-lookup/') && previousLocationRef.current?.pathname !== '/product-lookup'
  // const previousRoute = usePreviousRoute();
  // 
  // const isComingFromProductPage = previousLocationRef.current?.pathname.includes('/product-lookup/') && previousLocationRef.current?.pathname.split('/').length > 2;

//   const params = useParams();
// const isComingFromProductPage = params.id !== undefined;
 


//  const [query, setQuery] = useState("");

//  const handleChange = (e) => {
//     setQuery(e.target.value);
//     console.log(query); 
    // TODO: Send query to API to fetch products based on search term. OR filter products if already available from api
//  }
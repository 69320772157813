import PropTypes from 'prop-types';
 
// import { TextBox } from 'devextreme-react/text-box';
// import { useCallback, useEffect, useState } from 'react';

import { useContext, useState } from 'react';
import FilterPopup from './FilterPopup';
import SalesDashboardOptionsPopup from './SalesDashboardOptionsPopup';
import useTranslate from '../utils/useTranslate';
import { ProductContext } from '../contexts/ProductProvider';
import Cookies from 'js-cookie';
import { expireOptions } from '../utils/constants/expireOptions';


const filtersSalesDashboard = [
    {
        label: "store",
        image: 'fa-solid fa-store',
        // image: 'fa-solid fa-shopping-bag',
    },
    {
        label: "supplier",
        // image: 'fa-solid fa-tag',
        image: 'fa-solid fa-truck',
    },
    {
        label: "options",
        image: "fa-solid fa-toggle-on",
    }
]; 


function FooterMenuDashboard({
    filter, stores, suppliers, selectMultipleFilter,
    // handleGrossChange, isGross, 
    // handleCumulativeChange, 
    // isCumulative
}) {

    const { isGross, setIsGross } = useContext(ProductContext)
    
        const translate = useTranslate();
    // const [selectedOption, setSelectedOption] = useState('all');
    // const [isPopupVisible, setIsPopupVisible] = useState(false);
    // const [isPopupOptionsVisible, setIsPopupOptionsVisible] = useState(false);  //todo: remove, just use the ispopupVisible and the toggle

    const [currentFilterIndex, setCurrentFilterIndex] = useState(null);
    
    const togglePopup = (index) =>{
        setCurrentFilterIndex(index === currentFilterIndex ? null : index);
    } 
    
    //ex grossChange 
    const handleGrossChange = (e) => {
        setIsGross(e.value);
        Cookies.set('dashboard-is_gross', e.value, { expires: expireOptions });
        // dashboard(tab);
    };
    
    // const togglePopup = () =>{
    //     setIsPopupVisible(!isPopupVisible)
    // }

    // const togglePopupOptions = () =>{
    //     setIsPopupOptionsVisible(!isPopupOptionsVisible)
    // }

    //manages the selection of one option, returns the filtered items
    // const handlePopupClick = (option) => {     
    
    //     console.log(option)
    //     // if () //todo se clicco nel popup 2 deve cambiare il valore dellopzione 2
    //     setSelectedOption(option)
    //     //todo: save in store e.target.value? isit necessary? do we want the selection to persist?
    //     // todo: send the filter option to the backend
    //     togglePopup()
    // }



    // const [value, setValue] = useState("");

    // const onValueChange = useCallback((v) => {
    //     setValue(v)
    //     console.log(value);
    // }, [value]);

    // const selectedOption = 'selected';
    // TODO fetch data based on the selected filter from the backend and update the state with the new data OR filter already existent json and display it?

    //todo capire come targettare i singoli options senza settarli tutti con l'utimo valore selezionato  --> selectMultipleFilter

    return (
        <>        
            <div className="footer-menu d-flex">
                {
                    filtersSalesDashboard.map((staticFilter, index) =>(
                        <div 
                            key={index} 
                            className={`filter-item flex-grow-1 ${index == 2 ? 'd-flex flex-column justify-content-center' : ''}`} 
                            onClick={() => togglePopup(index)}>
                                {index == 2  ? ( 
                                <>
                                    <div><i className={`${staticFilter.image} fs-18 ${isGross ? 'selected' : ''}`}></i></div>
                                    <p className={`text-uppercase m-0 ${isGross ? 'selected' : ''}`}>{translate(staticFilter.label)}</p>
                                </>
                                ) : (filter[staticFilter.label] == '<ALL>' ? (
                                    <>
                                    <div><i className={`${staticFilter.image} fs-18`}></i></div>
                                    <p className='text-uppercase m-0'>{translate(staticFilter.label)}</p>
                                    </>
                                ) : (
                                    <>
                                    <div><i className={`${staticFilter.image} fs-18 selected`}></i></div>
                                    <p className='m-0 selected'>{translate(filter[staticFilter.label])}</p>
                                    </>
                                ))}

                                
                                {currentFilterIndex === index && (
                                    (currentFilterIndex == 2) ? (
                                        <SalesDashboardOptionsPopup 
                                            isPopupVisible={currentFilterIndex === index} 
                                            togglePopup={()=> togglePopup(index)}
                                            handleGrossChange={handleGrossChange}
                                            isGross={isGross}  
                                            // handleCumulativeChange={handleCumulativeChange}
                                            // isCumulative={isCumulative}
                                        />
                                        
                                    ) :
                                    (
                                        <FilterPopup
                                            isPopupVisible={currentFilterIndex === index} 
                                            togglePopup={()=> togglePopup(index)} 

                                            // handlePopupClick={ handlePopupClick}
                                            content={
                                                staticFilter.label === "store"? stores : 
                                                staticFilter.label === "supplier"? suppliers : 
                                                []
                                            }
                                            filterType={staticFilter.label}
                                            selectFilter={selectMultipleFilter}
                                        //differentially pass the content to the global popup based on the label of the filter

                                            />
                                    )
                                )}
                        </div>
                        ))

                }


                    {/* <div className='filter-item flex-grow-1' onClick={togglePopup}>
                            <p className='m-0'>{`${selectedOption}`}</p>
                            <div>
                                <i className='fa-solid fa-shopping-bag'></i>
                            </div>
                            <p className='text-uppercase m-0'>shop</p>
                    </div>
                    <div className='filter-item flex-grow-1' onClick={togglePopup}>
                            <p className='m-0'>{`${selectedOption}`}</p>
                            <div>
                                <i className='fa-solid fa-tag'></i>
                            </div>
                            <p className='text-uppercase m-0'>supplier</p>
                    </div>                    
                    <div className='filter-item flex-grow-1 d-flex flex-column justify-content-center' onClick={togglePopupOptions}>
                            <div>
                                <i className='fa-solid fa-toggle-on'></i>
                            </div>
                            <p className='text-uppercase m-0'>options</p>
                    </div>
                    
                 */}
            </div>

            
            {/* <FilterPopup isPopupVisible={isPopupVisible} togglePopup={togglePopup} /> */}
            {/* <FilterPopup isPopupVisible={isPopupVisible} togglePopup={togglePopup} handlePopupClick={ handlePopupClick}/>
            <SalesDashboardOptionsPopup isPopupOptionsVisible={isPopupOptionsVisible} togglePopupOptions={togglePopupOptions}/> */}
            {/* must pass a prop to identify which list has to be rendered and to change just the val of that one folter, not all */}

        </>
    );
}
 
export default FooterMenuDashboard;


{/* <>        
            <div className="footer-menu d-flex">
                {
                    filtersSalesDashboard.map((staticFilter, index) =>(
                        <div 
                            key={index} 
                            className={`filter-item flex-grow-1 ${index == 2 ? 'd-flex flex-column justify-content-center' : ''}`} 
                            onClick={() => togglePopup(index)}>
                                {index != 2 &&
                                    <p className='m-0'>{translate(filter[staticFilter.label])}</p>
                                }
                                <div>
                                    <i className={staticFilter.image}></i>
                                </div>
                                <p className='text-uppercase m-0'>{translate(staticFilter.label)}</p>
                                
                                {currentFilterIndex === index && (
                                    (currentFilterIndex == 2) ? (
                                        <SalesDashboardOptionsPopup 
                                            isPopupVisible={currentFilterIndex === index} 
                                            togglePopup={()=> togglePopup(index)}
                                            handleGrossChange={handleGrossChange}
                                            isGross={isGross}  
                                            // handleCumulativeChange={handleCumulativeChange}
                                            // isCumulative={isCumulative}
                                        />
                                        
                                    ) :
                                    (
                                        <FilterPopup
                                            isPopupVisible={currentFilterIndex === index} 
                                            togglePopup={()=> togglePopup(index)} 

                                            // handlePopupClick={ handlePopupClick}
                                            content={
                                                staticFilter.label === "store"? stores : 
                                                staticFilter.label === "supplier"? suppliers : 
                                                []
                                            }
                                            filterType={staticFilter.label}
                                            selectFilter={selectMultipleFilter}
                                        //differentially pass the content to the global popup based on the label of the filter

                                            />
                                    )
                                )}
                        </div>
                        ))

                }
            </div>
        </> */}
import Cookies from 'js-cookie';

const CardSmallTotal = ({subtitle, value, fwNormal, addFormat, children, totalQty, addPadding, error500}) => {
    const currency = Cookies.get('currency');

    const formatValue = (value) => {
      // if (value) return null

      if (value == null) return null;
        // console.log(formatting, 'formatting');
        // console.log(addPercent, 'addPercent')
        const roundedValue = Math.round(value);

        switch (addFormat) {
          case 'percentToFixed0':
            return `${roundedValue}%`;   
          case 'currency':
            return `${currency} ${Intl.NumberFormat("it-IT").format(value)}` //convert here (in child) bc CardSmall needs to receive a number to se the proper css
          case 'euFormat':
            return `${Intl.NumberFormat("it-IT").format(value)}` //convert here (in child) bc CardSmall needs to receive a number to se the proper css
          case 'euFormatToFixed0':
            return `${Intl.NumberFormat("it-IT").format(roundedValue)}` //convert here (in child) bc CardSmall needs to receive a number to se the proper css
          default:
            return value
        }
      };

      // console.log('formatValue', formatValue(value));
      
  return (
    <div className={`total ${fwNormal ? 'fw-normal' : 'fw-bold'} d-flex align-items-start mb-2`}>
        <div className='margin-right-1'>
          {/* {subtitle && */}
          {/* <> */}
          <small>{subtitle || ''}</small>
          <br/>
          {/* </> */}
          {/* } */}

          <div className='d-flex justify-content-between'>
            <div className="d-flex flex-column me-2">
                <small>{currency}</small>
                {/* <br /> */}
                <span>{('Qty')}</span>
            </div>

            <div>
            {formatValue(value) == null || error500 ? (
              <p className='pt-2'>
                <i className='dx-icon-pulldown big-number text-end'></i>
              </p>
            ) :  (
              <>
              <p className='big-number text-end'>{formatValue(value)}</p>
              <p className='text-end'>{totalQty}</p>
              </>
            )
            }
            </div>
          </div>
        </div>
        {children}
    </div>  
  )
}

export default CardSmallTotal
//Helper (es. remove N/A)

import config from '../utils/constants/config'

const Helper = () => {
    const removeBadValue = (data, field, type) => {
        /* EX comment:
          data is json
          field can be a name or null (all fields)
          type can be all or empty to replace with all in config or empty
        */
        const replace = type === 'all' ? config.all : '';
        data.forEach(item => {
          if (field === null) {
            Object.keys(item).forEach(key => {
              if (item[key] == 'N/A' || item[key] == 'N\A' || item[key] == 'TUTTI') {
                item[key] = replace;
              }
            });
          } else {
            if (item[field] == 'N/A' || item[field] == 'N\A' || item[field] == 'TUTTI') {
              item[field] = replace;
            }
          }
        });
        return data;
      }
    
    const allFirst = (data) => {
        //All is not first (ex. if order by description) this function moves it to the top
        let allPosition = 0;
        data.forEach((item, index) => {
          if (item.ID === "00000000-0000-0000-0000-000000000000") {
            allPosition = index;
          }
        });
        const all = [data[allPosition]];
        data.splice(allPosition, 1);
        data = all.concat(data);
        return data
      }
    
      const addAllFirst = (data, field) => {
        //Add "all" at the top in a specific field
        const all = [{ [field]: config.all }]; //original: var all = [JSON.parse('{"'+field+'":"'+config.all+'"}')];
        data = all.concat(data);
        return data
      }
    
      const valueInK = (data, field) => {
        // Set value in k except for the specified field (e.g., label)
        data.forEach(item => {
          Object.keys(item).forEach(key => {
            if (key !== field) {
              item[key] = item[key] / 1000;
            }
          });
        });
        return data;
      }

    return { removeBadValue, allFirst, addAllFirst, valueInK }
};


export default Helper
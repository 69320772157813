//let's skip this file and call functions directly to avoid data loss in the passages
//Login and logout
import config from '../utils/constants/config';
import useApi from './useApi';

const ApiCalls = () => {
  const { callApi } = useApi()

  async function getAccessLevel(formData) {
    const { success, data, error } = await callApi('POST', `/Config/${config.language}/Get_ACCESS_LEVEL`, formData);
    if (success) {
      return { success: true, data: data };
    } else {
      console.error('Error in getAccessLevel:', error);
      return { success: false, error: 'Authentication failed' };
    }
  }

  async function getBaseValues(formData) {
    const { success, data, error } = await callApi('POST', `/Config/${config.language}/Get_BASE_VALUES`, formData);
    if (success) {  
      return { success: true, data: data };
    } else {
      console.error('Error in getBaseValues:', error); 
      return { success: false, error: 'Authentication failed' };  
    }
  }

  async function getUrl(formData) {
    const { success, data, error } = await callApi('POST', `/Config/${config.language}/Get_URL`, formData);
    if (success) {  
      return { success: true, data: data };
    } else {
      console.error('Error in getUrl:', error);
      return { success: false, error: 'Authentication failed' };        
    }
  }           

  
  return { getAccessLevel, getBaseValues, getUrl}
  }
export default ApiCalls

//OLD, not error handling
// async function getAccessLevel(formData) {
//   try {
//     const responseData = await callApi('POST', `/Config/${config.language}/Get_ACCESS_LEVEL`, formData);

//     return { isOk: true, data: responseData };
//   } catch (error) {
//     console.error('Error in getAccessLevel:', error);
//     return { isOk: false, message: 'Authentication failed' };
//   }
// }

// async function getBaseValues(formData) {
//   try {
//     const responseData = await callApi('POST', `/Config/${config.language}/Get_BASE_VALUES`, formData);  
//     return { isOk: true, data: responseData };  
//   } catch (error) {
//     console.error('Error in getBaseValues:', error); 
//     return { isOk: false, message: 'Authentication failed' };  
//   }
// }

// async function getUrl(formData) {
//   try {
//     const responseData = await callApi('POST', `/Config/${config.language}/Get_URL`, formData);  
//     return { isOk: true, data: responseData };  
//   } catch (error) {
//     console.error('Error in getUrl:', error); 
//     return { isOk: false, message: 'Authentication failed' };  
//   }
// }  
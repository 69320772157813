import { useNavigate } from 'react-router-dom'
import { useEffect, useContext, useRef } from 'react';
import Cookies from 'js-cookie';
import useTranslate from '../utils/useTranslate.jsx';
import { ProductContext } from '../contexts/ProductProvider.jsx';
import demo from '../assets/images/wallpaper_login.jpg';
import { getStatusClass } from '../utils/functions.js';
import { httpToHttps } from '../utils/functions';


const OrderListItem = ({orders, filter}) => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const currency = Cookies.get('currency')
    const role = Cookies.get('role')

    const { ordersItemScrollPosition, setOrdersItemScrollPosition
        // setStockPagination, stockScrollPosition, setStockScrollPosition, stockSearch, typeSearch, filter, isLoading, search  
    } = useContext(ProductContext)
    const orderScrollRef = useRef(null);

//hanndle scroll
    // const updateBottomContent = () => {
    //     console.log('Reached the bottom!');
    //     if(filter.season != undefined && filter.department != undefined && filter.supplier != undefined){
    //         setStockPagination(prevPag => prevPag + 1)
    //     } 
    // };

    const handleScroll = () => {
        const scrollTop = orderScrollRef?.current.scrollTop; //value of the top from offset
        // const scrollHeight = orderScrollRef?.current.scrollHeight;
        // const clientHeight = orderScrollRef?.current.clientHeight;
        
        // Save scroll position during scroll
        setOrdersItemScrollPosition(scrollTop);

        // // Check if the scroll has reached the bottom
        // if (scrollTop + clientHeight >= scrollHeight) {
        //     updateBottomContent();
        // }
    };

    // Set the div's scroll position to the stored value when the component mounts
    useEffect(() => {
        if (orderScrollRef?.current) {
            orderScrollRef.current.scrollTop = ordersItemScrollPosition;
        }
    }, [ordersItemScrollPosition]);

    // //delays the search, params is the query typed by the user
    // useEffect(() => {    
    //     let delayTimer;
    //     clearTimeout(delayTimer);
    //     delayTimer = setTimeout(() => {
    //         if(orderScrollRef?.current){
    //             orderScrollRef.current.scrollTop = 0;
    //             setStockPagination(1)
    //             // search() //MOVED TO PROVIDER
    //         }
    //     }, 500);
    // }, [ stockSearch, typeSearch ]);

    //scroll top when filter change
//ATTENZIONE questo impedosce il corretto scroll, per ora commentato, sarà da scommentare o da collegare ad altro es se cambiano gli ordini che cambiano in seguito alla fetch, che parte se i filtri cambiano --> stesso problema anche con orders
                                // useEffect(() => {
                                //     if (orderScrollRef?.current){
                                //         orderScrollRef.current.scrollTop = 0;
                                //         // setStockPagination(1)
                                //     }
                                // }, [filter])

    //MOVED TO FUNCTIONS
    // const getStatusClass = (status) => {
    //     switch (status) {
    //       case 'inserted': //inserito
    //         return 'status-inserted';
    //       case 'waybill': //lettera vettura
    //         return 'status-waybill';
    //       case 'processing': //lavorazione
    //         return 'status-processing';
    //       case 'dispatched': //or processed  //evaso
    //         return 'status-dispatched';
    //       case 'returned': //or processed  //evaso
    //         return 'status-returned';          
    //       case 'partially returned': //or processed  //evaso
    //         return 'status-part-returned';
    //       default:
    //         return '';
    //     }
    //   };
  
  return (
    <>
    {/* <div id='stock-item-list' className='scroll-view'> */}
        <ul className='stock-item-ul scroll-view-ul list-unstyled ' ref={orderScrollRef} onScroll={handleScroll}>
        {orders.map((order, index) => (
            <li 
                key={index}
                className='stock-item order-item border-top-none text-start'
                // onClick={()=> {navigate(`/order/${order.ID}`)}}
            >

                <div 
                    className='stock-item-child pt-1 d-flex justify-content-between hover-grey' 
                    onClick={()=> {navigate(`/orders/${order.ID}`)}}
                >
                    <div>
                        <p className='fw-bold'>{order.CUSTOMER.NAME} </p>
                        <p className='fw-bold pe-1 pb-1'>{`#${order.ID}`}</p>
                    </div>
                    <div className='d-flex flex-column align-items-end'>
                        <p className={`order-status ${getStatusClass(order.STATUS)}`}>{translate(order.STATUS)}</p>
                        <p className='grey pe-1 pb-1 text-end'>{`${order.SALE_CHANNEL} #${order.ID}`}</p>
                    </div>
                </div>

{order.ITEMS.map((item, i) => (
                <div 
                    key={i}  
                    className='order-single-item d-flex justify-content-between align-items-center py-1'
                    // onClick={navigate(`/product-lookup/${item.ID}`)}
                    onClick={()=>navigate(`/product-lookup/${item.ID}`)}
                    >
                    <div className='stock-item-right d-flex align-items-center flex-shrink-1 flex-grow-1'>
                    {/* image */}
                        <div className='img-container d-flex justify-content-center align-items-center'>
                            <img src={httpToHttps(item.IMAGE_01)} alt="image" />

{/* src={item.IMAGE_01} */}
                            {/* <img src={demo} alt="image" />   */}
                        </div> 

                        {/* data */}
                        <div className='d-flex justify-content-between  text-start me-2 pe-0 pe-sm-4 flex-grow-1'>
                            <div>
                            <p className="title text-uppercase fw-semibold fs-14 lh-1">{item.CODE}
                                <span className='grey'> {item.AUX_CODE}</span>
                            </p>
                            <div className='grey'>
                            <p className="description fs-12">{item.DESCRIPTION}</p>
                            <p className="variant fs-12">{item.VARIANT_DESCRIPTION}</p>
                            <p className="supplier-name text-uppercase d-inline fs-12">{item.BRAND}
                                {/*<span className='text-uppercase text-black-50 '> {item.SUPPLIER_NAME}</span>  da mettere?  */}
                            </p>   
                            </div>

                                {/* 
                                <p>{order.CODE} <span>{order.AUX_CODE}</span></p>
                                <p>{order.DESCRIPTION}</p>
                                <p>{order.VARIANT_DESCRIPTION}</p>
                                {/* also add supplier? 
                                <p>{order.BRAND}</p> 
                                {/* <p>{order.ID}</p>
                                <p>{order.ID}</p>
                                <p>{order.ID}</p> */} 
                            </div>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <p className='title text-uppercase fw-semibold flex-shrink-0 fs-4'>{item.SIZE}</p>
                                {/* <p>{`${translate('qty')}: ${item.ITEM_QTY}`}</p> */}
                                {item.ITEM_QTY > 1 &&
                                <p className='fs-5'><span className='light-grey fs-12'>{translate('qty')}: </span>{item.ITEM_QTY}</p>
                                }
                            </div>
                        
                          
                        </div>
                    </div>

                    {/* arrow */}
                    <div className='arrow pe-0 pe-sm-5 flex-shrink-0'>
                        <i className='fa-solid fa-chevron-right'></i>
                    </div>

                </div>                        

))}

            </li>

            ))}
        </ul>   

    </>
    
  )
}

export default OrderListItem





  {/* <div>  */}
                                {/* <div className='d-flex justify-content-between'>
                                    <p className='text-secondary'>{order.CUSTOMER} </p>
                                    <p className='text-secondary'>{`#${order.ID}`}</p>
                                </div> */}
                                {/* <div className='d-flex justify-content-between flex-grow-1'>
                                    <p className="title order-item-description text-uppercase flex-shrink-1">{order.CUSTOMER}</p>
                                    <p className='title text-uppercase fw-bold flex-shrink-0 fs-1'>{order.ITEM_SIZE}</p>
                                </div>
                                <div className='d-flex justify-content-between pb-1'>
                                    <p className='text-secondary'>{`${order.STORE}`} </p>
                                    <p className='text-secondary'>{`${order.SALE_CHANNEL}`}</p>
                                </div>
                                    
                                <div className='d-flex justify-content-between'>
                                    <span className={`description border rounded-pill px-2 ${getStatusClass(order.STATUS)}`}>{translate(order.STATUS)}</span>
                                    <span>{order.DATE}</span>
                                </div> */}
                                {/* <p className="supplier-name text-uppercase d-inline text-secondary-emphasis">{order.VALUE_08}
                                <span className='text-uppercase text-black-50 '> {order.SUPPLIER_CUSTOMER}</span>
                                </p>     */}
                                
                            {/* </div> */}

                            {/* <div className="container m-0 p-0 mt-1 flex-shrink-1">
                                <div className="row flex-shrink-1">
                                    <div className="col-6 flex-shrink-1">
                                        <div className="cell">
                                            <p className="cell-title fw-bolder">{translate('Available')}</p>
                                            <p className="cell-content fw-bolder">{Intl.NumberFormat('it-IT').format(order.NUM_DATA_05 - order.IMP_QTY)}</p>
                                        </div>
                                        <div className="cell grey">
                                            <p className="cell-title">{translate('Reserved')}</p>
                                            <p className="cell-content">{Intl.NumberFormat('it-IT').format(order.IMP_QTY)}</p>
                                        </div>
                                        <div className="cell grey">
                                            <p className="cell-title">{translate('In Order')}</p>
                                            <p className="cell-content">{Intl.NumberFormat('it-IT').format(order.NUM_DATA_01)}</p>
                                        </div>                                    
                                    </div>
                                    <div className="col-6 flex-shrink-1">
                                        <div className="cell">
                                            <p className="cell-title fw-bolder">{translate('Price')}</p>
                                            <p className="cell-content fw-bolder" >{currency} {Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(order.NUM_DATA_10)}</p>
                                        </div>
                                        {(role==0 || role==1) && (
                                        <div className="cell grey">
                                            <p className="cell-title">ST%</p>
                                            <p className="cell-content">{(order.NUM_DATA_09*100).toFixed(0)} %</p>
                                        </div>
                                        )}                                    

                                        <div className="cell grey">
                                            <p className="cell-title">{translate('Color')}</p>
                                            <p className="cell-content">{order.VARIANT_DESCRIPTION}</p>
                                        </div>
                                </div>
                            </div>
                        </div> */}

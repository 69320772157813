// import LoginCardAuth from '../components/Login/LoginCardAuth';
import LoginForm from '../components/LoginForm';
// import italyFlag from '../assets/images/italy_flag.jpg'
// import { SelectBox } from 'devextreme-react/select-box';
// import { Popup } from 'devextreme-react/popup';
import { useCallback, useContext } from 'react';
import useTranslate from '../utils/useTranslate';
import { LanguageContext } from '../contexts/LanguageProvider';
import logo from '../assets/images/FullLogo_Transparent.png';
// import {version} from '../utils/constants/version';
// import packageJson from '../../package.json'
// import Cookies from 'js-cookie';
// import { expireOptions } from '../utils/constants/expireOptions';
import LanguageDropdown from '../components/LanguageDropdown';


const languages = [
  { text: 'Italiano', value: "it-IT" },
  { text: 'English', value: "en-EN" },
];


//todo passare la lingua o ai cookies? poi la uso?
function Login() {
  const translate = useTranslate();
  const { language, changeLanguage } = useContext(LanguageContext)
  
  const onValueChanged = useCallback((e) => {
    changeLanguage(e.value) //it-IT  //set lang in Language provider 
  }, []);
  

// NEW VERSION POPUP
  // const [isPopupVisible, setPopupVisibility] = useState(Cookies.get('version') == packageJson.version ? false : true); //TO MODIFY IN NEW VERSION: 2.0.1 etc
  // const togglePopup = () => {
  //   setPopupVisibility(!isPopupVisible);
  //   Cookies.set('version', packageJson.version, { expires: expireOptions });
  // };
  // const popupTitle = useMemo(() => translate('New version'), []);

  // const renderContent = useCallback(() =>  {
  //   return (
  //       <div className='px-4 py-3'>
  //           <p>
  //           Benvenuti nella nuova versione dell'app Eversell, che è stata ridisegnata con una moderna tecnologia.
  //           </p>
  //           <p className='pt-2'>
  //           L'app è ora disponibile in <b>inglese</b> e <b>italiano</b>, con nuove funzionalità nella <b>Dashboard vendite</b> e una nuova pagina delle <b>Impostazioni</b>.
  //           </p>
  //           <p className='pt-2'>
  //           Altre nuove funzionalità saranno presto disponibili.
  //           </p>
  //       </div>
  //   )
  // }, [])


  return (
    <div id='login'>
      {/* <Popup
        contentRender={renderContent}
        visible={isPopupVisible}
        hideOnOutsideClick={true}
        onHiding={togglePopup}
        showTitle={true}
        title={popupTitle}
        defaultWidth={290}
        defaultHeight={'auto'} 
        resizeEnabled={true}
        showCloseButton={true}
      >
      </Popup> */}
      <div id='language-container' className='d-flex justify-content-end'>
        <LanguageDropdown language={language} languages={languages} onValueChanged={onValueChanged} />
      </div>

      <div className='auth-card'>
        <div className='dx-card content'>
          <div className='header'>
            <img src={logo} alt="logo" />
            <div className='title'>{translate('Log In')}</div>
            <div className='description'>{translate('Welcome on eversell')}</div>
          </div>
          <div className="login-content">
            <LoginForm />
          </div>
        </div>
      </div>
    </div>
  );
}


export default Login


      {/* <div id='language-container' className='d-flex justify-content-end'>
        <SelectBox
          className='language-dropdown'
          dataSource={languages}
          displayExpr= 'text'
          valueExpr='value'   
          // searchEnabled={true}
          onValueChanged={onValueChanged}
          width={100}
          height={30}
          // defaultValue={languages[0].value} //todo: change, if user changes in settings, this does not upd
          defaultValue={language} //todo: change, if user changes in settings, this does not upd
          placeholder={false}
          acceptCustomValue={false}
          aria-label="language"
          // fieldRender={renderField}
        />
      </div> */}



            {/* <img src={logo} alt="logo" /> */}
            {/* {translate('Welcome to the new version of eversell app!')} */}
 {/* <p className='pt-2'>
              The app is now available in <b>English</b> and <b>Italian</b>, with new features in the <b>Dashboard</b> and a brand new <b>Settings</b> page where you can check your currently logged account and manage the language settings.
            </p> 

 dove puoi controllare l'account attualmente connesso e scegliere la lingua. */}





  // const renderField = (data) => {
  //   return (    
  //   <TextBox
  //     defaultValue={data.text}
  //   />)
  // }



// const langEditorOptions = {
//   displayExpr: 'text',
//   valueExpr: 'value',
//   searchEnabled: true,
// }
  // const dropDownBoxRef = useRef(null);


//upd selectedLanguage when user selects item from List
  // const changeDropDownBoxValue = useCallback((arg) => {
  //   console.log('ard addedItem:', arg.addedItems[0], 'text:', arg.addedItems[0].text )
  //   setSelectedLanguage(arg.addedItems[0]);
  //   setShownLanguage(arg.addedItems[0].text);
  //   if(dropDownBoxRef.current){
  //     dropDownBoxRef.current.instance().close();
  //   }
  // }, []);

  // upd selectedLanguage with the new value of the dropdown
  // function needed if we will put other selection options other than List onClick
    // const onValueChanged = useCallback((e) => {
    //   console.log(e.value)
    //   setSelectedLanguage(e.value);
    // }, []);


  // const onValueChanged = useCallback((e) => {
  //   console.log('value:',e.value)
  //   const selectedLang = languages.find(lang => lang.value === e.value);
  //   setSelectedLanguage(selectedLang);
  // }, []);

// const languages = [
//   {
//     text: 'English', 
//     flag: italyFlag, 
//   },
//   {    
//     text: 'Italiano',
//     flag: italyFlag,
//   }  
//   ]

// const languages = ['Italiano', 'English', ]



   {/* <DropDownBox 
        // className='d-flex justify-content-end pt-3 pe-4'
        className='language-dropdown'
        ref={dropDownBoxRef}
        dataSource={languages}
        displayExpr= 'text'
        valueExpr='value'   
        // value={selectedLanguage}
        // onValueChanged={onValueChanged}
        width={100}
        height={30}
        acceptCustomValue={false}
        openOnFieldClick={true} //user can opne dropdown by clicking text field
        // editorOptions={langEditorOptions}
                // text={selectedLanguage.text}
                // icon={selectedLanguage.flag}
                // text='italiano'
                // icon='user'
        >
        <List
          dataSource={languages}   
          selectionMode="single"
          onSelectionChanged={changeDropDownBoxValue}
        />     
        {/* <img id='flag' src={italyFlag} alt="" />
        <p>Italiano</p> 
      </DropDownBox> */}
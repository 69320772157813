import {Popup} from 'devextreme-react/popup';
import {Button} from 'devextreme-react/button';
import { useCallback, useState } from 'react';
import useTranslate from '../utils/useTranslate';
import { Popover } from 'devextreme-react/popover';

const NotificationBell = () => {
    const [newOrdersNumber, setNewOrdersNumber] = useState(4);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const translate = useTranslate();
    
    //questa andra passata dal padre che fa effettivamente la api call
    const showNewOrders = () => {
        console.log('Showing new orders');
        setIsPopupVisible(false);
        setNewOrdersNumber(0);
        // fetchOdreds
        // set notiifcations to 0
        // Add your logic to show new orders
    };

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const renderContent = useCallback(() => {
        return (
            <div className="notification-popup-content">
                <p>{translate('There are')} </p>
                <p className='fs-18'> {newOrdersNumber} </p>
                <p className='pb-3'>{translate('new orders')}</p>
                <Button
                    className='mt-2'
                    text={translate('view')} 
                    onClick={showNewOrders}
                    stylingMode="contained"
                    type='default'
                    disabled={!newOrdersNumber}
                    width={80}
                /> 
            </div>
        )
    }, [newOrdersNumber])

  return (
    <div className="notification-bell-container">
        <div className="notification-bell" onClick={togglePopup} id='notification-bell'>
        <button className='p-0 border-0 bg-light-grey'>
            <i id='notification-bell-target' className='fa-solid fa-bell'></i>
        </button>
        {newOrdersNumber > 0 && (
                <span className="notification-badge">{newOrdersNumber}</span>
            )}
        </div>
        <Popover 
            id="notification-popup"
            target='#notification-bell'
            contentRender={renderContent} 
            visible={isPopupVisible}
            hideOnOutsideClick={true}
            onHiding={togglePopup}
            showTitle={false}
            showCloseButton={false}
            width={180}
            height={'auto'}
            position='bottom'

            // position={
            //     {
            //         my: 'top right',   // Align the popup's top-right to the target's bottom-right
            //         at: 'bottom right', // Position it below the bell (aligning right edges)
            //         of: '#notification-bell', // The target is the bell icon
            //         // offset: '0 0',   // Add a bit of margin: 10px from right and 5px from bottom
            //         boundary: window,   // Constrain the popup within the window
            //         boundaryOffset: '10 20' // Ensure a 10px gap from the right edge of the window
            //     }
            // }
        />
        {/* <Popup 
            id="notification-popup"
            contentRender={renderContent} 
            visible={isPopupVisible}
            hideOnOutsideClick={true}
            onHiding={togglePopup}
            showTitle={false}
            showCloseButton={false}
            width={180}
            height={'auto'}
            position={
                {
                    my: 'top right',   // Align the popup's top-right to the target's bottom-right
                    at: 'bottom right', // Position it below the bell (aligning right edges)
                    of: '#notification-bell', // The target is the bell icon
                    // offset: '0 0',   // Add a bit of margin: 10px from right and 5px from bottom
                    boundary: window,   // Constrain the popup within the window
                    boundaryOffset: '10 0' // Ensure a 10px gap from the right edge of the window
                }
            }
        /> */}
    </div>
  )
}

export default NotificationBell


{/* <Button
    className='mt-2'
    text='See now' 
    onClick={showNewOrders}
    stylingMode="contained"
    type='default'
/> */}
//http request interceptor: to append token in every request
import axios from 'axios';
import config from '../utils/constants/config';
import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  baseURL: config.url,
})
  
//this adds interceptor to add token to headers in every request and check if token needs refresh
axiosInstance.interceptors.request.use(
  config => {
    // if (config.method === 'post' || config.method === 'put') {    //for now they are all post
    config.headers['Content-Type'] = 'application/json';
    const token = Cookies.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;  //todo: capire se cambiare in JWT
    }
    return config;
  },
  error => {
    console.log('Request interceptor error:', error);    
    return Promise.reject(error);
  }
);

//refresh the token when you get 401 error
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      error.isCancel = true;
    } else if (error.response) {
      console.log('Response error:', error, error.response.status, error.response.data);
    } else if (error.request) {
      console.log('No response received:', error.request);
    } else {
      console.log('Error setting up request:', error.message);
    }
    return Promise.reject(error);
  }
)

export default axiosInstance
  
  // {
  //   return Promise.reject(error)

  // }

    // {
    // if (error.response && error.response.status === 401) {
    //   console.log('call the refresh token api here')
    //   // tood: Handle 401 error, e.g., redirect to login or refresh token
    // }
    




    // let errorMessage = 'An unexpected error occurred';

    // if (error.response) {
    //   // The request was made and the server responded with a status code that falls out of the range of 2xx
    //   switch (error.response.status) {
    //     case 400:
    //       errorMessage = 'Bad Request: The server could not understand the request';
    //       break;
    //     case 401:
    //       errorMessage = 'Unauthorized: Authentication is required';
    //       // You can add logic here to refresh the token if needed
    //       // console.log('call the refresh token api here');
    //       break;
    //     case 403:
    //       errorMessage = 'Forbidden: You do not have permission to access this resource';
    //       break;
    //     case 404:
    //       errorMessage = 'Not Found: The requested resource could not be found';
    //       break;
    //     case 500:
    //       errorMessage = 'Internal Server Error: Something went wrong on the server';
    //       break;
    //     default:
    //       errorMessage = `Error ${error.response.status}: ${error.response.data.message || 'An error occurred'}`;
    //   }      
    // } else if (error.request) {
    //   // The request was made but no response was received
    //   errorMessage = 'No response received: The server did not respond to the request';
    // }

    // // Create a custom error object
    // const customError = {
    //   isAxiosError: true,
    //   errorMessage: errorMessage,
    //   originalError: error,
    // };

    // return Promise.reject(customError);
  // },


  
//todo: chk if updater

export const provinces = [
    "Chieti", "L'Aquila", "Pescara", "Teramo",
    "Matera", "Potenza",
    "Catanzaro", "Cosenza", "Crotone", "Reggio Calabria", "Vibo Valentia",
    "Avellino", "Benevento", "Caserta", "Napoli", "Salerno",
    "Bologna", "Ferrara", "Forlì-Cesena", "Modena", "Parma", "Piacenza", "Ravenna", "Reggio Emilia", "Rimini",
    "Gorizia", "Pordenone", "Trieste", "Udine",
    "Frosinone", "Latina", "Rieti", "Roma", "Viterbo",
    "Genova", "Imperia", "La Spezia", "Savona",
    "Bergamo", "Brescia", "Como", "Cremona", "Lecco", "Lodi", "Mantova", "Milano", "Monza e Brianza", "Pavia", "Sondrio", "Varese",
    "Ancona", "Ascoli Piceno", "Fermo", "Macerata", "Pesaro e Urbino",
    "Campobasso", "Isernia",
    "Alessandria", "Asti", "Biella", "Cuneo", "Novara", "Torino", "Verbano Cusio Ossola", "Vercelli",
    "Bari", "Barletta-Andria-Trani", "Brindisi", "Lecce", "Foggia", "Taranto",
    "Cagliari", "Carbonia-Iglesias", "Medio Campidano", "Nuoro", "Ogliastra", "Olbia-Tempio", "Oristano", "Sassari",
    "Agrigento", "Caltanissetta", "Catania", "Enna", "Messina", "Palermo", "Ragusa", "Siracusa", "Trapani",
    "Arezzo", "Firenze", "Grosseto", "Livorno", "Lucca", "Massa e Carrara", "Pisa", "Pistoia", "Prato", "Siena",
    "Bolzano", "Trento",
    "Perugia", "Terni",
    "Aosta",
    "Belluno", "Padova", "Rovigo", "Treviso", "Venezia", "Verona", "Vicenza"
];

//order the provinces alphabetically 
provinces.sort();


// import { TextBox } from 'devextreme-react/text-box';
import { useContext } from 'react';
// import PropTypes from 'prop-types';


// import { useDispatch, useSelector } from "react-redux";
// import { setStateStockSearch } from "../store/stateSlice";
import { ProductContext } from '../contexts/ProductProvider';
// import useTranslate from '../utils/useTranslate';
import SearchTextbox from './SearchTextbox';

const dropdownOptions = {
    inputAttr:{
        autocomplete: 'new-password',
    }
}


const  ProductLookupTextbox = () => {

    // const translate = useTranslate();
    const {stockSearch, setStockSearch, setStockPagination} = useContext(ProductContext)

    // const state = useSelector((state)=> state.state) //to summon the state
    // const dispatch = useDispatch(); //this allows to store new data in the redux store
    
    const onValueChange = (value) => {
        // onSearchChanged(value);
        setStockSearch(value);
        setStockPagination(1)

        // setValue(value)
        // dispatch(setStateStockSearch(value))
    }; //problem: store is async updated, so it shows the prevoius value, solution: useEffect for functions that need the updated store value

    // useEffect(()=>{
    //     // console.log(state.stockSearch, 'stato'); //todo: remove
    //     onSearchChanged() //question: what does this function do, hence eventully change order of onSearch and dispatch
    // }, [state.stockSearch])

    return (
        <div className="product-lookup-textbox me-2">
            <SearchTextbox 
                value={stockSearch} 
                editorOptions={dropdownOptions} 
                onValueChange={onValueChange}
                placeholder='Search item'
            />
             {/* <TextBox 
                 mode= "search"
                 placeholder={translate('Search')}
                 showClearButton={true}
                 value={stockSearch}
                 valueChangeEvent="input"
                 onValueChange={onValueChange}
                 editorOptions={dropdownOptions}
             /> */}
        </div>
    );
}
 
export default ProductLookupTextbox;

// ProductLookupTextbox.propTypes = {
//     onSearchChanged: PropTypes.func
// }
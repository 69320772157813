export const TRANSLATION = {
	'en-EN':{},
	'it-IT': {
		//error messages
		'Error': 'Errore',
		'Error in fetching data': 'Errore nel recupero dei dati',
		'Authentication failed': 'Autenticazione fallita',
		'Camera not found': 'Fotocamera non trovata',

		//New version popup
		'New version': 'Nuova versione',
		'Welcome to the new version of eversell app!': "Benvenuti nella nuova versione dell'app Eversell!",

		//login
		'Log In': 'Accedi',
		'Welcome on eversell': 'Benvenuti su eversell',
		'Company': 'Azienda',
		'Username': 'Utente',
		'Password': 'Password',
		'Remember me': 'Ricorda credenziali', //dubbio Stay connected-Resta connesso oppure Remember me-Ricorda credenziali?
		'Forgot password?': 'Password dimenticata?',
		'Sign In': 'Accedi',
		'Required field': 'Campo obbligatorio', //dubbio richiesto
		'Required fields': 'Campi obbligatori', //dubbio richiesto

		//header 
		'admin': 'admin', //dubbio
		'store': 'negozio', //dubbio
		'customer': 'cliente',

		//sidebar
		'Product Lookup': 'Ricerca prodotti', //dubbio
		'Sales Dashboard': 'Vendite', //dubbio pannello? Bacheca vendite?
		'Customer': 'Cliente',
		'Settings': 'Impostazioni',
		'Logout': 'Esci', //dubbio  lasciare logout?
		'Version': 'Versione',

		//load panel
		'Loading...': 'Caricamento...',

		//footer filter
		'<ALL>': '<TUTTI>',
		'season': 'stagione',
		'department': 'reparto',
		'supplier': 'fornitore',

		//Product lookup
		// 'Product lookup': 'Ricerca prodotti',
		'Products not found.': 'Nessun articolo trovato.',
		'Search': 'Cerca', //also in popup filter
		'Search item': 'Cerca articolo',
		'Item': 'Articolo', //dubbio 'Prodotto'
		'Barcode': 'Barcode', //dubbio 'Codice a barre'
		'Available': 'Disponibile',
		'Reserved': 'Riservato',
		'In Order': 'In ordine',
		'Price': 'Prezzo',
		'Color': 'Colore',
		'Available only': 'Solo disponibili',

		//QR code reader
		'Barcode reader': 'Lettore barcode',
		'Retry': 'Riprova',
		'back': 'indietro',

		//Product detail
			//footer
		'description': 'descrizione',
		'stock': 'stock',
			//description
		'Code': 'Codice',
		'Model': 'Modello',
		'Description': 'Descrizione',
		'Sell Price': 'Prezzo di vendita',
		'Brand': 'Brand', //dubbio
		'Supplier': 'Fornitore',
		'Season': 'Stagione',
		'Department': 'Reparto',
		'Category': 'Categoria',
		'Subcategory': 'Sottocategoria',
		'Product Type': 'Tipo prodotto',
		'Line': 'Linea',
		'Composition': 'Composizione',
			//stock
		'Store': 'Negozio',
		'Avail': 'DSP',
		'Sold': 'VEN',
		'Qty': 'Qtà', //dubbio
		'qty' : 'qtà',
		'Size': 'Taglia',
		'Available others': 'Altri disponibili',
		
			//KPI
		// 'Sell Through': 'Vendite', //dubbio
		// 'Week Sell Through': 'Vendite Settimanali', //dubbio
		// 'Gross Margin': 'Margine lordo', //dubbio
		// 'Week Presence': 'Presenza settimanale', //dubbio
		'General information': 'Informazioni generali', 
		'Value': 'Valore',
		'Buy Price': "Prezzo d'acquisto",
		'Buy': 'Acquistati',
		'Sell': 'Venduti',

		//sales dashboard
		// 'store': 'negozio',
		'options': 'opzioni',
		'Options': 'Opzioni',
		'Gross': 'Lordo',
		'Cumulative': 'Cumulativo',
		'day': 'giorno',
		'month': 'mese',
		'year': 'anno',
		'Today': 'Oggi',
		'Yesterday': 'Ieri',
		'Current month': 'Mese corrente',
		'Last month to date': 'Mese precedente ad oggi', //dubbio
		'Last month': 'Mese precedente',
		'diff Cm/Lmtd': 'diff Cm/Lmtd', //dubbio
		'Current year': 'Anno corrente',
		'Last year to date': 'Anno precedente ad oggi', //dubbio
		'Last year': 'Anno precedente',
		'diff Cy/Lytd': 'diff Cy/Lytd', //dubbio

		'all': 'tutti',
		'instore': 'in negozio',
		'online': 'online',

		'Current': 'Corrente',
		'Last to date': 'Precedente ad oggi',
		'LTD': 'Prec. ad oggi',
		'Last': 'Precedente',
		'diff C/Ltd': 'diff C/Ltd', //dubbio
		'No data': 'Nessun dato',
		'to date': 'ad oggi',
		'to this time': "a quest'ora",
		'whole': 'intero',

		'Day sales': 'Vendite giornaliere',
		'Month sales': 'Vendite mensili',
		'Year sales': 'Vendite annuali',

		'Sales in store': 'Vendite in negozio',
		'Sales by channel': 'Vendite per canale',

		'Click on the legend to show and hide the series': 'Clicca sulla legenda per mostrare e nascondere le serie',
		'Compare with last month': 'Confronta con mese precedente',
		'Compare with same month of last year': "Confronta con stesso mese dell'anno prec.",


		//orders
		'Orders': 'Ordini',
		'Order': 'Ordine',
		'inserted': 'inserito',
		'waybill': 'lettera vettura',
		'processing': 'lavorazione',
		'dispatched': 'evaso',
		'returned' : 'reso',
		'part. returned': 'reso parz.',
		'channel': 'canale',
		'status': 'stato',
		'archive': 'archivio',
		'open': 'aperti',
		'closed': 'chiusi',
		// 'all': 'tutti',
		'item': 'articolo',
		'Items': 'Articoli',
		'Order Detail': 'Dettaglio ordine',
		'Order not found.': 'Ordine non trovato.',
		'Shipping address': 'Indirizzo di spedizione',
		'Edit': 'Modifica',
		'Customer Orders Detail': 'Dettaglio ordini cliente',
		'There are' : 'Ci sono',
		'new orders' : 'nuovi ordini',
		'view': 'vedi',
		'this month' : 'mese corrente',
		'last month' : 'mese precedente',		
		'last 3 months' : 'ultimi 3 mesi',	
		'select dates': 'seleziona date',	
		// 'this year' : "anno corrente",
		// 'last 3 years' : 'ultimi 3 anni',
		'Orders not found.' : 'Nessun ordine trovato.',
		'Week': 'Sett.',
		'Overview orders': 'Panoramica ordini', //Riepilogo?


		//customer
		'Personal Information': 'Informazioni personali',
		'Personal Data': 'Dati personali',
		'Name':'Nome',
		'Surname':'Cognome',
		'Birth Date': 'Data di nascita',
		'Gender':'Genere',
		'Select...': 'Seleziona...',
		'Male': 'Maschio',
		'Female': 'Femmina',
		'Other': 'Altro',
		'Prefer not to tell': 'Preferisco non dirlo',

		'Contacts': 'Contatti',
		'Mobile':'Cellulare',
		'Address':'Indirizzo',
		'Province':'Provincia',
		'City':'Città',

		'I consent to the processing of my data *': 'Acconsento al trattamento dei miei dati *',
		'I want to receive e-mails': 'Desidero ricevere e-mail',

		'submit': 'invia',

		// 'I confirm treatment of personal data and its use':'Confermo il trattamento dei dati e  il suo utilizzo',
		// 'Confirm':'Conferma',
		// 'Error':'Errore',

		'Do not use digits in this field': 'Non usare cifre in questo campo',
		'Email is not valid': 'Email non valida',
		'Phone number must have country prefix': 'Numero di telefono deve avere il prefisso (es. +39)',

		'Customer saved successfully':'Cliente salvato con successo',
		'Error in saving customer': 'Errore nel salvataggio del cliente',


		//Inventory
		'Inventory' : 'Inventario',
		'Delete confirmation': 'Conferma eliminazione',
		'Edit confirmation': 'Conferma modifica',
		'Cancel': 'Annulla',
		'Delete' : 'Elimina',
		'Are you sure you want to delete this item from the current inventory?' : 'Sei sicuro di voler eliminare questo articolo dall’inventario corrente?',
		'Are you sure you want to delete this item?' : 'Sei sicuro di voler eliminare questo articolo?',
		'Are you sure you want to edit this item?': 'Sei sicuro di voler modificare questo articolo?',
		'Detected item' : 'Articolo rilevato',
		'save': 'salva',
		'exit': 'esci',
		'Item added successfully': 'Articolo aggiunto con successo',
		'new': 'nuovo',
		'ongoing': 'in corso',
		// 'archive': 'archivio',
		'Date': 'Data',
		'Inventory created successfully': 'Inventario creato con successo',
		'Error in creating inventory': "Errore nella creazione dell'inventario",
		'Type': 'Tipo',
		'Total': 'Totale',
		'Partial': 'Parziale',
		'On detected items only': 'Solo su articoli rilevati',
		'New inventory': 'Nuovo inventario',
		'Partial inventory': 'Inventario parziale',
		'create': 'crea',
		'type': 'tipo',
		'Confirmed': 'Confermato',
		'Detected': 'Rilevato',
		// 'Confirmed': 'Confermato',

		//Barcode association
		'Barcode association': 'Associazione barcode',
		'associate': 'associa',
		'Use digits only in this field': 'Usa solo numeri in questo campo',
		'Internal barcode': 'Barcode interno',
		'Manufacturer barcode': 'Barcode produttore',
		'Barcode associated successfully': "Barcode associato con successo",
		'Error in associating barcode': "Errore nell'associazione del barcode",
		'Product not found.': 'Articolo non trovato.',
		'No product selected.' : 'Nessun articolo selezionato.',


		//settings
		'Account': 'Profilo',
		'Role': 'Ruolo',
		'Language': 'Lingua',
		'Preferences': 'Preferenze',


		//PWA
		'A new version of the app is available. Would you like to reload?' : "Una nuova versione dell'app è diponibile. Desideri aggiornarla?"
	}
};

//todo
//files that uses this TRADUCTION in the original app: app/filters.js
// app.filter('translate', function(TRADUCTION, $cookies) {
// 	return function(input) {
// 		try {
// 			var output = TRADUCTION[$cookies.get('language')][input];
// 			return ((typeof output)=='undefined') ? input : output;
// 		} catch(err) {
// 			return input;
// 		}
// 	}
// });
import { useNavigate,useLocation, Outlet} from 'react-router-dom';
import Cookies from 'js-cookie';
import { useContext, useEffect, lazy } from 'react';
import { it } from 'date-fns/locale/it'
import { enGB } from 'date-fns/locale/en-GB'

import {AuthContext} from './contexts/LoginAuthProvider.jsx'
import { setDefaultOptions } from 'date-fns';
import { LanguageContext } from './contexts/LanguageProvider.jsx';

//todo: check if needed
// const RedirectToLogin = () => {
//   const role = Cookies.get('role');
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (role == 550){
//       navigate('/customer')
//     } else {
//       navigate('/product-lookup')
//     }
//   }, [role, navigate])
// }



function App() {
//manually trigger the install prompt

//claude
  // const {
  //   offlineReady,
  //   needRefresh,
  //   updateServiceWorker,
  //   registerSW,
  // } = useRegisterSW()

  // useEffect(() => {
  //   if (needRefresh) {
  //     if (confirm('New content available. Reload?')) {
  //       updateServiceWorker();
  //     }
  //   }
  // }, [needRefresh, updateServiceWorker]);
//fine claude

  
  const { logout } = useContext(AuthContext);
  const {language} = useContext(LanguageContext);
  const navigate = useNavigate();
  const location = useLocation();  // gets current location path

  // on app load check if cookies, if defined redirects to product-lookup, else to login
  useEffect(()=>{
    const token = Cookies.get('token');
    const url = Cookies.get('url');  {/* todo check if needed to be saved in the cookies or not*/}
    const currency = Cookies.get('currency');
    const role = Cookies.get('role');
    const id_store = Cookies.get('id_store'); {/* todo check if needed to be saved in the cookies or not*/}


    if (!token || !url || !currency || !role || !id_store) {
      logout();
      navigate('/');
    } else {
      if(role == 550){
        navigate('/customer')
      } else {
        //if the user is in a page different from product lookup, it stays where it is
        if(location.pathname == '/') {
          navigate('/product-lookup')
        }
      }
    }

    // Function to set the --vh variable to listen to height resize, necessary in mobile
    const setVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    setVh(); //set on initial load
    window.addEventListener('resize', setVh); //Update the --vh variable on window resize
    // return () => {
    //   window.removeEventListener('resize', setVh);   // Cleanup the event listener on component unmount
    // }
  }, [])

  useEffect(()=>{
    const lang = language == 'it-IT'? it : enGB;  // Set the language based on the language context
    const weekStart = language == 'it-IT' ? 1 : 0;
    setDefaultOptions({locale: lang, weekStartsOn: weekStart})
  },[language]);

  return <Outlet />
}


export default App



//   export const router = createBrowserRouter (
//     createRoutesFromElements (
//     <>
//     {/* <AnimatePresence mode='wait'> */}
//       {/* <AnimatedPage animation={getAnimation(location.pathname)}>
//         <Routes location={location} key={location.key}> */}
//         {/* <Routes> */}

//     <Route element={<App />}>
//         <Route path="/" element={<Login />} />     
//         {/* <Route element={<PrivateRoute /> } >    */}
//         {/* todo: uncomment after login allows to enter */}
//         {/* this allows navigation in the following routes only if */}    
//         <Route path="/product-lookup" element={<ProductLookup/> } />
//         {/* <Route path="/product-lookup" element={
//           <Suspense fallback={<div></div>}>
//             <ProductLookup />
//           </Suspense>
//           } />       */}
//         <Route path="/product-lookup/:id" element={
//           <Suspense fallback={<div></div>}>
//             <ProductDetails />
//           </Suspense>
//           } />
//         <Route path="/qr-scanner" element={
//         <Suspense fallback={<div></div>}>
//           <QRScannerPage />
//         </Suspense>
//         } />

//       <Route path="/sales-dashboard" element={<SalesDashboard />} /> 
//         {/* <Route path="/sales-dashboard" element={
//           <Suspense fallback={<div></div>}>
//             <SalesDashboard />
//           </Suspense>
//           } /> */}

//       <Route path="/orders" element={<Orders/> } />
//       <Route path="/orders/:id" element={
//         <Suspense fallback={<div></div>}>
//           <OrderDetail />
//         </Suspense>
//         } />
//       <Route path="/orders/customer/:id" element={
//         <Suspense fallback={<div></div>}>
//           <OrdersSingleCustomer />
//         </Suspense>
//         } />

//         <Route path="/customer" element={
//           <Suspense fallback={<div></div>}>
//           <Customer />
//         </Suspense>
//         } />

//         <Route path="/inventory" element={
//           <Suspense fallback={<div></div>}>
//           <InventoryManagement />
//         </Suspense>
//         } />

//         <Route path="/inventory/:id" element={
//           <Suspense fallback={<div></div>}>
//           <Inventory />
//         </Suspense>
//         } />

//         <Route path="/barcode-association" element={
//           <Suspense fallback={<div></div>}>
//           <BarcodeAssociation />
//         </Suspense>
//         } />
//         <Route path="/settings" element={
//           <Suspense fallback={<div></div>}>
//           <Settings />
//           </Suspense>} />
//         <Route path='*' element= {<Navigate to="/product-lookup"/>}/> {/*todo handle this correctly */}

//         {/* <Route path="/logout" element={<Logout />} /> */}
//         {/* <Route path='/' element= {<RedirectToLogin/>}/> todo handle this correctly */}

//         {/* version with require auth, for now not used */}
//         {/* <Route path="/product-lookup" element={<RequireAuth><ProductLookup/></RequireAuth> } />
//         <Route path="/product-lookup/:item" element={<RequireAuth><ProductDetails /></RequireAuth>} />
//         <Route path="/sales-dashboard" element={<RequireAuth><SalesDashboard /></RequireAuth>} />
//         <Route path="/customer" element={<RequireAuth><Customer /></RequireAuth>} />
//         <Route path="/logout" element={<Logout />} />
//         <Route path='*' element= {<Navigate to="/product-lookup"/>}/>  */}
//         {/* todo handle this correctly */}
//         {/* <Route path='/' element= {<RedirectToLogin/>}/> todo handle this correctly */}
//         {/* </Route>   */}
//         </Route>
//       {/* </Routes> */}
//         {/* </Routes>
//       </AnimatedPage>*/}
//     {/* </AnimatePresence>  */}
//     </>
//   )
// )


//todo: check if needed, take care of animatons (move the code to a file outside app?) keyword: motion
//Define template and animation
// const AnimationWrapper = ({ children }) => {
//   const location = useLocation();
//   return (
//     <motion.div 
//       key={location.pathname}
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       exit={{ opacity: 0 }}
//       transition={{duration: 0.5}}
//       >
//         {children}
//       </motion.div>
//   )
// }






//////////////////////7animation 

// const animations = {
//   fade: {
//       initial: { opacity: 0 },
//       animate: { opacity: 1 },
//       exit: { opacity: 0 }
//   },
//   slide: {
//       initial: { x: '100%', opacity: 0 },
//       animate: { x: 0, opacity: 1 },
//       exit: { x: '-100%', opacity: 0 }
//   },
//   slideAlt: {
//       initial: { x: '-100%', opacity: 0 },
//       animate: { x: 0, opacity: 1 },
//       exit: { x: '100%', opacity: 0 }
//   }
// };

// const AnimatedPage = ({ children, animation }) => {
//   const location = useLocation();
//   return (
//       <motion.div
//           key={location.pathname}
//           initial={animation.initial}
//           animate={animation.animate}
//           exit={animation.exit}
//           transition={{ duration: 0.3 }}
//       >
//           {children}
//       </motion.div>
//   );
// };


  //////////////////animation
  // const getAnimation = (pathname) => {
  //   switch (pathname) {
  //     case '/stock':
  //         return { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } };
  //     case '/stock/:id':
  //         return { initial: { x: 100, opacity: 0 }, animate: { x: 0, opacity: 1 }, exit: { x: -100, opacity: 0 } };
  //     case '/dashboard':
  //         return { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } };
  //     case '/customer':
  //         return { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } };
  //     default:
  //         return { initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } };
  //   }
  // };
import config from '../constants/config';

export const status = [
    {
        ID: 0,
        DESCRIPTION: config.all,
        OPEN_CLOSED: ['open', 'closed']
    },
    {
        ID: 1,
        DESCRIPTION: 'inserted',
        OPEN_CLOSED: ['open']
    },
    {
        ID: 2,
        DESCRIPTION: 'waybill',
        OPEN_CLOSED: ['open']
    },
    {
        ID: 3,
        DESCRIPTION: 'processing',
        OPEN_CLOSED: ['open']
    },
    {
        ID: 4,
        DESCRIPTION: 'dispatched',
        OPEN_CLOSED: ['closed']
    },
    {
        ID: 5,
        DESCRIPTION: 'returned',
        OPEN_CLOSED: ['closed']
    },
    {
        ID: 6,
        DESCRIPTION: 'part. returned',
        OPEN_CLOSED: ['closed']
    }
]    


export const archive = [
    {
        ID: 0,
        // DESCRIPTION: `${translate('this month')} (default)`  //if modify here, have to modify the default val in the product provider too
        DESCRIPTION: 'this month'  //if modify here, have to modify the default val in the product provider too
    },
    {
        ID: 1,
        DESCRIPTION: 'last month'
    },
    {
        ID: 2,
        DESCRIPTION: 'last 3 months'
    },
    {
        ID: 3,
        DESCRIPTION: 'select dates'
    },
    // {
    //     ID: 3,
    //     DESCRIPTION: translate('this year')
    // },
    // {
    //     ID: 4,
    //     DESCRIPTION: translate('last year')
    // },
    // {
    //     ID: 5,
    //     DESCRIPTION: translate('last 3 years')
    // },
]    






// //todo: capire se filtri sono fissi o vengono da backend


// // const filtersProductLookup = [
// //     {
// //         label: "season",
// //         selected: "Filter PL 1",
// //         image: 'fa-regular fa-calendar-check',
// //     },
// //     {
// //         label: "department",
// //         selected: "Filter PL 1",
// //         image: 'fa-regular fa-building',
// //     },
// //     {
// //         label: "supplier",
// //         selected: "Filter PL 1",
// //         image: "fa-solid fa-tag",
// //     }
// // ];
// const filtersProductPage = [
//     {
//         label: "description",
//         selected: "Filter SD 1",
//         image: "fa-file-text-o",
//     },
//     {
//         label: "stock",
//         selected: "Filter SD 1",
//         image: "https://via.placeholder.com/150",
//     },
//     {
//         label: "KPI",
//         selected: "Filter SD 1",
//         image: "https://via.placeholder.com/150",
//     }
// ]
// // const filtersSalesDashboard = [
// //     {
// //         label: "store",
// //         selected: "Filter SD 1",
// //         image: "fa-solid fa-shopping-bag",
// //     },
// //     {
// //         label: "supplier",
// //         selected: "Filter SD 1",
// //         image: "fa-solid fa-tag",
// //     },
// //     {
// //         label: "options",
// //         // selected: "Filter SD 1",
// //         image: "fa-solid fa-toggle-on",
// //     }
// // ];



// const filters = [
//     filtersProductLookup, 
//     filtersProductPage, 
//     // filtersSalesDashboard
// ];


// export default filters;

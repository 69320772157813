import { useContext } from 'react';
import { LanguageContext } from '../contexts/LanguageProvider';
import { TRANSLATION } from './constants/translation';

function useTranslate() {
    const { language } = useContext(LanguageContext); //takes lang from the provider

    function translate(input) {
        try {
            const output = TRANSLATION[language][input]; //input is the sentence we are giving, which is also the key of the dictionary
            return typeof output === 'undefined' ? input : output;
        } catch (err) {
            return input;
        }
    }

    return translate;
}

export default useTranslate;
